import React, { useState, useEffect } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import Modal from 'react-modal';
import adminService from './../../services/adminService';
import styles from './ProgramRequests.module.scss';
import placeholder from '../../images/placeholder.jpg';
import { Tooltip } from '@mui/material';
import Flag from 'react-world-flags';

Modal.setAppElement('#root');

const ProgramRequests = () => {
  const [programRequests, setProgramRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [revshare, setRevshare] = useState('0.00');
  const [cpa, setCpa] = useState(0);
  const [affiliateLink, setAffiliateLink] = useState('');
  const [var1, setVar1] = useState('');
  const [var2, setVar2] = useState('');
  const [isVar1ReadOnly, setIsVar1ReadOnly] = useState(false);
  const [isVar2ReadOnly, setIsVar2ReadOnly] = useState(false);
  const [useStandardDeal, setUseStandardDeal] = useState(true);
  const [dealMessage, setDealMessage] = useState('');
  const [regionDeal, setRegionDeal] = useState(null);
  const [warningMessage, setWarningMessage] = useState('');
  const [handlingDeny, setHandlingDeny] = useState(false);
  const [denyMessage, setDenyMessage] = useState("");

  const [date, setDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // Format: YYYY-MM-DD
  }); const fetchProgramRequests = async () => {
    try {
      const data = await adminService.fetchProgramRequests();
      setProgramRequests(data);
    } catch (error) {
      console.error('Error fetching program requests:', error);
    }
  };

  useEffect(() => {
    fetchProgramRequests();
  }, []);

  const openModal = (request) => {
    setSelectedRequest(request);
    // Hantera var1 och var2
    if (request.sourceProgram.var1) {
      setVar1(request.sourceProgram.var1);
      setIsVar1ReadOnly(true);
    } else {
      setVar1('');
      setIsVar1ReadOnly(false);
    }

    if (request.sourceProgram.var2) {
      setVar2(request.sourceProgram.var2);
      setIsVar2ReadOnly(true);
    } else {
      setVar2('');
      setIsVar2ReadOnly(false);
    }

    // Hantera regionDeal
    const currentDeal = request.ProgramRegion?.currentDeals[0] || null; // Rättat från currentDeal till currentDeals
    const nextDeal = request.ProgramRegion?.nextDeals[0] || null; // Rättat från nextDeal till nextDeals

    setRegionDeal(currentDeal);

    if (currentDeal) {
      setDealMessage('Regionen har en specifik deal. Vänligen kontrollera värdena.');
      setRevshare(((currentDeal.revshare || 0) * 100).toFixed(2));
      setCpa(currentDeal.CPA || 0);
    } else {
      setDealMessage('Ingen specifik deal är kopplad till denna region.');
      setRevshare('0.00');
      setCpa(0);
    }

    // Sätt varningstext om det finns en nästa deal
    if (nextDeal) {
      setWarningMessage(
        'Programmet håller på att byta standard. Om du vill ha de nya villkoren bör du svara på förfrågan dagen efter eller ange nya värden manuellt.'
      );
    } else {
      setWarningMessage('');
    }

    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedRequest(null);
    setRegionDeal(null);
    setWarningMessage('');
    setHandlingDeny(false);
    setDenyMessage('');
    setDate(() => {
      const today = new Date();
      return today.toISOString().split('T')[0]; // Format: YYYY-MM-DD
    })
  };

  const handleAccept = async () => {
    if (selectedRequest) {
      try {
        await adminService.acceptProgramRequest(selectedRequest.id, {
          revshare: parseFloat(revshare) / 100,
          cpa,
          affiliateLink,
          var1,
          var2,
          useStandardDeal,
          date
        });
        fetchProgramRequests();
        setAffiliateLink('');
        setVar1('');
        setVar2('');
        setDate(() => {
          const today = new Date();
          return today.toISOString().split('T')[0]; // Format: YYYY-MM-DD
        })
        closeModal();
      } catch (error) {
        console.error('Error accepting program request:', error);
      }
    }
  };

  const handleDeny = async () => {
    if (selectedRequest) {
      try {
        console.log(denyMessage)
        await adminService.denyProgramRequest(selectedRequest.id, denyMessage);
        fetchProgramRequests();
        closeModal();
      } catch (error) {
        console.error('Error denying program request:', error);
      }
    }
  };

  const handleUseStandardDealChange = (event) => {
    const isChecked = event.target.checked;

    setUseStandardDeal(isChecked);

    if (isChecked && regionDeal) {
      // Om standard deal används, sätt värden från regionDeal
      setRevshare(((regionDeal.revshare || 0) * 100).toFixed(2));
      setCpa(regionDeal.CPA || 0);

      // Sätt datum till regionDeal.validFrom om det är tillgängligt
      if (regionDeal.validFrom) {
        const validFromDate = new Date(regionDeal.validFrom);
        const currentDate = new Date(date);

        // Om det nuvarande datumet är före validFrom, uppdatera det
        if (currentDate < validFromDate) {
          setDate(validFromDate.toISOString().split('T')[0]); // Format: YYYY-MM-DD
        }
      }
    }
  };

  const handleRevshareChange = (e) => {
    const value = parseFloat(e.target.value) || 0;
    setRevshare(value.toFixed(2));
  };

  const getDate = () => {
    const programRegionStart = selectedRequest.ProgramRegion.createdAt;
    const dealStart = regionDeal.validFrom;
    const toReturn = useStandardDeal ? dealStart : programRegionStart;
    const date = new Date(toReturn);
    console.log(date.toISOString().split('T')[0])
    return date.toISOString().split('T')[0]; // Format: YYYY-MM-DD
  };

  const openDenySection = () => {
    if (selectedRequest) {
      const programName = selectedRequest.ProgramRegion.Program.name;
      const regionName = selectedRequest.ProgramRegion.Region.name;
      setDenyMessage(`Request for ${programName} - ${regionName} has been denied`)
      setHandlingDeny(true);
    }
  }

  return (
    <div className={styles.programRequestsContainer}>
      <div className={styles.cardGrid}>
        {programRequests.map((request) => (
          <Card className={styles.programRequestCard} key={request.id}>
            <Card.Body>
              <p className={styles.requestDate}>{new Date(request.createdAt).toLocaleDateString()}</p>
              <div className={styles.programRequestContent}>
                <div className={styles.sourceInfo}>
                  <img
                    src={
                      request.sourceProgram.Source.logo
                        ? `/uploads/sourceLogos/${request.sourceProgram.Source.logo}`
                        : placeholder
                    }

                    alt={request.sourceProgram.Source.url}
                    className={styles.logo}
                  />
                  <p>{request.sourceProgram.Source.name}</p>
                </div>

                <div className={styles.arrow}>
                  <span className="material-symbols-outlined">arrow_forward</span>
                </div>

                {/* Programsektion */}
                <div className={styles.programInfo}>
                  <img
                    src={
                      request.ProgramRegion.Program.Brand.image
                        ? `/uploads/brandLogos/${request.ProgramRegion.Program.Brand.image}`
                        : placeholder
                    }

                    alt={request.ProgramRegion.Program.name}
                    className={styles.logo}
                  />
                  <div className={styles.programName}>
                    <Flag
                      code={request.ProgramRegion.Region.shortCode}
                      style={{ width: '30px', height: '20px' }}
                      alt="flag"
                    />
                    {request.ProgramRegion.Program.name}
                  </div>
                </div>
                <div>
                  <Button
                    variant="primary"
                    onClick={() => openModal(request)}
                    className={styles.respondButton}
                  >
                    Respond
                  </Button>
                </div>
              </div>
            </Card.Body>
          </Card>
        ))}
      </div>

      {/* Modal för att visa mer detaljer och uppdatera kommission */}
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        className={styles.modal}
        overlayClassName={styles.modalOverlay}
      >
        <div className={styles.modalContent}>
          <h3>Program Request</h3>
          {selectedRequest && (
            <div>
              <div className={styles.sourceProgramInfo}>
                <div className={styles.firstInfo}>
                  <p className={styles.infoLabel}>Source</p>
                  <div className={styles.imageText}>
                    <img
                      src={
                        selectedRequest.sourceProgram.Source.logo
                          ? `/uploads/sourceLogos/${selectedRequest.sourceProgram.Source.logo}`
                          : placeholder
                      }

                      className={styles.logo}
                    />
                    <div className={styles.mainInfo}>
                      <p>{selectedRequest.sourceProgram.Source.name}</p>
                      <p>{selectedRequest.sourceProgram.Source.url}</p>
                    </div>
                  </div>
                </div>
                <div className={styles.firstInfo}>
                  <p className={styles.infoLabel}>Program</p>
                  <div className={styles.imageText}>
                    <img
                      src={
                        selectedRequest.ProgramRegion.Program.Brand.image
                          ? `/uploads/brandLogos/${selectedRequest.ProgramRegion.Program.Brand.image}`
                          : placeholder
                      }

                      className={styles.logo}
                    />
                    <div className={styles.mainInfo}>
                      <p>{selectedRequest.ProgramRegion.Program.name}</p>
                      <p>{selectedRequest.ProgramRegion.Program.Brand.name}</p>
                      <p>
                        {selectedRequest.ProgramRegion.Program.Verticals.map((v) => v.name).join(', ')}
                      </p>
                      <p>{selectedRequest.ProgramRegion.Region.name}</p>
                    </div>
                  </div>
                </div>
                <div className={styles.standardDeal}>
                  <p>
                    <strong>Their CPA: </strong>
                    {regionDeal ? `${regionDeal.CPA}€` : '-'}
                  </p>
                  <p>
                    <strong>Our CPA: </strong>
                    {regionDeal ? `${regionDeal.CPAbeforeCommission}€` : '-'}
                  </p>
                  <p>
                    <strong>Their Revenue share: </strong>
                    {regionDeal ? `${(regionDeal.revshare * 100).toFixed(2)}%` : '-'}
                  </p>
                  <p>
                    <strong>Our Revenue share: </strong>
                    {regionDeal ? `${(regionDeal.revShareBeforeCommission * 100).toFixed(2)}%` : '-'}
                  </p>
                  <p>
                    <strong>Baseline: </strong>
                    {regionDeal ? `${regionDeal.baseline}€` : '-'}
                  </p>
                </div>
              </div>
              {!handlingDeny ?
                <div className={styles.inputContainer}>
                  {/* Varningsikon med kort text och tooltip */}
                  {warningMessage && (
                    <div className={styles.warningMessage}>
                      <Tooltip title={warningMessage} arrow>
                        <div className={styles.warningIconContainer}>
                          <span className="material-symbols-outlined">warning</span>
                          <span className={styles.warningText}>Deal Changing</span>
                        </div>
                      </Tooltip>
                    </div>
                  )}

                  <Form>
                    <Form.Group controlId="formAffiliateLink" className={styles.affiliateLink}>
                      <Form.Label>Affiliate Link</Form.Label>
                      <Form.Control
                        type="text"
                        value={affiliateLink}
                        onChange={(e) => setAffiliateLink(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="formVar1">
                      <Form.Label>
                        Var1
                        <Tooltip
                          title={
                            'Var1 och Var2 krävs för vissa program för att mappa data från API:er. Se dokumentationen för ditt program.'
                          }
                        >
                          <div className={styles.varTooltipContainer}>
                            <span className="material-symbols-outlined">info</span>
                          </div>
                        </Tooltip>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={var1}
                        onChange={(e) => setVar1(e.target.value)}
                        disabled={isVar1ReadOnly}
                      />
                    </Form.Group>

                    <Form.Group controlId="formVar2">
                      <Form.Label>Var2</Form.Label>
                      <Form.Control
                        type="text"
                        value={var2}
                        onChange={(e) => setVar2(e.target.value)}
                        disabled={isVar2ReadOnly}
                      />
                    </Form.Group>

                    {dealMessage && <p className={styles.dealMessage}>{dealMessage}</p>}
                    <Form.Group controlId="useStandardDeal" className={styles.useStandard}>
                      <Form.Label>Use Standard Deal</Form.Label>
                      <Form.Check
                        type="checkbox"
                        checked={useStandardDeal}
                        onChange={handleUseStandardDealChange}
                      />
                    </Form.Group>

                    <Form.Group controlId="formRevshare">
                      <Form.Label>Their Rev %</Form.Label>
                      <Form.Control
                        type="number"
                        value={revshare}
                        onChange={handleRevshareChange}
                        disabled={useStandardDeal}
                        step="0.01"
                      />
                    </Form.Group>

                    <Form.Group controlId="formCpa">
                      <Form.Label>Their CPA €</Form.Label>
                      <Form.Control
                        type="number"
                        value={cpa}
                        onChange={(e) => setCpa(e.target.value)}
                        disabled={useStandardDeal}
                      />
                    </Form.Group>

                    <Form.Group controlId="formCpa">
                      <Form.Label className={styles.validFrom}>Valid from
                        <Tooltip title={"Set the date marking the beginning of the source's affiliation. Setting a date later than the actual start will block the API from fetching earlier data. To avoid this, it is better to set a date well before the actual start date."}>
                          <div>
                            <span class="material-symbols-outlined">
                              info
                            </span>
                          </div>
                        </Tooltip>


                      </Form.Label>
                      <Form.Control
                        type="date"
                        value={date}
                        min={getDate()}
                        onChange={(e) => setDate(e.target.value)}
                      />

                    </Form.Group>
                  </Form>

                  <div className={styles.buttonGroup}>
                    <button variant="primary" className={styles.acceptButton} onClick={handleAccept}>
                      Accept
                    </button>
                    <div className={styles.rightButtonGroup}>
                      <button variant="danger" className={styles.denyButton} onClick={() => openDenySection()}>
                        Deny
                      </button>
                      <button variant="secondary" className={styles.closeButton} onClick={closeModal}>
                        Close
                      </button>
                    </div>
                  </div>
                </div>
                :
                <div className={styles.denyContainer}>
                  <p>Do you want to change the notification message?</p>
                  <textarea type="text" className={styles.input} onChange={(e) => setDenyMessage(e.target.value)} value={denyMessage} />
                  <div className={styles.buttonGroup}>
                    <button variant="danger" className={styles.denyButton} onClick={() => handleDeny()}>
                      Confirm Deny
                    </button>
                    <div className={styles.rightButtonGroup}>
                      <button variant="danger" className={styles.backButton} onClick={() => setHandlingDeny(false)}>
                        Back
                      </button>
                      <button variant="secondary" className={styles.closeButton} onClick={closeModal}>
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              }
            </div>
          )}
        </div>
      </Modal >
    </div >
  );
};

export default ProgramRequests;
