import React, { useState } from 'react';
import { NavLink, useNavigate, Outlet, useLocation } from 'react-router-dom';
import styles from './AdminLayout.module.scss';
import authService from '../services/authService';

const AdminLayout = () => {
    const navigate = useNavigate();
    const [showProgramDropdown, setShowProgramDropdown] = useState(false);
    const location = useLocation();

    const logout = async () => {
        await authService.logout();
        navigate('/login');
    };

    const isProgramActive = location.pathname.includes('/admin/programs');


    return (
        <div className={styles.container}>
            <div className={styles.topnav}>
                <div className={styles.topnavComponent}>
                    <div className={styles.header}>
                        <NavLink to="/admin">
                            <h1>Savvy</h1>
                            <h2>ADMIN</h2>
                        </NavLink>
                    </div>
                    <div className={styles.navLinks}>
                        <NavLink to="/admin/users" className={({ isActive }) => isActive ? styles.active : ''}>
                            Users
                        </NavLink>
                        <NavLink to="/admin/sources" className={({ isActive }) => isActive ? styles.active : ''}>
                            Sources
                        </NavLink>
                        <div
                            className={`${styles.dropdown} ${isProgramActive ? styles.active : ''}`}
                            onMouseEnter={() => setShowProgramDropdown(true)}
                            onMouseLeave={() => setShowProgramDropdown(false)}
                        >
                            <div>
                                <NavLink to="/admin/programs/all" className={({ isActive }) => isActive ? styles.active : ''}>
                                    Programs
                                </NavLink>
                            </div>
                            {showProgramDropdown && (
                                <div className={styles.dropdownContent}>
                                    <NavLink to="/admin/programs/all" className={({ isActive }) => isActive ? styles.underline : ''}>Programs</NavLink>
                                    <NavLink to="/admin/programs/create" className={({ isActive }) => isActive ? styles.underline : ''}>Create</NavLink>
                                    <NavLink to="/admin/programs/requests" className={({ isActive }) => isActive ? styles.underline : ''}>Requests</NavLink>
                                </div>
                            )}
                        </div>
                        <NavLink to="/admin/source-program" className={({ isActive }) => isActive ? styles.active : ''}>
                            SourcesProgram
                        </NavLink>
                        <NavLink to="/admin/performance" className={({ isActive }) => isActive ? styles.active : ''}>
                            Performance
                        </NavLink>
                        <NavLink to="/admin/payments" className={({ isActive }) => isActive ? styles.active : ''}>
                            Payments
                        </NavLink>
                        <NavLink to="/admin/monthly-summaries" className={({ isActive }) => isActive ? styles.active : ''}>
                            Monthly Summaries
                        </NavLink>
                        <a href="https://bot.savvyleadsmedia.com/admin_panel/" target="_blank">Telegrambot</a>
                        <NavLink to="/admin/settings" className={({ isActive }) => isActive ? styles.active : ''}>
                            App Settings
                        </NavLink>
                    </div>
                </div>
                <button onClick={logout} className={styles.logout}>
                    <span className="material-symbols-outlined">logout</span>
                    Logout
                </button>
            </div>
            <div className={styles.main}>
                <Outlet />
            </div>
        </div>
    );
};

export default AdminLayout;
