import React, { useState, useEffect } from 'react';
import adminService from '../../services/adminService';
import styles from './Settings.module.scss';

const Settings = () => {
    const [cronTimer, setCronTimer] = useState(''); // För att lagra aktuell cron-timer
    const [newCronTimer, setNewCronTimer] = useState(''); // För att lagra ny cron-timer
    const [message, setMessage] = useState(''); // För att visa feedback till användaren

    // Hämta aktuell cron-timer vid laddning av komponenten
    useEffect(() => {
        const fetchCronTimer = async () => {
            try {
                const timer = await adminService.getCronTimer();
                setCronTimer(timer.cronTimer);
                setNewCronTimer(timer.cronTimer); // Förifyll inputfältet med aktuell timer
            } catch (error) {
                console.error('Error fetching cron timer:', error);
                setMessage('Failed to fetch cron timer.');
            }
        };

        fetchCronTimer();
    }, []);

    // Hantera uppdatering av cron-timer
    const handleUpdateCronTimer = async () => {
        try {
            await adminService.updateCronTimer(newCronTimer);
            setMessage('Cron timer updated successfully.');
            setCronTimer(newCronTimer); // Uppdatera aktuell cron-timer
        } catch (error) {
            console.error('Error updating cron timer:', error);
            setMessage('Failed to update cron timer.');
        }
    };

    return (
        <div className={styles.settingsContainer}>
            <h1>Settings</h1>
            <div className={styles.cronSection}>
                <h2>Cron Timer Settings</h2>
                <p>OBS. UTC tidzone</p>
                <p>OBS. Server måste restartas för att ändra</p>
                <p>Current Cron Timer: <strong>{cronTimer}</strong></p>

                <label htmlFor="cronInput">New Cron Timer:</label>
                <input
                    id="cronInput"
                    type="text"
                    value={newCronTimer}
                    onChange={(e) => setNewCronTimer(e.target.value)}
                    className={styles.cronInput}
                />

                <button onClick={handleUpdateCronTimer} className={styles.updateButton}>
                    Update Timer
                </button>

                <pre class={styles.cronVisual}>
                    <strong>FORMAT</strong>  <br />
                    * * * * * <br />
                    - - - - -  <br />
                    | | | | |  <br />
                    | | | | +---- Day of the week (0 - 7) (Sunday is both 0 and 7)  <br />
                    | | | +------ Month (1 - 12)  <br />
                    | | +-------- Day of the month (1 - 31)  <br />
                    | +---------- Hour (0 - 23)  <br />
                    +------------ Minute (0 - 59)  <br />
                </pre>
                {message && <p className={styles.message}>{message}</p>}
            </div>
        </div>
    );
};

export default Settings;
