import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL + "/api/";

const login = async (email, password) => {
  const response = await axios.post(
    API_URL + "user/login",
    {
      email,
      password,
    },
    {
      withCredentials: true,
    }
  );
  if (response.data.token) {
    return {
      token: response.data.token,
      role: response.data.role,
      userId: response.data.userId,
    };
  }
  return response.data;
};

const register = async (password, email) => {
  const response = await axios.post(
    API_URL + "user/register",
    {
      password,
      email,
      role: "User",
    },
    {
      withCredentials: true,
    }
  );

  if (response.data.token) {
    return {
      token: response.data.token,
      role: response.data.role,
      userId: response.data.userId,
    };
  }
};

const logout = async () => {
  try {
    await axios.get(API_URL + "user/logout", { withCredentials: true });
  } catch (error) {
    throw error;
  }
};

const authenticate = async () => {
  try {
    const response = await axios.get(API_URL + "user/authenticate", {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    return { authenticated: false, role: null };
  }
};

const changePassword = async (newPassword) => {
  try {
    const response = await axios.post(
      API_URL + "user/change-password",
      {
        newPassword,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const forgotPassword = async (email) => {
  try {
    const response = await axios.post(
      `${API_URL}user/forgot-password`,
      {
        email,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const resetPassword = async (token, newPassword) => {
  try {
    const response = await axios.post(
      API_URL + "user/reset-password",
      {
        token,
        newPassword,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const checkResetPassword = async (token) => {
  try {
    const response = await axios.get(
      API_URL + `user/check-reset-password/${token}`,
      {},
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to access reset password");
  }
};

const getDashboardSummary = async () => {
  try {
    const response = await axios.get(`${API_URL}user/dashboard/summary`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching dashboard summary data:", error);
    throw error;
  }
};

const getDashboardEarnings = async (period) => {
  try {
    const response = await axios.get(`${API_URL}user/dashboard/earnings`, {
      params: { period },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching dashboard earnings data:", error);
    throw error;
  }
};

const getDashboardBarChartData = async (sourceId = null) => {
  try {
    const response = await axios.get(`${API_URL}user/dashboard/bar-charts`, {
      params: { sourceId },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching dashboard hot programs data:", error);
    throw error;
  }
};

const getDashboardHotPrograms = async () => {
  try {
    const response = await axios.get(`${API_URL}user/dashboard/hot-programs`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching dashboard hot programs data:", error);
    throw error;
  }
};

const getNotifications = async () => {
  try {
    const response = await axios.get(`${API_URL}user/notifications`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching notifications:", error);
    throw error;
  }
};


const getRecentNotifications = async () => {
  try {
    const response = await axios.get(`${API_URL}user/notifications/recent`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching recent notifications:", error);
    throw error;
  }
};

const getUnreadCount = async () => {
  try {
    const response = await axios.get(`${API_URL}user/notifications/unread`, {
      withCredentials: true,
    });
    return response.data.unreadCount;
  } catch (error) {
    console.error("Error fetching unread notifications count:", error);
    throw error;
  }
};

const markNotificationsAsRead = async () => {
  try {
    await axios.get(`${API_URL}user/notifications/mark-read`, {
      withCredentials: true,
    });
  } catch (error) {
    console.error("Error marking notifications as read:", error);
    throw error;
  }
};

const markConfettiAsRead = async () => {
  try {
    await axios.get(`${API_URL}user/notifications/mark-confetti-shown`, {
      withCredentials: true,
    });
  } catch (error) {
    console.error("Error marking confetti as read:", error);
    throw error;
  }
};

const unreadConfetti = async () => {
  try {
    const response = await axios.get(`${API_URL}user/notifications/unread-confetti`, {
      withCredentials: true,
    });
    return response.data.hasUnreadConfetti;
  } catch (error) {
    console.error("Error fetching confetti:", error);
    throw error;
  }
};

const getUserMailSignups = async () => {
  try {
    const response = await axios.get(`${API_URL}user/mail-signups`, {
      withCredentials: true, 
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching mail signup settings:", error);
    throw error;
  }
};

// Update user mail signup settings
const setUserMailSignups = async (settings) => {
  try {
    const response = await axios.post(`${API_URL}user/mail-signups`, settings, {
      withCredentials: true, 
    });
    return response.data;
  } catch (error) {
    console.error("Error updating mail signup settings:", error);
    throw error;
  }
};

const getUserPaymentSettings = async () => {
  try {
    const response = await axios.get(`${API_URL}user/payment-settings`, {
      withCredentials: true, 
    });
    return response.data;
  } catch (error) {
    console.error("Error getting payment settings:", error);
    throw error;
  }
};

const setUserPaymentSettings = async (settings) => {
  try {
    const response = await axios.post(`${API_URL}user/payment-settings`, settings, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error updating payment settings:", error);
    throw error;
  }
};

const getAllUserPayments = async (sourceId, startDate, endDate, searchTerm, currentPage, itemsPerPage) => {
  try {
    const response = await axios.post(`${API_URL}user/all-payments`, {
      sourceId,
      startDate,
      endDate,
      searchTerm,
      currentPage,
      itemsPerPage}, {
      withCredentials: true, 
    });
    return response.data;
  } catch (error) {
    console.error("Error getting payments:", error);
    throw error;
  }
};

const getAllUsers = async () => {
  try {
    const response = await axios.get(`${API_URL}user/all-users`, {
      withCredentials: true, 
    });
    return response.data;
  } catch (error) {
    console.error("Error getting all users:", error);
    throw error;
  }
};

const fetchTelegramChatCount = async () => {
  try {
    const response = await axios.get(`${API_URL}user/telegram-count`,{
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching telegram chat count:", error);
    throw error;
  }
}

const deleteAllTelegramChats = async () => {
  try {
    const response = await axios.post(`${API_URL}user/telegram-delete-all`,{}, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting telegram chats:", error);
    throw error;
  }
}

export default {
  deleteAllTelegramChats,
  fetchTelegramChatCount,
  getAllUsers,
  getAllUserPayments,
  setUserPaymentSettings,
  getUserPaymentSettings,
  getUserMailSignups,
  setUserMailSignups,
  getNotifications,
  unreadConfetti,
  markConfettiAsRead,
  getRecentNotifications,
  getUnreadCount,
  markNotificationsAsRead,
  getDashboardBarChartData,
  getDashboardSummary,
  getDashboardEarnings,
  getDashboardHotPrograms,
  checkResetPassword,
  resetPassword,
  forgotPassword,
  changePassword,
  login,
  register,
  logout,
  authenticate,
  logout,
};
