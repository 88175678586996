import React, { useState, useRef, useEffect, useContext } from 'react';
import { NavLink, useNavigate, Outlet, Link, useLocation } from 'react-router-dom';
import styles from './UserLayout.module.scss';
import authService from '../services/authService';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import WebStoriesIcon from '@mui/icons-material/WebStories';
import WebStoriesOutlinedIcon from '@mui/icons-material/WebStoriesOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import LayersIcon from '@mui/icons-material/Layers';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import PaymentsIcon from '@mui/icons-material/Payments';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import NotificationButton from './NotificationButton';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use';
import CampaignIcon from '@mui/icons-material/Campaign';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import { UserResponsiveContext } from '../components/UserResponsiveContext';
import { Tooltip } from '@mui/material';

const UserLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(window.innerWidth >= 770);
  const [sidebarExpanded, setSidebarExpanded] = useState(window.innerWidth >= 1200);
  const [showConfetti, setShowConfetti] = useState(false); // State för konfetti
  const [theme, setTheme] = useState("light"); // Light mode är standard

  const [showDropDown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const sidebarRef = useRef(null);
  const navigate = useNavigate();
  const { width, height } = useWindowSize();

  const { screenSize } = useContext(UserResponsiveContext);

  useEffect(() => {
    if (screenSize < 1200) {
      setSidebarExpanded(false);
    }
  }, [screenSize]);

  useEffect(() => {
    if (screenSize < 770) {
      setSidebarExpanded(true);
    }
  }, [screenSize]);

  const toggleDropdown = () => {
    setShowDropdown(!showDropDown);
  };

  const logout = async () => {
    await authService.logout();
    navigate('/login');
  };

  const handleLinkClick = () => {
    if (window.innerWidth <= 770) {
      setSidebarOpen(false);
    }
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const toggleExpandSidebar = () => {
    setSidebarExpanded(!sidebarExpanded);
  };


  const handleClickOutside = (event) => {
    // Om sidopanelen är öppen på mobil och användaren klickar utanför den, stäng den
    if (sidebarOpen && window.innerWidth <= 770) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarOpen(false);
      }
    }

    // Om klicket är på dropdown-knappen, ignorera det
    if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
      return;
    }

    // Stäng dropdown-menyn om man klickar utanför den
    setShowDropdown(false);
  };


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sidebarOpen, showDropDown]);

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setTheme(savedTheme); // Använd det sparade temat om det finns
    }
  }, []);

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme); // Lagra temat i sessionStorage
  };


  return (
    <div className={styles.container}>
      {showConfetti &&
        <Confetti
          className={styles.confetti}
          width={width}
          numberOfPieces={400}
          gravity={0.07}
          height={height}
          recycle={false} // Låt konfettibitarna försvinna naturligt
          onConfettiComplete={() => setShowConfetti(false)} // Avsluta konfetti automatiskt
          colors={[
            '#E3F2FD', // Ljusblå
            '#BBDEFB', // Ljusare himmelsblå
            '#90CAF9', // Pastellblå
            '#64B5F6', // Klar mellanblå
            '#42A5F5', // Medium blå
            '#2196F3', // Standard blå
            '#1E88E5', // Ljus marinblå
            '#1976D2', // Marinblå
            '#1565C0', // Djup marinblå
            '#0D47A1', // Mörk marinblå
            '#82B1FF', // Ljus neonblå
            '#448AFF', // Ljus elektrisk blå
            '#2979FF', // Elektrisk blå
            '#2962FF', // Djupt elektrisk blå
          ]}
          drawShape={ctx => {
            const size = 20; // Storleken på kvadraten
            ctx.beginPath();
            ctx.rect(-size / 2, -size / 2, size, size); // Rita en kvadrat centrerad runt (0, 0)
            ctx.fill(); // Fyll kvadraten med aktuell fillStyle
            ctx.closePath();
          }}
        />
      }
      <div className={styles.topnav}>
        <div className={styles.topnavRight}>
          <button className={styles.burgerButton} onClick={toggleSidebar}>
            <span className="material-symbols-outlined">menu</span>
          </button>
          <div className={styles.header}>
            <Link to="/" onClick={handleLinkClick}><h1>Savvy</h1></Link>
          </div>
        </div>
        <ul className={styles.topMenu}>
          <li className={`${styles.topMenuItem} ${styles.lightmode}`}>
            <button onClick={toggleTheme}>
              {theme === "light" ? <span class="material-symbols-outlined">
                dark_mode
              </span> : <span class="material-symbols-outlined">
                light_mode
              </span>}
            </button>
          </li>
          <li className={`${styles.topMenuItem} ${styles.notification}`}>
            <NotificationButton setShowConfetti={setShowConfetti} />
          </li>
          <li className={`${styles.topMenuItem} ${styles.profile}`}>
            <button onClick={toggleDropdown} onMouseDown={(e) => e.stopPropagation()}>
              <span className="material-symbols-outlined">person</span>
            </button>
            <div ref={dropdownRef} className={`${styles.dropdown} ${showDropDown ? styles.dropdownOpen : ''}`}>
              <div className={styles.dropdownItem}>
                <NavLink to="/settings" onClick={() => setShowDropdown(false)}>
                  <span className="material-symbols-outlined">settings</span>
                  Settings
                </NavLink>
              </div>
              <div className={styles.dropdownItem}>
                <button onClick={logout}>
                  <span className="material-symbols-outlined">logout</span>
                  Logout
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className={`${styles.afterHeaderContainer} ${sidebarExpanded ? styles.expanded : styles.contracted} ${!sidebarOpen && styles.closed}`}>
        <div ref={sidebarRef} className={styles.sidebar}>
          <div className={styles.sideMenu}>
            <ul>
              <li className={styles.sideMenuItem}>
                {!sidebarExpanded ? (
                  <Tooltip title={<p style={{ margin: '0px' }}>Dashboard</p>} arrow placement="right">
                    <div>

                      <NavLink
                        to="/"
                        className={({ isActive }) => (isActive ? styles.active : undefined)}
                        onClick={handleLinkClick}
                      >
                        <DashboardIcon className={styles.filled} />
                        <DashboardOutlinedIcon className={styles.outlined} />
                      </NavLink>
                    </div>
                  </Tooltip>
                ) : (
                  <NavLink
                    to="/"
                    className={({ isActive }) => (isActive ? styles.active : undefined)}
                    onClick={handleLinkClick}
                  >
                    <DashboardIcon className={styles.filled} />
                    <DashboardOutlinedIcon className={styles.outlined} />
                    <p>Dashboard</p>
                  </NavLink>
                )}
              </li>
              <li className={styles.sideMenuItem}>
                {!sidebarExpanded ? (
                  <Tooltip title={<p style={{ margin: '0px' }}>Sources</p>} arrow placement="right">
                    <div>
                      <NavLink
                        to="/sources"
                        className={({ isActive }) => (isActive ? styles.active : undefined)}
                        onClick={handleLinkClick}
                      >
                        <WebStoriesIcon className={styles.filled} />
                        <WebStoriesOutlinedIcon className={styles.outlined} />
                      </NavLink>
                    </div>
                  </Tooltip>
                ) : (
                  <NavLink
                    to="/sources"
                    className={({ isActive }) => (isActive ? styles.active : undefined)}
                    onClick={handleLinkClick}
                  >
                    <WebStoriesIcon className={styles.filled} />
                    <WebStoriesOutlinedIcon className={styles.outlined} />
                    <p>Sources</p>
                  </NavLink>
                )}
              </li>
              <li className={styles.sideMenuItem}>
                {!sidebarExpanded ? (
                  <Tooltip title={<p style={{ margin: '0px' }}>Programs</p>} arrow placement="right">
                    <div>

                      <NavLink
                        to="/programs"
                        className={({ isActive }) => (isActive ? styles.active : undefined)}
                        onClick={handleLinkClick}
                      >
                        <LayersIcon className={styles.filled} />
                        <LayersOutlinedIcon className={styles.outlined} />
                      </NavLink>
                    </div>

                  </Tooltip>
                ) : (
                  <NavLink
                    to="/programs"
                    className={({ isActive }) => (isActive ? styles.active : undefined)}
                    onClick={handleLinkClick}
                  >
                    <LayersIcon className={styles.filled} />
                    <LayersOutlinedIcon className={styles.outlined} />
                    <p>Programs</p>
                  </NavLink>
                )}
              </li>
              <li className={styles.sideMenuItem}>
                {!sidebarExpanded ? (
                  <Tooltip title={<p style={{ margin: '0px' }}>Performance</p>} arrow placement="right">
                    <div>

                      <NavLink
                        to="/performance"
                        className={({ isActive }) => (isActive ? styles.active : undefined)}
                        onClick={handleLinkClick}
                      >
                        <InsertChartIcon className={styles.filled} />
                        <InsertChartOutlinedIcon className={styles.outlined} />
                      </NavLink>
                    </div>

                  </Tooltip>
                ) : (
                  <NavLink
                    to="/performance"
                    className={({ isActive }) => (isActive ? styles.active : undefined)}
                    onClick={handleLinkClick}
                  >
                    <InsertChartIcon className={styles.filled} />
                    <InsertChartOutlinedIcon className={styles.outlined} />
                    <p>Performance</p>
                  </NavLink>
                )}
              </li>
              <li className={styles.sideMenuItem}>
                {!sidebarExpanded ? (
                  <Tooltip title={<p style={{ margin: '0px' }}>Payments</p>} arrow placement="right">
                    <div>

                      <NavLink
                        to="/payments"
                        className={({ isActive }) => (isActive ? styles.active : undefined)}
                        onClick={handleLinkClick}
                      >
                        <PaymentsIcon className={styles.filled} />
                        <PaymentsOutlinedIcon className={styles.outlined} />
                      </NavLink>
                    </div>

                  </Tooltip>
                ) : (
                  <NavLink
                    to="/payments"
                    className={({ isActive }) => (isActive ? styles.active : undefined)}
                    onClick={handleLinkClick}
                  >
                    <PaymentsIcon className={styles.filled} />
                    <PaymentsOutlinedIcon className={styles.outlined} />
                    <p>Payments</p>
                  </NavLink>
                )}
              </li>
              <br />
              <li className={styles.sideMenuItem}>
                {!sidebarExpanded ? (
                  <Tooltip title={<p style={{ margin: '0px' }}>Telegram Bot</p>} arrow placement="right">
                    <div>
                      <NavLink
                        to="services/telegram-bot"
                        className={({ isActive }) => (isActive ? styles.active : undefined)}
                        onClick={handleLinkClick}
                      >
                        <SmartToyIcon className={styles.filled} />
                        <SmartToyOutlinedIcon className={styles.outlined} />
                      </NavLink>
                    </div>
                  </Tooltip>
                ) : (
                  <NavLink
                    to="services/telegram-bot"
                    className={({ isActive }) => (isActive ? styles.active : undefined)}
                    onClick={handleLinkClick}
                  >
                    <SmartToyIcon className={styles.filled} />
                    <SmartToyOutlinedIcon className={styles.outlined} />
                    <p>Telegram Bot</p>
                  </NavLink>
                )}
              </li>
              <li className={styles.sideMenuItem}>
                {!sidebarExpanded ? (
                  <Tooltip title={<p style={{ margin: '0px' }}>Facebook Ads</p>} arrow placement="right">
                    <div>

                      <NavLink
                        to="services/facebook-ads"
                        className={({ isActive }) => (isActive ? styles.active : undefined)}
                        onClick={handleLinkClick}
                      >
                        <CampaignIcon className={styles.filled} />
                        <CampaignOutlinedIcon className={styles.outlined} />
                      </NavLink>
                    </div>

                  </Tooltip>
                ) : (
                  <NavLink
                    to="services/facebook-ads"
                    className={({ isActive }) => (isActive ? styles.active : undefined)}
                  >
                    <CampaignIcon className={styles.filled} />
                    <CampaignOutlinedIcon className={styles.outlined} />
                    <p>Facebook Ads</p>
                  </NavLink>
                )}
              </li>
            </ul>

          </div >
          <div className={styles.toggleSidebar}>
            <span className="material-symbols-outlined" onClick={toggleExpandSidebar}>
              chevron_left
            </span>
          </div>
        </div >
        {
          sidebarOpen && window.innerWidth <= 770 && (
            <div className={styles.overlay} onClick={() => setSidebarOpen(false)}></div>
          )
        }
        <div className={styles.main}>
          <Outlet />
        </div>
      </div >

    </div >
  );
};

export default UserLayout;
