import React, { useState, useEffect, useRef } from 'react';
import adminService from '../../services/adminService';
import programService from '../../services/programService';
import Select from 'react-select';
import PaginationComponent from '../../components/PaginationComponent';
import styles from './Programs.module.scss';
import placeholder from '../../images/placeholder.jpg';
import Flag from 'react-world-flags';
import Modal from 'react-modal';
import SetInformation from './components/SetInformation';
import EditStandradDealModal from './components/EditStandradDealModal';
import AddNewRegionDealModal from './components/AddNewRegionDealModal';
import { Tooltip } from '@mui/material';

Modal.setAppElement('#root');

const Programs = () => {
  const [programs, setPrograms] = useState([]);
  const [brands, setBrands] = useState([]);
  const [verticals, setVerticals] = useState([]);
  const [regions, setRegions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showDealModal, setShowDealModal] = useState(false);
  const [selectedProgramRegion, setSelectedProgramRegion] = useState(null);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [selectedProgramInformation, setSelectedProgramInformation] = useState(null);
  const [showAddRegionModal, setShowAddRegionModal] = useState(false);
  const [showInformationModal, setShowInformationModal] = useState(false);
  // Tidigare var detta en boolean. Nu är det ett id eller null.
  const [openDropdownProgramId, setOpenDropdownProgramId] = useState(null);

  const [filters, setFilters] = useState({
    brandId: null,
    verticalId: null,
    regionId: null,
  });

  const statusOptions = [
    { value: 'Active', label: 'Active' },
    { value: 'Inactive', label: 'Inactive' },
  ];

  useEffect(() => {
    fetchFilters();
  }, []);

  const openDealModal = (region, program) => {
    setSelectedProgramRegion(region);
    setSelectedProgram(program);
    setShowDealModal(true);
  };

  const openAddRegionModal = (program) => {
    setSelectedProgram(program);
    setShowAddRegionModal(true);
  };

  const openInformationModal = (program) => {
    setSelectedProgramInformation(program);
    setShowInformationModal(true);
  };

  const handleAddRegionSubmit = async (newDealData) => {
    try {
      await adminService.addNewRegionDeal(selectedProgram.id, newDealData);
      alert('New region deal added successfully.');
      setShowAddRegionModal(false);
      fetchProgramsData();
    } catch (error) {
      console.error('Error adding new region deal:', error);
      alert('Failed to add new region deal.');
    }
  };

  useEffect(() => {
    fetchProgramsData();
  }, [currentPage, itemsPerPage]);

  const fetchFilters = async () => {
    try {
      const [brandsData, verticalsData, regionsData] = await Promise.all([
        programService.fetchAllBrands(),
        programService.fetchAllVerticals(),
        programService.fetchAllRegions(),
      ]);

      setBrands(brandsData.map((brand) => ({ value: brand.id, label: brand.name })));
      setVerticals(verticalsData.map((vertical) => ({ value: vertical.id, label: vertical.name })));
      setRegions(regionsData.map((region) => ({ value: region.id, label: region.name })));
    } catch (error) {
      console.error('Error fetching filter options:', error);
    }
  };

  const handleSubmitDealChange = async (updatedDealData) => {
    try {
      await adminService.updateStandardDeal(selectedProgramRegion.id, updatedDealData);
      alert('Deal updated successfully.');
      closeDealModal();
      fetchProgramsData();
    } catch (error) {
      console.error('Error updating deal:', error);
      alert('Failed to update deal.');
    }
  };

  const handleSubmitInformation = async (payload) => {
    try {
      await adminService.submitInformation(payload);
      alert('Information updated successfully.');
      fetchProgramsData();
    } catch (error) {
      console.error('Error updating information:', error);
      alert('Failed to update information.');
    }
  };

  const fetchProgramsData = async () => {
    try {
      const data = await adminService.fetchPrograms(currentPage, itemsPerPage, { ...filters, searchTerm });
      setPrograms(data.programs || []);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error('Error fetching programs:', error);
    }
  };

  const handleFilterChange = (selectedOption, { name }) => {
    setFilters({
      ...filters,
      [name]: selectedOption ? selectedOption.value : null,
    });
  };

  const handleApplyFilters = () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    } else {
      fetchProgramsData();
    }
  };

  const closeDealModal = () => {
    setShowDealModal(false);
    setSelectedProgramRegion(null);
    setSelectedProgram(null);
  };

  const getIconName = (type) => {
    switch (type) {
      case "general":
        return "info";
      case "delay":
        return "schedule";
      case "warning":
        return "warning";
      default:
        return "help";
    }
  };

  const getClassName = (type) => {
    switch (type) {
      case "general":
        return styles.general;
      case "delay":
        return styles.delay;
      case "warning":
        return styles.warning;
      default:
        return styles.default;
    }
  };

  // Hantera utanför klick för att stänga dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Om vi inte har någon dropdown öppen, behövs inget göras.
      if (openDropdownProgramId === null) return;

      // Kolla om klicket skedde inne i en container för öppna dropdownen
      // eller på knappen själv. Om inte - stäng dropdown.
      const editContainers = document.querySelectorAll(`.${styles.editContainer}`);
      let clickedInsideDropdown = false;

      editContainers.forEach(container => {
        if (container.contains(event.target)) {
          clickedInsideDropdown = true;
        }
      });

      if (!clickedInsideDropdown) {
        setOpenDropdownProgramId(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openDropdownProgramId]);

  const setProgramDowntime = async (programId, currentlyActive) => {
    if (window.confirm(`Do you really want to set this program to ${currentlyActive ? "inactive" : "Active"}?`)) {
      await programService.toggleProgramDownTime(programId);
      fetchProgramsData()
    }
  }

  const setProgramRegionDowntime = async (programRegionId, currentlyActive) => {
    if (window.confirm(`Do you really want to set this region to ${currentlyActive ? "inactive" : "Active"}?`)) {
      await programService.toggleProgramRegionDownTime(programRegionId);
      fetchProgramsData()
    }
  }

  return (
    <div className={styles.programsContainer}>
      <h1>Programs</h1>

      {/* Filters */}
      <div className={styles.filterContainer}>
        <div>
          <label>Brand</label>
          <Select
            isClearable
            name="brandId"
            value={brands.find((option) => option.value === filters.brandId)}
            onChange={handleFilterChange}
            options={brands}
          />
        </div>
        <div>
          <label>Vertical</label>
          <Select
            isClearable
            name="verticalId"
            value={verticals.find((option) => option.value === filters.verticalId)}
            onChange={handleFilterChange}
            options={verticals}
          />
        </div>
        <div>
          <label>Region</label>
          <Select
            isClearable
            name="regionId"
            value={regions.find((option) => option.value === filters.regionId)}
            onChange={handleFilterChange}
            options={regions}
          />
        </div>
      </div>

      {/* Search Input */}
      <div className={styles.searchContainer}>
        <input
          type="text"
          placeholder="Search programs..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button onClick={handleApplyFilters} className={styles.applyFilters}>Apply Filters</button>
      </div>

      {/* Programs Cards */}
      <div className={styles.cardsContainer}>
        {programs.map((program) => (
          <div className={styles.programCard} key={program.id}>
            {/* Program Details */}
            <div className={styles.programDetails}>
              <div>
                <div className={styles.programInformations}>
                  {program.ProgramInformations?.length > 0 &&
                    program.ProgramInformations.map((info) => (
                      <Tooltip key={info.id} title={info.message} arrow>
                        <div className={`${styles.informationItem} ${getClassName(info.type)}`}>
                          <span className="material-symbols-outlined">
                            {getIconName(info.type)}
                          </span>
                        </div>
                      </Tooltip>
                    ))}
                </div>
                <img
                  src={program.Brand.image ? `/uploads/brandLogos/${program.Brand.image}` : placeholder}
                  alt={program.name}
                  className={styles.programImage}

                />
              </div>
              <div className={styles.programInfo}>
                <h2>{program.name}</h2>
                <p className={styles.status}>Status: {program.ProgramDowntimes.length > 0 ?
                  <Tooltip title={<p style={{ margin: '0px' }}>{"Downtime start: " + program.ProgramDowntimes[0].startDate}</p>} arrow>
                    <div className={styles.red}>
                      <span className="material-symbols-outlined">cancel</span>
                    </div>
                  </Tooltip> :
                  <div className={styles.green}>
                    <span className="material-symbols-outlined">check_circle</span>
                  </div>
                }</p>
                <p>{program.Brand.name}</p>
                <p>{program.Verticals.map(v => v.name).join(", ")}</p>
                {program.aggregatedData && <p>(Aggregated Data)</p>}

                <div className={styles.editContainer}>
                  <button
                    className={styles.editBtn}
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenDropdownProgramId(
                        openDropdownProgramId === program.id ? null : program.id
                      );
                    }}
                  >
                    <span className="material-symbols-outlined">
                      edit
                    </span>
                    Edit
                  </button>
                  {openDropdownProgramId === program.id && (
                    <div className={styles.dropdown}>
                      {program.ProgramDowntimes.length === 0 && (
                        <button
                          onClick={() => {
                            setOpenDropdownProgramId(null);
                            openAddRegionModal(program);
                          }}
                        >
                          Add New Region
                        </button>
                      )}
                      <button
                        onClick={() => {
                          setOpenDropdownProgramId(null);
                          openInformationModal(program);
                        }}
                      >
                        {program.programInformation ? "Edit" : "Set"} Information
                      </button>
                      <button
                        onClick={() => {
                          setProgramDowntime(program.id, program.ProgramDowntimes.length === 0)
                        }}
                      >
                        Set as {program.ProgramDowntimes.length > 0 ? "Active" : "Inactive"}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className={styles.regionsContainer}>
              <div className={`${styles.regionRow} ${styles.headerRow}`}>
                <div className={styles.regionCell}>Region</div>
                <div className={styles.regionCell}>Description</div>
                <div className={styles.regionCell}>Baseline</div>
                <div className={styles.regionCell}>Savvy CPA</div>
                <div className={styles.regionCell}>Customer CPA</div>
                <div className={styles.regionCell}>Savvy Revshare</div>
                <div className={styles.regionCell}>Customer Revshare</div>
                <div className={styles.regionCell}>Action</div>
                <div className={styles.regionCell}>Info</div>
              </div>
              {program.ProgramRegions.map((region) => (
                <div key={region.id} className={styles.regionRow}>
                  <div className={styles.regionCell}>
                    {region.currentDeals && region.currentDeals.length > 0 && region.currentDeals[0].validTo !== null ? (
                      region.nextDeals && region.nextDeals.length > 0 ? (
                        <Tooltip
                          title={
                            <div className={styles.changeTooltip}>
                              <p>Current deal expires on: <strong>{new Date(region.currentDeals[0].validTo).toLocaleDateString()}</strong></p>
                              <p>Next deal starts on: <strong>{new Date(region.nextDeals[0].validFrom).toLocaleDateString()}</strong></p>
                              <p>Baseline: <strong>{region.nextDeals[0].Deal.baseline} €</strong></p>
                              <p>CPA Before: <strong>{region.nextDeals[0].Deal.CPAbeforeCommission} €</strong></p>
                              <p>CPA: <strong>{region.nextDeals[0].Deal.CPA} €</strong></p>
                              <p>Revshare Before: <strong>{(region.nextDeals[0].Deal.revShareBeforeCommission * 100).toFixed(2)}%</strong></p>
                              <p>Revshare: <strong>{(region.nextDeals[0].Deal.revshare * 100).toFixed(2)}%</strong></p>
                            </div>
                          }
                          arrow
                        >
                          <div className={`${styles.dealInfo} ${styles.dealChange}`}>
                            <span className="material-symbols-outlined">autorenew</span>
                          </div>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={
                            <div className={styles.expireTooltip}>
                              <p>Current deal expires on: <strong>{new Date(region.currentDeals[0].validTo).toLocaleDateString()}</strong></p>
                            </div>
                          }
                          arrow
                        >
                          <div className={`${styles.dealInfo} ${styles.dealExpire}`}>
                            <span className="material-symbols-outlined">hourglass_top</span>
                          </div>
                        </Tooltip>
                      )
                    ) : null}
                    <Flag code={region.Region.shortCode} style={{ width: '30px', height: '20px' }} alt="flag" />
                  </div>

                  <div className={styles.regionCell}>{region.description || 'N/A'}</div>
                  <div className={styles.regionCell}>
                    {region.currentDeals && region.currentDeals.length > 0 ? `${region.currentDeals[0].Deal.baseline} €` : 'N/A'}
                  </div>
                  <div className={styles.regionCell}>
                    {region.currentDeals && region.currentDeals.length > 0 ? `${region.currentDeals[0].Deal.CPAbeforeCommission} €` : 'N/A'}
                  </div>
                  <div className={styles.regionCell}>
                    {region.currentDeals && region.currentDeals.length > 0 ? `${region.currentDeals[0].Deal.CPA} €` : 'N/A'}
                  </div>
                  <div className={styles.regionCell}>
                    {region.currentDeals && region.currentDeals.length > 0 ? `${(region.currentDeals[0].Deal.revShareBeforeCommission * 100).toFixed(2)}%` : 'N/A'}
                  </div>
                  <div className={styles.regionCell}>
                    {region.currentDeals && region.currentDeals.length > 0 ? `${(region.currentDeals[0].Deal.revshare * 100).toFixed(2)}%` : 'N/A'}
                  </div>
                  <div className={`${styles.regionCell} ${styles.buttonCell}`}>
                    <button onClick={() => openDealModal(region, program)} className={styles.editDeal} disabled={region.nextDeals && region.nextDeals.length > 0}>Edit Deal</button>
                    {program.ProgramDowntimes.length === 0 &&
                      <button
                        onClick={() => {
                          setProgramRegionDowntime(region.id, region.ProgramDowntimes.length === 0)
                        }}
                        className={`${styles.editDeal} ${region.ProgramDowntimes.length === 0 ? styles.currentlyInactive : styles.currentlyActive}`}
                      >
                        {region.ProgramDowntimes.length === 0 ? "Active" : "Inactive"}
                      </button>
                    }
                  </div>
                  <div className={styles.regionCell}>
                    {region.ProgramInformations?.length > 0 &&
                      region.ProgramInformations.map((info) => (
                        <Tooltip key={info.id} title={info.message} arrow>
                          <div className={`${styles.informationItem} ${getClassName(info.type)}`}>
                            <span className="material-symbols-outlined">
                              {getIconName(info.type)}
                            </span>
                          </div>
                        </Tooltip>
                      ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {
        showDealModal && (
          <EditStandradDealModal
            isOpen={showDealModal}
            onRequestClose={closeDealModal}
            selectedProgram={selectedProgram}
            selectedProgramRegion={selectedProgramRegion}
            onSubmit={handleSubmitDealChange}
          />
        )
      }
      {
        showAddRegionModal && (
          <AddNewRegionDealModal
            isOpen={showAddRegionModal}
            onRequestClose={() => setShowAddRegionModal(false)}
            selectedProgram={selectedProgram}
            onSubmit={handleAddRegionSubmit}
          />
        )
      }
      {
        showInformationModal && (
          <SetInformation
            isOpen={showInformationModal}
            onRequestClose={() => setShowInformationModal(false)}
            onSubmit={handleSubmitInformation}
            program={selectedProgramInformation}
          />
        )
      }

      <PaginationComponent
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={(event, newPage) => setCurrentPage(newPage)}
      />
    </div>
  );
};

export default Programs;
