import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import styles from './ManualNotificationModal.module.scss';

const SetInformation = ({
    isOpen,
    onRequestClose,
    onSubmit,
    user,
    isGlobal
}) => {
    // State for title and message inputs
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    console.log(user)
    // Reset form fields when the modal is closed
    useEffect(() => {
        if (!isOpen) {
            setTitle('');
            setMessage('');
        }
    }, [isOpen]);

    const handleSubmit = () => {
        // Pass title, message, and user.id to the onSubmit function
        if (isGlobal) {
            onSubmit(title, message, null, true);

        } else {
            onSubmit(title, message, user.id, false);
        }
        // Reset fields after submission
        setTitle('');
        setMessage('');
        onRequestClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => {
                onRequestClose();
                setTitle('');
                setMessage('');
            }}
            className={styles.modal}
            overlayClassName={styles.modalOverlay}
        >
            <div className={styles.modalHeader}>
                <h3>Set Notification for {user?.email || "All Users"}</h3>
                <button className={styles.modalClose} onClick={() => onRequestClose()}>
                    <span class="material-symbols-outlined">
                        close
                    </span>
                </button>
            </div>

            <div className={styles.modalContent}>

                <label>
                    Set Title
                    <input
                        type="text"
                        className={styles.input}
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </label>
                <label>
                    Set Message
                    <textarea
                        className={styles.input}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                </label>
            </div>
            <div className={styles.modalActions}>
                <button
                    className={styles.submitButton}
                    onClick={handleSubmit}
                    disabled={!title.trim() || !message.trim()} // Disable until fields are filled
                >
                    Submit
                </button>
                <button
                    className={styles.cancelButton}
                    onClick={() => {
                        onRequestClose();
                        setTitle('');
                        setMessage('');
                    }}
                >
                    Cancel
                </button>
            </div>
        </Modal>
    );
};

export default SetInformation;
