import React, { useEffect, useState } from 'react';
import styles from './FacebookAds.module.scss';
import FacebookIcon from '@mui/icons-material/Facebook';
const FacebookAds = () => {

    return (
        <div className={styles.facebookAdsContainer}>
            <div>
                <FacebookIcon />
                <div>
                    <p>Unlock the potential of your brand with targeted media buying on Meta platforms. Our expert team is here to help you reach your desired audience, maximize your ad spend efficiency, and achieve your marketing goals.
                    </p>
                    <p className={styles.extraText}>Contact you account manager for more information!</p>
                </div>
            </div>
        </div>
    );
};

export default FacebookAds;
