import React, { useState } from "react";
import MailSettings from "./settings/MailSettings";
import PaymentSettings from "./settings/PaymentSettings";
import TelegramSettings from "./settings/TelegramSettings";
import styles from "./Settings.module.scss";

const Settings = () => {
    const [activeSetting, setActiveSetting] = useState("payment"); // Default active setting

    const renderActiveSetting = () => {
        switch (activeSetting) {
            case "mail":
                return <MailSettings />;
            case "payment":
                return <PaymentSettings />;
            case "telegram":
                return <TelegramSettings />;
            default:
                return null;
        }
    };

    return (
        <div className={styles.settingsPage}>
            <div className={styles.settingsNav}>
                <button
                    onClick={() => setActiveSetting("payment")}
                    className={`${styles.settingsNavItem} ${activeSetting === "payment" ? styles.active : ""
                        }`}
                >
                    Payment Details
                </button>
                <button
                    onClick={() => setActiveSetting("mail")}
                    className={`${styles.settingsNavItem} ${activeSetting === "mail" ? styles.active : ""
                        }`}
                >
                    Mail Notifactions
                </button>
                <button
                    onClick={() => setActiveSetting("telegram")}
                    className={`${styles.settingsNavItem} ${activeSetting === "telegram" ? styles.active : ""
                        }`}
                >
                    Telegram Notifactions
                </button>
            </div>
            <div className={styles.settingsForm}>{renderActiveSetting()}</div>
        </div>
    );
};

export default Settings;
