import React, { useEffect, useState } from 'react';
import styles from './TelegramBot.module.scss';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import BotDemo from '../../components/BotDemo';
const TelegramBot = () => {

    return (
        <div className={styles.telegramContainer}>
            <div className={styles.telegramContent}>
                <SmartToyOutlinedIcon />
                <h2>Cooming Soon</h2>
            </div>
        </div>
    );
};

export default TelegramBot;
