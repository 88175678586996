import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select'; // Import react-select for dropdowns
import PaginationComponent from '../../components/PaginationComponent';
import sourceService from '../../services/sourceService';
import authService from '../../services/authService'; // Service for fetching payments
import styles from './Payments.module.scss'; // Custom styles
import DateSelect from '../../components/DateSelect';

const Payments = () => {
    const [payments, setPayments] = useState([]);
    const [sources, setSources] = useState([]);
    const [selectedSource, setSelectedSource] = useState(null);
    const [appliedDateRange, setAppliedDateRange] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        fetchInitialData();
    }, []);

    const fetchInitialData = async () => {
        try {
            const sourcesData = await sourceService.getAllSources();
            setSources(sourcesData);
            fetchPaymentsData(null, null, "", 1, itemsPerPage);
        } catch (error) {
            console.error('Error fetching initial data:', error);
        }
    };

    const fetchPaymentsData = async (sourceId, dateRange, searchTerm, currentPage, itemsPerPage) => {
        try {
            const formatDate = (date) => {
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                return `${year}-${month}-${day}`;
            };

            const startDate = dateRange?.startDate ? formatDate(dateRange.startDate) : null;
            const endDate = dateRange?.endDate ? formatDate(dateRange.endDate) : null;

            const response = await authService.getAllUserPayments(
                sourceId,
                startDate,
                endDate,
                searchTerm,
                currentPage,
                itemsPerPage
            );

            setPayments(response.payments);
            setTotalPages(response.pagination.totalPages);
        } catch (error) {
            console.error('Error fetching payments data:', error);
        }
    };

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
        fetchPaymentsData(
            selectedSource?.value || null,
            appliedDateRange,
            searchTerm,
            newPage,
            itemsPerPage
        );
    };

    const handleApplyFilters = () => {
        fetchPaymentsData(
            selectedSource?.value || null,
            appliedDateRange,
            searchTerm,
            1,
            itemsPerPage
        );
    };

    const handleSourceChange = (selectedOption) => {
        setSelectedSource(selectedOption);
    };

    return (
        <div className={styles.paymentsContainer}>
            <h1>Payments</h1>
            <div className={styles.filterForm}>
                <input
                    type="text"
                    placeholder="Search payments id..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className={styles.input}
                />
                <Select
                    name="source"
                    value={
                        selectedSource
                    }
                    onChange={handleSourceChange}
                    options={sources.map((source) => ({
                        value: source.id,
                        label: source.name,
                    }))}
                    placeholder="Select source"
                    isClearable
                />

                <DateSelect dateRange={appliedDateRange} setDateRange={setAppliedDateRange} boxShadow={true} />
                <button onClick={handleApplyFilters} className={styles.applyFiltersBtn}>
                    Apply Filters
                </button>
            </div>
            <div className={styles.tableContainer}>
                <table className={styles.tableFullWidth}>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Source</th>
                            <th>Month</th>
                            <th>Created At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {payments.map((payment) => (
                            <tr key={payment.id}>
                                <td><a href={`${process.env.REACT_APP_BASE_URL}/api/performance/view-pdf?pdfId=${payment.id}`} target="_blank">{payment.id}</a></td>
                                <td>{payment.Source.name}</td>
                                <td>{new Date(payment.month).toISOString().slice(0, 7)}</td>
                                <td>{payment.createdAt.split("T")[0]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <PaginationComponent
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                totalPages={totalPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
            />
        </div>
    );
};

export default Payments;
