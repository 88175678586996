import React, { useState, useEffect } from 'react';
import styles from './Payments.module.scss';
import sourceService from '../../services/sourceService';
import DateSelect from '../../components/DateSelect';
import performanceService from '../../services/performanceService';
import Skeleton from '@mui/material/Skeleton';

const Payments = () => {
  useEffect(() => {
    document.title = 'Savvy - Earnings';
  }, []);

  const [earningsData, setEarningsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [appliedDateRange, setAppliedDateRange] = useState(null);
  const [pdfs, setPdfs] = useState([]);
  const [currentPage, setCurrentPage] = useState('payouts');
  const [totalPayoutsProgram, setTotalPayoutsProgram] = useState(0);
  const [totalPayoutsExtra, setTotalPayoutExtra] = useState(0);

  const tabs = [
    { id: 'payouts', label: 'Payouts' },
    { id: 'earnings', label: 'Earnings' },
    { id: 'referrals', label: 'Referrals' },
  ];

  useEffect(() => {
    fetchEarnings();
  }, [appliedDateRange]);


  useEffect(() => {
    const getPdfs = async () => {
      setLoading(true);
      const pdfs = await performanceService.getUserPdfs();
      setPdfs(pdfs);
      setLoading(false);
    };
    getPdfs();
  }, []);

  useEffect(() => {
    const getPayoutOverview = async () => {
      setLoading(true);
      const overview = await performanceService.getPayoutOverview();
      setTotalPayoutExtra(overview.totalPayoutsExtra);;
      setTotalPayoutsProgram(overview.totalPayoutsProgram);
      setLoading(false);
    };
    getPayoutOverview();
  }, []);


  const fetchEarnings = async () => {
    setLoading(true);
    try {
      const data = await sourceService.fetchEarnings(appliedDateRange?.startDate || null, appliedDateRange?.endDate || null);
      setEarningsData(data);
    } catch (error) {
      console.error('Error fetching earnings data:', error);
    } finally {
      setLoading(false);
    }
  };

  const formatCurrency = (amount) => {
    return `${amount && amount > 0 ? amount.toFixed(2) : '0.00'} €`;
  };

  const renderActiveTabContent = () => {
    switch (currentPage) {
      case 'earnings':
        return (
          <div className={styles.earningsContainer}>

            <div className={styles.totalEarnings}>
              <div className={styles.header}>
                <h2>Earnings</h2>
                <div className={styles.filterForm}>
                  <DateSelect dateRange={appliedDateRange} setDateRange={setAppliedDateRange} boxShadow={false} mobileVersionSmall={true} />
                </div>
              </div>
              <div className={styles.earningsBreakdown}>
                <div className={styles.infoBoxes}>
                  {loading ?
                    <>
                      <div className={styles.infoBox}>
                        <Skeleton variant="text" width="100%" height={52} style={{ marginTop: "-10px" }} />
                        <Skeleton variant="text" width="50%" height={20} style={{ marginTop: "-5px" }} />
                      </div>
                      <div className={styles.infoBox}>
                        <Skeleton variant="text" width="100%" height={52} style={{ marginTop: "-10px" }} />
                        <Skeleton variant="text" width="50%" height={20} style={{ marginTop: "-5px" }} />
                      </div>
                      <div className={styles.infoBox}>
                        <Skeleton variant="text" width="100%" height={52} style={{ marginTop: "-10px" }} />
                        <Skeleton variant="text" width="50%" height={20} style={{ marginTop: "-5px" }} />
                      </div>
                    </>
                    :
                    <>
                      <div className={styles.infoBox}>
                        <h3>{formatCurrency(earningsData.totalCPAIncome)}</h3>
                        <p>CPA Income</p>
                      </div>
                      <div className={styles.infoBox}>
                        <h3>{formatCurrency(earningsData.totalRevShareIncome)}</h3>
                        <p>RevShare Income</p>
                      </div>
                      <div className={styles.infoBox}>
                        <h3>{formatCurrency(earningsData.totalIncome)}</h3>
                        <p>Total Earnings</p>
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>


            {earningsData.breakdownBySource.length > 0 && (
              <div className={styles.tableOuter}>
                <table className={styles.tableFullWidth}>
                  <thead>
                    <tr>
                      <th>Source</th>
                      <th>CPA</th>
                      <th>RevShare</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {earningsData.breakdownBySource.map((sourceData) => (
                      <tr key={sourceData.sourceId}>
                        <td>{sourceData.sourceName}</td>
                        <td>{formatCurrency(sourceData.totalCPAIncome)}</td>
                        <td>{formatCurrency(sourceData.totalRevShareIncome)}</td>
                        <td>
                          {formatCurrency(
                            sourceData.totalCPAIncome + sourceData.totalRevShareIncome
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        );

      case 'payouts':
        return (
          <div className={styles.pdfContainer}>
            <div className={styles.pdfFirstCont}>
              <h2>Payouts</h2>
              <div className={styles.payoutsOverview}>
                <div className={styles.infoBoxes}>
                  {loading ?
                    <>
                      <div className={styles.infoBox}>
                        <Skeleton variant="text" width="100%" height={52} style={{ marginTop: "-10px" }} />
                        <Skeleton variant="text" width="50%" height={20} style={{ marginTop: "-5px" }} />
                      </div>
                      <div className={styles.infoBox}>
                        <Skeleton variant="text" width="100%" height={52} style={{ marginTop: "-10px" }} />
                        <Skeleton variant="text" width="50%" height={20} style={{ marginTop: "-5px" }} />
                      </div>
                      <div className={styles.infoBox}>
                        <Skeleton variant="text" width="100%" height={52} style={{ marginTop: "-10px" }} />
                        <Skeleton variant="text" width="50%" height={20} style={{ marginTop: "-5px" }} />
                      </div>
                      <div className={styles.infoBox}>
                        <Skeleton variant="text" width="100%" height={52} style={{ marginTop: "-10px" }} />
                        <Skeleton variant="text" width="50%" height={20} style={{ marginTop: "-5px" }} />
                      </div>
                    </>
                    :
                    <>
                      <div className={styles.infoBox}>
                        <h3>{formatCurrency(totalPayoutsProgram + totalPayoutsExtra)}</h3>
                        <p>Paid</p>
                      </div>
                      <div className={styles.infoBox}>
                        <h3>{/*formatCurrency(earningsData?.totalIncome - totalPayoutsProgram)*/}-</h3>
                        <p>Avilable Balance</p>
                      </div>
                      <div className={styles.infoBox}>
                        <h3>{formatCurrency(earningsData?.totalIncome)}</h3>
                        <p>Earned</p>
                      </div>
                      <div className={styles.infoBox}>
                        <h3>500.00€</h3>
                        <p>Min. Payout</p>
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
            {pdfs.length > 0 && (
              <div className={styles.tableOuter}>
                <table className={styles.tableFullWidth}>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Source</th>
                      <th>Month</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pdfs.map((pdf) => (
                      <tr key={pdf.pdf.id}>
                        <td>
                          <a
                            href={`${process.env.REACT_APP_BASE_URL}/api/performance/view-pdf?pdfId=${pdf.pdf.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.pdfLink}
                          >
                            {pdf.pdf.id}
                            <span class="material-symbols-outlined">
                              open_in_new
                            </span>
                          </a>
                        </td>
                        <td>{pdf.sourceName}</td>
                        <td>
                          {pdf.pdf.month}
                        </td>
                      </tr>
                    ))}
                  </tbody>

                </table>
              </div>
            )}
          </div>
        );

      case 'referrals':
        return <div className={styles.referralContainer}>Coming Soon!</div>;

      default:
        return null;
    }
  };

  return (
    <div className={styles.paymentsContainer}>
      <h1>Payments</h1>

      {/* Navigation */}
      <div className={styles.paymentsNav}>
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={currentPage === tab.id ? styles.active : ''}
            onClick={() => setCurrentPage(tab.id)}
            disabled={currentPage === tab.id}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {/* Content */}
      {renderActiveTabContent()}
    </div>
  );
};

export default Payments;
