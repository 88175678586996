import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import UserLayout from "./layout/UserLayout";
import AdminLayout from "./layout/AdminLayout";

import PrivateRoute from "./security/privateRoute";
import PublicRoute from "./security/publicRoute";
import StandardRoute from "./security/standardRoute";

// ADMIN
import ProgramRequests from "./pages/admin/ProgramRequests";
import CreateProgram from "./pages/admin/CreateProgram";
import AdminPrograms from "./pages/admin/Programs";
import AdminSources from "./pages/admin/Sources";
import AdminUsers from "./pages/admin/Users";
import AdminSourcePrograms from "./pages/admin/SourcePrograms";
import AdminPerformance from "./pages/admin/Performance";
import AppSettings from "./pages/admin/Settings";
import MonthlySummary from "./pages/admin/MonthlySummary"
import AdminPayments from "./pages/admin/Payments";

// USER
import Dashboard from "./pages/user/Dashboard";
import Sources from "./pages/user/Sources";
import Programs from "./pages/user/Programs";
import Performance from "./pages/user/Performance";
import Payments from "./pages/user/Payments";
import TelegramBot from "./pages/user/TelegramBot";
import FacebookAds from "./pages/user/FacebookAds";
import Notification from "./pages/user/Notification";
import Settings from "./pages/user/Settings";

// SHARED
import Login from "./pages/shared/Login";
import ForgotPassword from "./pages/shared/ForgotPassword";
import ResetPassword from "./pages/shared/ResetPassword";
import Register from "./pages/shared/Register";
import Unauthorized from "./pages/shared/Unauthorized";
import NotFound from "./pages/shared/NotFound";
import Homepage from "./pages/admin/HomePage";

import { UserResponsiveProvider } from './components/UserResponsiveContext';

const App = () => {
  return (
    <UserResponsiveProvider>

    <Router>
      <Routes>
        <Route path="/" element={<StandardRoute />}>
          <Route path="/" element={<UserLayout />}>
            <Route
              path=""
              element={
                <PrivateRoute requiredRole="User">
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="sources"
              element={
                <PrivateRoute requiredRole="User">
                  <Sources />
                </PrivateRoute>
              }
            />
            <Route
              path="programs"
              element={
                <PrivateRoute requiredRole="User">
                  <Programs />
                </PrivateRoute>
              }
            />
            <Route
              path="performance"
              element={
                <PrivateRoute requiredRole="User">
                  <Performance />
                </PrivateRoute>
              }
            />
            <Route
              path="payments"
              element={
                <PrivateRoute requiredRole="User">
                  <Payments />
                </PrivateRoute>
              }
            />
                        <Route
              path="services/telegram-bot"
              element={
                <PrivateRoute requiredRole="User">
                  <TelegramBot />
                </PrivateRoute>
              }
            />
                                    <Route
              path="services/facebook-ads"
              element={
                <PrivateRoute requiredRole="User">
                  <FacebookAds />
                </PrivateRoute>
              }
            />
                        <Route
              path="/notifications"
              element={
                <PrivateRoute requiredRole="User">
                  <Notification />
                </PrivateRoute>
              }
            />
                                    <Route
              path="/settings"
              element={
                <PrivateRoute requiredRole="User">
                  <Settings />
                </PrivateRoute>
              }
            />
            
          </Route>

        </Route>

        <Route
          path="/admin"
          element={
            <PrivateRoute requiredRole="Admin">
              <AdminLayout />
            </PrivateRoute>
          }>
                      <Route
            path=""
            element={
              <PrivateRoute requiredRole="Admin">
                <Homepage />
              </PrivateRoute>
            }
          />
          <Route
            path="sources"
            element={
              <PrivateRoute requiredRole="Admin">
                <AdminSources />
              </PrivateRoute>
            }
          />
          <Route
            path="settings"
            element={
              <PrivateRoute requiredRole="Admin">
                <AppSettings />
              </PrivateRoute>
            }
          />
          <Route
            path="programs/all"
            element={
              <PrivateRoute requiredRole="Admin">
                <AdminPrograms />
              </PrivateRoute>
            }
          />
          <Route
            path="programs/requests"
            element={
              <PrivateRoute requiredRole="Admin">
                <ProgramRequests />
              </PrivateRoute>
            }
          />
          <Route
            path="programs/create"
            element={
              <PrivateRoute requiredRole="Admin">
                <CreateProgram />
              </PrivateRoute>
            }
          />
          <Route
            path="source-program"
            element={
              <PrivateRoute requiredRole="Admin">
                <AdminSourcePrograms />
              </PrivateRoute>
            }
          />
          <Route
            path="users"
            element={
              <PrivateRoute requiredRole="Admin">
                <AdminUsers />
              </PrivateRoute>
            }
          />
          <Route
            path="performance"
            element={
              <PrivateRoute requiredRole="Admin">
                <AdminPerformance />
              </PrivateRoute>
            }
          />
                    <Route
            path="monthly-summaries"
            element={
              <PrivateRoute requiredRole="Admin">
                <MonthlySummary />
              </PrivateRoute>
            }
          />
                                                            <Route
              path="payments"
              element={
                <PrivateRoute requiredRole="Admin">
                  <AdminPayments />
                </PrivateRoute>
              }
            />          

        </Route>

        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route
          path="/register"
          element={
            <PublicRoute>
              <Register />
            </PublicRoute>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <PublicRoute>
              <ForgotPassword />
            </PublicRoute>
          }
        />
        <Route
          path="/reset-password/:token"
          element={
            <PublicRoute>
              <ResetPassword />
            </PublicRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
    </UserResponsiveProvider>

  );
};

export default App;
