import React, { useState, useEffect } from 'react';
import adminService from '../../services/adminService'; // Service for fetching sources
import Select from 'react-select'; // Import react-select for dropdowns
import PaginationComponent from '../../components/PaginationComponent';
import styles from './Sources.module.scss'; // Custom styles
import placeholder from '../../images/placeholder.jpg';

const Sources = () => {
  const [sources, setSources] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); // Will be updated based on API response
  const [appliedFilters, setAppliedFilters] = useState({
    searchTerm: ''
  });


  // Fetch sources based on applied filters, page, and itemsPerPage
  const fetchSourcesData = async () => {
    try {
      const data = await adminService.fetchSources(currentPage, itemsPerPage, appliedFilters);
      setSources(data.sources || []);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error('Error fetching sources:', error);
    }
  };

  // Trigger fetchSourcesData when currentPage or itemsPerPage changes
  useEffect(() => {
    fetchSourcesData();
  }, [currentPage, itemsPerPage, appliedFilters]);



  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  // Apply filters when the user clicks the "Apply Filters" button
  const handleApplyFilters = () => {
    setAppliedFilters({
      searchTerm,
    });
    setCurrentPage(1); // Reset to the first page after applying filters
  };

  return (
    <div className={styles.sourcesContainer}>
      <h1>Sources</h1>

      {/* Search Input */}
      <div className={styles.searchContainer}>
        <input
          type="text"
          placeholder="Search sources..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button className={styles.applyFilters} onClick={handleApplyFilters}>
          Apply Filters
        </button>
      </div>

      {/* Sources Table */}
      <div className={styles.sourcesTableContainer}>
        <table className={styles.tableFullWidth}>
          <thead>
            <tr>
              <th>Logo</th>
              <th>Name</th>
              <th>URL</th>
              <th>Created At</th>
            </tr>
          </thead>
          <tbody>
            {sources.map((source) => (
              <tr key={source.id}>
                <td>
                  <img
                    src={source.logo ? `/uploads/sourceLogos/${source.logo}` : placeholder}
                    alt={source.name}
                    width="50"
                    height="50"

                  />
                </td>
                <td>{source.name}</td>
                <td>{source.url}</td>
                <td>{new Date(source.createdAt).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <PaginationComponent
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />
    </div>
  );
};

export default Sources;
