import React, { useContext } from 'react';
import styles from './HotProgramsChart.module.scss';
import placeholder from '../../images/placeholder.jpg';
import { Skeleton, Tooltip } from '@mui/material';
import { UserResponsiveContext } from '../../components/UserResponsiveContext';

const HotProgramsChart = ({ hotPrograms, loading }) => {
    // Hitta den högsta intäkten för att normalisera bredden
    const maxEarnings = Math.max(...hotPrograms.map((program) => program.earnings));
    const { screenSize } = useContext(UserResponsiveContext);
    const isSmallScreen = screenSize <= 560;

    return (
        <div className={styles.hotProgramsChart}>
            {loading ? (
                // Skeleton-komponenter visas medan data laddas
                Array.from({ length: 4 }).map((_, index) => (
                    <div key={index} className={styles.programRow}>
                        <Skeleton height={40} width="60%" />
                        <Skeleton height={40} width="20%" />
                        <Skeleton height={40} width="20%" />
                    </div>
                ))
            ) : hotPrograms.length > 0 ? (
                // Visa program om data finns
                hotPrograms.map((program, index) => (
                    <div key={index} className={styles.programRow}>
                        {/* Bild och namn */}
                        <div className={styles.programLabel}>
                            {isSmallScreen ? (
                                // Visa Tooltip vid små skärmar
                                <Tooltip title={program.programName} arrow placement='top'>
                                    <img
                                        src={
                                            program.brandImage
                                                ? `/uploads/brandLogos/${program.brandImage}`
                                                : placeholder
                                        }
                                        alt={`${program.programName} Logo`}
                                        className={styles.programImage}
                                    />
                                </Tooltip>
                            ) : (
                                <img
                                    src={
                                        program.brandImage
                                            ? `/uploads/brandLogos/${program.brandImage}`
                                            : placeholder
                                    }
                                    alt={`${program.programName} Logo`}
                                    className={styles.programImage}
                                />
                            )}
                            <span className={styles.programName}>{program.programName}</span>
                        </div>

                        {/* Stapel */}
                        <div className={styles.barContainer}>
                            <div
                                className={styles.bar}
                                style={{
                                    width: `${(program.earnings / maxEarnings) * 100 || 0}%`, // Hanterar division med 0
                                }}
                            />
                        </div>

                        {/* Värde */}
                        <div className={styles.programValue}>
                            {program.earnings ? `${program.earnings.toFixed(2)} €` : "0.00 €"}
                        </div>
                    </div>
                ))
            ) : (
                // Fallback om inga program finns
                <p className={styles.noPerformance}>No recent performance</p>
            )}
        </div>

    );
};

export default HotProgramsChart;
