import React, { useState, useEffect } from 'react';
import sourceService from '../../services/sourceService';
import { Form } from 'react-bootstrap';
import Modal from 'react-modal';
import styles from './Sources.module.scss';
import Select from 'react-select';
import placeholder from '../../images/placeholder.jpg';
import { Tooltip } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

Modal.setAppElement('#root');

const Sources = () => {
  useEffect(() => {
    document.title = 'Savvy - Sources';
  }, []);

  const [sources, setSources] = useState([]);
  const [selectedSource, setSelectedSource] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editingSource, setEditingSource] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // New state to track editing mode
  const [sourceForm, setSourceForm] = useState({
    url: '',
    sourceImage: null,
    imagePreview: '',
    name: '',
    regionIds: [],
  });
  const [validationError, setValidationError] = useState(''); // New state for validation errors
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSources();
  }, []);

  const handleSourceChange = (selectedOption) => {
    setSelectedSource(selectedOption);
    if (selectedOption && selectedOption.value) {
      fetchSource(selectedOption.value);
    } else {
      fetchSources();
    }
  };

  const fetchSources = async () => {
    try {
      setLoading(true);
      const response = await sourceService.getUserSources();
      setSources(response);
    } catch (error) {
      console.error('Error fetching sources:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchSource = async (sourceId) => {
    try {
      const response = await sourceService.getUserSource(sourceId);
      setSources([response]);
    } catch (error) {
      console.error('Error fetching sources:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSourceForm({ ...sourceForm, [name]: value });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setSourceForm({
        ...sourceForm,
        sourceImage: file,
        imagePreview: URL.createObjectURL(file),
      });
    } else {
      alert('Please upload a valid image file (jpg, jpeg, png).');
    }
  };

  const openModal = (source = null) => {
    if (source) {
      setEditingSource(source);
      setIsEditing(true); // We're editing
      setSourceForm({
        url: source.url,
        sourceImage: null,
        imagePreview: source.logo || '',
        name: source.name,
      });
    } else {
      setEditingSource(null);
      setIsEditing(false); // We're creating
      setSourceForm({
        url: '',
        sourceImage: null,
        imagePreview: '',
        name: '',
      });
    }
    setValidationError(''); // Reset validation error
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setEditingSource(null);
    setSourceForm({
      url: '',
      sourceImage: null,
      imagePreview: '',
      name: '',
    });
    setValidationError(''); // Reset validation error
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      if (!editingSource) {  // Only include name if creating a new source
        formData.append('name', sourceForm.name);
      }
      formData.append('url', sourceForm.url);
      if (sourceForm.sourceImage) {
        formData.append('sourceImage', sourceForm.sourceImage);
      }

      if (editingSource) {
        await sourceService.editSource(editingSource.id, formData);
      } else {
        await sourceService.createUserSources(formData);
      }
      fetchSources();
      closeModal();
    } catch (error) {
      console.error('Error saving source:', error);
      if (error.message) {
        console.log(error)
        setValidationError(
          error.message || 'An error occurred while saving the source.'
        );
      }
    }
  };

  return (
    <div className={styles.sourceContainer}>
      <div className={styles.header}>
        <h1>Source</h1>
        <div className={styles.options}>
          <div className={styles.selectSourceContainer}>
            <Select
              name="source"
              value={selectedSource}
              onChange={handleSourceChange}
              options={sources.map((source) => ({
                value: source.id,
                label: source.name,
              }))}
              classNamePrefix={loading ? "loading" : "customSelect"}
              disabled={loading}
              placeholder="Search source"
              isClearable
              className={styles.sourceSelect}
            />
          </div>
          <button onClick={() => openModal()} className={styles.addButton}>
            <span class="material-symbols-outlined">
              add
            </span>
            Create
          </button>
        </div>
      </div>

      <div className={styles.cardContainer}>
        {loading ? (
          <>
            <div className={styles.card}>
              <Skeleton variant="rectangular" width={150} height="100%" style={{ minWidth: "150px" }} />
              <div className={styles.sourceInfo}>
                <Skeleton variant="text" width="80%" height={40} />
                <Skeleton variant="text" width="100%" height={30} />
                <Skeleton variant="text" width="50%" height={30} />
                <Skeleton variant="text" width="50%" height={30} />
              </div>
            </div>
          </>
        ) : sources.length > 0 ? (
          sources.map((source) => (
            <div className={styles.card} key={source.id}>
              <img
                src={
                  source.logo ? `/uploads/sourceLogos/${source.logo}` : placeholder
                }
                alt="logo"
                className={styles.cardImg}

              />
              <div className={styles.sourceInfo}>
                <h4>{source.name}</h4>
                {source.url && (
                  <div className={styles.cardUrl}>
                    <Tooltip
                      title={<p style={{ margin: '0px' }}>{source.url}</p>}
                      arrow
                      disableInteractive
                    >
                      <strong>URL:</strong> {source.url}
                    </Tooltip>
                  </div>
                )}
                <div className={styles.cardStatus}>
                  <strong>Status:</strong>
                  <span className="material-symbols-outlined">
                    check_circle
                  </span>
                </div>
                <button
                  onClick={() => openModal(source)}
                  className={styles.editButton}
                >
                  <span className="material-symbols-outlined">edit</span>
                  Edit
                </button>
              </div>
            </div>
          ))
        ) : (
          // Om det inte finns några sources, visa knappen
          <button className={styles.noFound} onClick={() => openModal()}>
            No sources found, create your first!
          </button>
        )}

      </div>

      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        className={styles.modal}
        overlayClassName={styles.overlay}
        contentLabel={editingSource ? 'Edit Source' : 'Add New Source'}
      >
        <div className={styles.modalHeader}>
          <h3>{editingSource ? 'Edit Source' : 'Add New Source'}</h3>
          <button className={styles.modalClose} onClick={() => setShowModal(false)}>
            <span class="material-symbols-outlined">
              close
            </span>
          </button>
        </div>
        <div className={styles.modalContent}>

          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>
                <span className={styles.required}>*</span>Name
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={sourceForm.name}
                onChange={handleInputChange}
                required
                disabled={isEditing}
              />
            </Form.Group>
            <Form.Group controlId="formUrl">
              <Form.Label>URL</Form.Label>
              <Form.Control
                type="text"
                name="url"
                value={sourceForm.url}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group controlId="formImage">
              <Form.Label>Upload Image</Form.Label>
              <Form.Control
                type="file"
                name="sourceImage"
                accept="image/*"
                onChange={handleImageUpload}
              />
            </Form.Group>

            {validationError && (
              <div className={styles.validationError}>{validationError}</div>
            )}
          </Form>
        </div>
        <div className={styles.modalActions}>
          <button onClick={closeModal} className={styles.cancelButton}>
            Cancel
          </button>
          <button type="submit" className={styles.submitButton}>
            {editingSource ? 'Update Source' : 'Create Source'}
          </button>
        </div>
      </Modal>
    </div >
  );
};

export default Sources;
