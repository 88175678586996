import React, { useRef, useState, useEffect } from 'react';
import './FadeScrollContainer.scss';

// Hjälpfunktion: konverterar t.ex. "#fff" => "255,255,255"
function parseColorToRgb(color) {
    if (!color) throw new Error("No color provided");

    if (color.startsWith('var(')) {
        // Hantera CSS-variabel: hämta beräknat värde
        const rootStyles = getComputedStyle(document.documentElement);
        const rootColor = rootStyles.getPropertyValue(color.match(/var\((.*?)\)/)[1]).trim()
        if (!rootColor) throw new Error(`CSS variable ${color} could not be resolved.`);
        color = rootColor;
    }

    if (color.startsWith('rgb')) {
        const match = color.match(/rgb?\((\d+),\s*(\d+),\s*(\d+)/);
        if (match) return `${match[1]},${match[2]},${match[3]}`;
    }

    // Om färgen redan är i rgba-format
    if (color.startsWith('rgba')) {
        const match = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)/);
        if (match) return `${match[1]},${match[2]},${match[3]}`;
    }

    // Om färgen är i hex-format, konvertera till rgb
    if (color.startsWith('#')) {
        color = color.replace(/^#/, '');
        if (color.length === 3) {
            color = color.split('').map(x => x + x).join('');
        }
        const r = parseInt(color.substring(0, 2), 16);
        const g = parseInt(color.substring(2, 4), 16);
        const b = parseInt(color.substring(4, 6), 16);

        if (Number.isNaN(r) || Number.isNaN(g) || Number.isNaN(b)) {
            throw new Error("Invalid hex color: " + color);
        }
        return `${r},${g},${b}`;
    }

    throw new Error("Unsupported color format: " + color);
}

export default function FadeScrollContainer({
    children,
    width = '100%',
    height,
    maxWidth,
    maxHeight,
    direction = 'vertical',
    fadeRange = 40,
    fadeSize = 40,
    fadeColor = '#fff', // antas vara en hex i det här exemplet
    className = '',
    classNameInner = '',
    style = {}
}) {
    const scrollRef = useRef(null);

    const [topFade, setTopFade] = useState(0);
    const [bottomFade, setBottomFade] = useState(0);

    let fadeColorRgb = '255,255,255'; // fallback
    try {
        fadeColorRgb = parseColorToRgb(fadeColor);
    } catch (err) {
        console.warn("Could not parse fadeColor as hex: ", fadeColor);
    }

    // Bygg gradienter med flera stopp (icke-linjär fade).
    // Justera procent & alpha efter behov.
    const topGradient = `
    linear-gradient(
      to bottom,
      rgba(${fadeColorRgb}, 1) 0%,
      rgba(${fadeColorRgb}, 0.7) 20%,
      rgba(${fadeColorRgb}, 0.2) 60%,
      rgba(${fadeColorRgb}, 0) 100%
    )
  `;
    const bottomGradient = `
    linear-gradient(
      to top,
      rgba(${fadeColorRgb}, 1) 0%,
      rgba(${fadeColorRgb}, 0.7) 20%,
      rgba(${fadeColorRgb}, 0.2) 60%,
      rgba(${fadeColorRgb}, 0) 100%
    )
  `;
    const leftGradient = `
    linear-gradient(
      to right,
      rgba(${fadeColorRgb}, 1) 0%,
      rgba(${fadeColorRgb}, 0.7) 20%,
      rgba(${fadeColorRgb}, 0.2) 60%,
      rgba(${fadeColorRgb}, 0) 100%
    )
  `;
    const rightGradient = `
    linear-gradient(
      to left,
      rgba(${fadeColorRgb}, 1) 0%,
      rgba(${fadeColorRgb}, 0.7) 20%,
      rgba(${fadeColorRgb}, 0.2) 60%,
      rgba(${fadeColorRgb}, 0) 100%
    )
  `;

    useEffect(() => {
        const el = scrollRef.current;
        if (!el) return;

        function handleScroll() {
            if (direction === 'vertical') {
                const { scrollTop, scrollHeight, clientHeight } = el;
                let newTop = scrollTop / fadeRange;
                newTop = Math.min(Math.max(newTop, 0), 1);

                const distanceFromBottom = scrollHeight - clientHeight - scrollTop;
                let newBottom = distanceFromBottom / fadeRange;
                newBottom = Math.min(Math.max(newBottom, 0), 1);

                setTopFade(newTop);
                setBottomFade(newBottom);
            } else {
                const { scrollLeft, scrollWidth, clientWidth } = el;
                let newLeft = scrollLeft / fadeRange;
                newLeft = Math.min(Math.max(newLeft, 0), 1);

                const distanceFromRight = scrollWidth - clientWidth - scrollLeft;
                let newRight = distanceFromRight / fadeRange;
                newRight = Math.min(Math.max(newRight, 0), 1);

                setTopFade(newLeft);
                setBottomFade(newRight);
            }
        }

        el.addEventListener('scroll', handleScroll);
        handleScroll(); // init

        return () => {
            el.removeEventListener('scroll', handleScroll);
        };
    }, [direction, fadeRange]);

    const containerStyles = {
        position: 'relative',
        width,
        ...(height && { height }),
        ...(maxWidth && { maxWidth }),
        ...(maxHeight && { maxHeight }),
        ...style
    };

    const scrollStyles = {
        overflowY: direction === 'vertical' ? 'auto' : 'hidden',
        overflowX: direction === 'horizontal' ? 'auto' : 'hidden'
    };

    const overlayTopBottomStyle = {
        height: fadeSize
    };
    const overlayLeftRightStyle = {
        width: fadeSize
    };

    return (
        <div className={`fade-scroll-container ${className}`} style={containerStyles}>
            <div
                ref={scrollRef}
                className={`fade-scroll-inner ${classNameInner}`}
                style={scrollStyles}
            >
                {children}
            </div>

            {direction === 'vertical' && (
                <>
                    <div
                        className="fade-overlay fade-overlay--top"
                        style={{
                            ...overlayTopBottomStyle,
                            opacity: topFade, // 0–1
                            background: topGradient
                        }}
                    />
                    <div
                        className="fade-overlay fade-overlay--bottom"
                        style={{
                            ...overlayTopBottomStyle,
                            opacity: bottomFade, // 0–1
                            background: bottomGradient
                        }}
                    />
                </>
            )}

            {direction === 'horizontal' && (
                <>
                    <div
                        className="fade-overlay fade-overlay--left"
                        style={{
                            ...overlayLeftRightStyle,
                            opacity: topFade,
                            background: leftGradient
                        }}
                    />
                    <div
                        className="fade-overlay fade-overlay--right"
                        style={{
                            ...overlayLeftRightStyle,
                            opacity: bottomFade,
                            background: rightGradient
                        }}
                    />
                </>
            )}
        </div>
    );
}
