import React, { useState, useEffect } from 'react';
import adminService from '../../services/adminService';
import { Table } from 'react-bootstrap';
import PaginationComponent from '../../components/PaginationComponent';
import styles from './Users.module.scss';
import Select from 'react-select';
import Tooltip from '@mui/material/Tooltip';
import ManualNotificationModal from './components/ManualNotificationModal';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [role, setRole] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isGlobalNotification, setIsGlobalNotification] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, [currentPage, itemsPerPage, role]);

  const fetchUsers = async () => {
    try {
      const filters = { role: role ? role.value : '' };
      const data = await adminService.fetchUsersWithPagination(currentPage, itemsPerPage, filters);
      setUsers(data.users);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleSubmitNotification = async (title, message, userId, isGlobal) => {
    try {
      const data = await adminService.setManualNotification(title, message, userId, isGlobal);
      alert('Notification successfully created');
    } catch (error) {
      console.error('Error setting notification:', error);
      alert('Notification failed');
    }
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRoleChange = (selectedRole) => {
    setRole(selectedRole);
  };

  const renderSourcesTooltip = (sources) => (
    <Tooltip
      title={
        <div style={{ fontSize: '16px' }}>
          {sources.map((source, index) => (
            <div key={index}>{source}</div>
          ))}
        </div>
      }
      arrow
    >
      <span>{sources.length} sources</span>
    </Tooltip>
  );

  const roleOptions = [
    { value: '', label: 'All' },
    { value: 'Admin', label: 'Admin' },
    { value: 'User', label: 'User' },
  ];

  const formatLabel = (key) => {
    return key
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Add spaces between lowercase and uppercase letters
      .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
  };

  return (
    <div className={styles.usersContainer}>
      <h1>Users</h1>

      <div className={styles.filterContainer}>
        <div>
          <label>Role Filter:</label>
          <Select
            value={role}
            onChange={handleRoleChange}
            options={roleOptions}
            isClearable
            placeholder="Select role"
            className={styles.roleSelect}
          />
        </div>
        <button
          onClick={() => {
            setNotificationModalOpen(true);
            setSelectedUser(null); // Ingen enskild användare
            setIsGlobalNotification(true); // Flagga för global notifikation
          }}
          className={styles.notificationAllBtn}
        >
          Send Notification to All
        </button>
      </div>

      <div className={styles.usersTableContainer}>
        <table striped bordered hover className={styles.tableFullWidth}>
          <thead>
            <tr>
              <th>Email</th>
              <th>Role</th>
              <th>Sources</th>
              <th>Mail Signup</th>
              <th>Corp</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index}>
                <td>{user.email}</td>
                <td>{user.role}</td>
                <td>
                  {user.sources.length > 1 ? (
                    renderSourcesTooltip(user.sources)
                  ) : (
                    user.sources[0] || 'No Sources'
                  )}
                </td>
                <td>
                  {user.mailSignup && (
                    <Tooltip
                      arrow
                      title={
                        <ul className={styles.tooltipList}>
                          <p>Subscribed to</p>
                          {Object.entries(user.mailSignup)
                            .filter(([key, value]) => value === true && key !== 'userMail') // Exclude userMail and show only true fields
                            .map(([key]) => (
                              <li key={key}>{formatLabel(key)}</li>
                            ))}
                        </ul>
                      }
                    >
                      <p className={styles.hoverable}>{user.mailSignup.userMail}</p>
                    </Tooltip>
                  )}
                </td>
                <td>
                  {user.paymentDetails && (
                    <Tooltip
                      arrow
                      title={
                        <ul className={styles.tooltipList}>
                          <p>Payment Details</p>
                          {Object.entries(user.paymentDetails)
                            .filter(([key]) => key !== 'regionId')
                            .map(([key, value]) => (
                              <li key={key}>{formatLabel(key)} - {value}</li>
                            ))}
                        </ul>
                      }
                    >
                      <p className={styles.hoverable}>{user.paymentDetails.company}</p>
                    </Tooltip>
                  )}
                </td>
                <td>
                  <button
                    className={styles.notificationBtn}
                    onClick={() => {
                      setNotificationModalOpen(true);
                      setSelectedUser(user);
                      setIsGlobalNotification(false); // Ingen global notifikation
                    }}
                  >
                    Send Notification
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {notificationModalOpen && (
        <ManualNotificationModal
          isOpen={notificationModalOpen}
          onRequestClose={() => setNotificationModalOpen(false)}
          onSubmit={(title, message) =>
            handleSubmitNotification(
              title,
              message,
              selectedUser ? selectedUser.id : null,
              isGlobalNotification // Skicka global flagga
            )
          }
          user={selectedUser}
          isGlobal={isGlobalNotification}
        />
      )}
      <PaginationComponent
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />
    </div>
  );
};

export default Users;
