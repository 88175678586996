// src/components/ProgramsPage.jsx
import React, { useState, useEffect } from 'react';
import programService from '../../services/programService';
import sourceService from '../../services/sourceService';
import Select from 'react-select';
import styles from './Programs.module.scss';
import Modal from 'react-modal';
import placeholder from '../../images/placeholder.jpg';
import Flag from 'react-world-flags'; // Importera flaggbibliotek
import Tooltip from '@mui/material/Tooltip';
import Skeleton from '@mui/material/Skeleton';

Modal.setAppElement('#root');

const LoadingSkeleton = () => (
  <div className={`${styles.programCard} ${styles.loadingCard}`}>
    <Skeleton variant="rectangular" width={260} height="100%" style={{ minWidth: "260px" }} />
    <div className={styles.regionsContainer}>
      {[...Array(4)].map((_, index) => (
        <Skeleton key={index} variant="text" width="100%" height={40} />
      ))}
    </div>
  </div>
);

const ProgramsPage = () => {
  useEffect(() => {
    document.title = 'Savvy - Programs';
  }, []);

  const [loading, setLoading] = useState(true);
  const [sourcesLoading, setSourcesLoading] = useState(true);
  const [filterLoading, setFilterLoading] = useState(true);

  // ================================
  // FILTER: State-hantering
  // ================================
  const [appliedFilters, setAppliedFilters] = useState({
    source: null,
    brand: null,
    regions: [],
    verticals: [],
  });

  // 2. Temporära filter som används i modalen
  const [tempFilters, setTempFilters] = useState({
    source: null,
    brand: null,
    regions: [],
    verticals: [],
  });

  // ================================
  // Övrig state
  // ================================
  const [regions, setRegions] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [sources, setSources] = useState([]);
  const [brands, setBrands] = useState([]);
  const [verticals, setVerticals] = useState([]);

  // Modal-state
  const [showModal, setShowModal] = useState(false);         // Join-program-modal
  const [showFilterModal, setFilterShowModal] = useState(false); // Filter-modal

  // Program-join-state
  const [programToJoin, setProgramToJoin] = useState(null);

  // Kopiera-länk-state
  const [copyStatus, setCopyStatus] = useState(null);

  // ================================
  // Hämta initial data
  // ================================
  useEffect(() => {
    fetchSources();
    fetchRegions();
    fetchBrands();
    fetchVerticals();
  }, []);

  useEffect(() => {
    if (appliedFilters.source) {
      fetchPrograms();
    } else {
      setPrograms([]);
    }
  }, [appliedFilters]);

  // ================================
  // API-anrop
  // ================================
  const fetchSources = async () => {
    try {
      setSourcesLoading(true);
      const response = await sourceService.getUserSources();
      setSources(response);
      setAppliedFilters((prev) => ({
        ...prev,
        source: response[0]
          ? { value: response[0].id, label: response[0].name }
          : null
      }));
      setTempFilters((prev) => ({
        ...prev,
        source: response[0]
          ? { value: response[0].id, label: response[0].name }
          : null
      }));
    } catch (error) {
      console.error('Error fetching sources:', error);
    } finally {
      setSourcesLoading(false);
    }
  };

  const fetchBrands = async () => {
    try {
      setFilterLoading(true); // Startar laddningsindikatorn
      const response = await programService.fetchAllBrands();
      setBrands(response.map((brand) => ({ value: brand.id, label: brand.name })));
    } catch (error) {
      console.error('Error fetching brands:', error);
    } finally {
      setFilterLoading(false); // Stänger av laddningsindikatorn
    }
  };

  const fetchRegions = async () => {
    try {
      setFilterLoading(true);
      const regionsData = await programService.fetchAllRegions();
      setRegions(regionsData.map(region => ({
        value: region.id,
        label: region.name,
        shortCode: region.shortCode
      })));
    } catch (error) {
      console.error('Error fetching regions:', error);
    } finally {
      setFilterLoading(false);
    }
  };

  const fetchVerticals = async () => {
    try {
      setFilterLoading(true);
      const response = await sourceService.fetchVerticals();
      setVerticals(response.map((vertical) => ({ value: vertical.id, label: vertical.name })));
    } catch (error) {
      console.error('Error fetching verticals:', error);
    } finally {
      setFilterLoading(false);
    }
  };


  const fetchPrograms = async () => {
    try {
      setLoading(true);
      const filters = {
        brandId: appliedFilters.brand?.value || null,
        verticalIds: appliedFilters.verticals.map((v) => v.value),
        regionIds: appliedFilters.regions.map((r) => r.value),
      };

      const sourceId = appliedFilters.source?.value || null;
      if (!sourceId) {
        setPrograms([]);
        return;
      }

      const response = await programService.getSourcePrograms(sourceId, filters);
      setPrograms(response);
    } catch (error) {
      console.error('Error fetching programs:', error);
    } finally {
      setLoading(false);
    }
  };

  // ================================
  // Filter-hantering i modalen
  // ================================
  const handleTempFilterChange = (key, value) => {
    setTempFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const applyFilters = () => {
    setAppliedFilters(tempFilters);
    setFilterShowModal(false);
  };

  const cancelFilters = () => {
    setTempFilters(appliedFilters);
    setFilterShowModal(false);
  };

  const clearFilters = () => {
    setTempFilters({
      source: appliedFilters.source,
      brand: null,
      regions: [],
      verticals: [],
    });
  };

  const countActiveFilters = () => {
    let count = 0;
    if (appliedFilters.brand) count++;
    if (appliedFilters.regions.length > 0) count++;
    if (appliedFilters.verticals.length > 0) count++;
    return count;
  };

  // ================================
  // Program: Join
  // ================================
  const joinProgramModal = (program, region) => {
    setProgramToJoin({ program, region });
    setShowModal(true);
  };

  const joinProgram = async (sourceId, programRegionId) => {
    try {
      await programService.joinProgram(sourceId, programRegionId);
      // Uppdatera program-listan
      fetchPrograms();
      setShowModal(false);
    } catch (error) {
      console.error('Error joining program:', error);
    }
  };

  // ================================
  // Kopiera-länk-hantering
  // ================================
  const copyToClipBoard = (text, id) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopyStatus(id);
        setTimeout(() => setCopyStatus(null), 5000);
      })
      .catch((error) => {
        console.error('Failed to copy text: ', error);
      });
  };

  // ================================
  // Information-ikoner
  // ================================
  const getIconName = (type) => {
    switch (type) {
      case 'general':
        return 'info';
      case 'delay':
        return 'schedule';
      case 'warning':
        return 'warning';
      default:
        return 'help';
    }
  };

  const getClassName = (type) => {
    switch (type) {
      case 'general':
        return styles.general;
      case 'delay':
        return styles.delay;
      case 'warning':
        return styles.warning;
      default:
        return styles.default;
    }
  };

  // ================================
  // Render
  // ================================
  return (
    <div className={styles.programContainer}>

      {/* Header */}
      <div className={styles.header}>
        <h1>Programs</h1>
        <div className={styles.options}>
          <button className={styles.filterButton} onClick={() => setFilterShowModal(true)}>
            <span className="material-symbols-outlined">filter_alt</span>
            Filter
            {/* Visa hur många av fyra som är aktiva */}
            <span className={styles.filterCount}>{countActiveFilters()}/3</span>
          </button>
          <div className={styles.selectSourceContainer}>
            <Select
              name="source"
              value={tempFilters.source}
              onChange={(val) => handleTempFilterChange('source', val)}
              options={sources.map((source) => ({
                value: source.id,
                label: source.name,
              }))}
              classNamePrefix={sourcesLoading ? "loading" : "customSelect"}
              disabled={filterLoading}
              placeholder="Source..."
              className={styles.sourceSelect}
            />
          </div>
        </div>
      </div>

      {/* Program Cards */}
      <div className={styles.cardsContainer}>
        {sourcesLoading ? ( // Kolla om sources fortfarande laddas
          <>
            <LoadingSkeleton />
            <LoadingSkeleton />
            <LoadingSkeleton />
          </>
        ) : sources.length === 0 ? ( // Kolla om sources är tom
          <a className={styles.noSource} href="../sources">
            Add a source to see program deals
          </a>
        ) : loading ? ( // Om sources finns men programs fortfarande laddas
          <>
            <LoadingSkeleton />
            <LoadingSkeleton />
            <LoadingSkeleton />
          </>
        ) : (
          programs.map((program) => (
            <div key={program.id} className={styles.programCard}>
              {/* Programhuvud */}
              <div
                className={`${styles.programDetails} ${program.downTime.length > 0 && styles.inactiveProgram
                  }`}
              >
                <img
                  src={
                    program.brand.image
                      ? `/uploads/brandLogos/${program.brand.image}`
                      : placeholder
                  }
                  alt={program.name}
                  className={styles.programImage}
                />
                <div className={styles.programInfo}>
                  <h2>{program.name}</h2>
                  <p>{program.verticals.map((v) => v.name).join(" + ")}</p>
                  {(program.programInformation?.length > 0 || program.downTime.length > 0) && (
                    <div className={styles.programInformations}>
                      {program.downTime.length > 0 ? (
                        <Tooltip
                          title={
                            <p style={{ margin: "0px" }}>
                              {"Downtime start: " + program.downTime[0].startDate}
                            </p>
                          }
                          arrow
                        >
                          <p className={styles.inactive}>Inactive</p>
                        </Tooltip>
                      ) : (
                        program.programInformation.map((info) => (
                          <Tooltip
                            key={info.id}
                            title={<p style={{ margin: "0px" }}>{info.message}</p>}
                            arrow
                          >
                            <div
                              className={`${styles.informationItem} ${getClassName(info.type)}`}
                            >
                              <span className="material-symbols-outlined">
                                {getIconName(info.type)}
                              </span>
                            </div>
                          </Tooltip>
                        ))
                      )}
                    </div>
                  )}
                </div>

              </div>

              {/* Regions */}
              <div className={styles.regionsContainer}>
                <div className={`${styles.regionRow} ${styles.headerRow}`}>
                  <div className={styles.regionCell}></div>
                  <div className={styles.regionCell}>DESCRIPTION</div>
                  <div className={styles.regionCell}>BASELINE</div>
                  <div className={styles.regionCell}>CPA</div>
                  <div className={styles.regionCell}>REVSHARE</div>
                  <div className={styles.regionCell}></div>
                </div>
                <div className={styles.regions}>
                  {program.regions.map((region) => {
                    const {
                      id: regionId,
                      regionName,
                      regionShortCode,
                      description,
                      deal,
                      upcomingDeal,
                      affiliateLink,
                      requestStatus,
                    } = region;

                    const effectiveDeal = deal;

                    return (
                      <div
                        key={regionId}
                        className={`${styles.regionRow} ${region.downTime?.length !== 0 &&
                          program.downTime?.length === 0 &&
                          styles.inactiveRow
                          }`}
                      >
                        <div className={styles.regionsInfoPhone}>
                          {region.regionInformation?.map((info) => (
                            <Tooltip
                              key={info.id}
                              title={<p style={{ margin: "0px" }}>{info.message}</p>}
                              arrow
                            >
                              <div
                                className={`${styles.informationItem} ${getClassName(
                                  info.type
                                )}`}
                              >
                                <span className="material-symbols-outlined">
                                  {getIconName(info.type)}
                                </span>
                              </div>
                            </Tooltip>
                          ))}
                        </div>
                        <div className={styles.regionCell}>
                          <Tooltip
                            title={<p style={{ margin: "0px" }}>{regionName}</p>}
                            arrow
                            disableInteractive
                          >
                            <span style={{ display: "flex", alignItems: "center" }}>
                              <Flag
                                code={regionShortCode.toLowerCase()}
                                style={{ width: "30px", height: "20px" }}
                                alt={`${regionName} flag`}
                                title={regionName}
                              />
                            </span>
                          </Tooltip>
                        </div>
                        <div className={styles.regionCell}>{description}</div>
                        <div className={styles.regionCell}>
                          <div className={styles.regionCellContent}>

                            <p>Baseline</p>
                            {upcomingDeal && upcomingDeal.baseline !== effectiveDeal.baseline && (
                              <Tooltip
                                title={
                                  <p style={{ margin: "0px", textAlign: "center" }}>
                                    {`Baseline will be changed to ${upcomingDeal.baseline} €, on the ${upcomingDeal.validFrom.split("T")[0]}`}
                                  </p>
                                }
                                arrow
                                disableInteractive
                              >
                                {upcomingDeal.baseline > effectiveDeal.baseline ? (
                                  <div className={styles.priceUp}>
                                    <span className="material-symbols-outlined">clock_arrow_up</span>
                                  </div>
                                ) : (
                                  <div className={styles.priceDown}>
                                    <span className="material-symbols-outlined">clock_arrow_down</span>
                                  </div>
                                )}
                              </Tooltip>
                            )}
                            {effectiveDeal && effectiveDeal.baseline > 0 ? `${effectiveDeal.baseline} €` : "-"}
                          </div>
                        </div>
                        <div className={styles.regionCell}>
                          <div className={styles.regionCellContent}>
                            <p>CPA</p>
                            {upcomingDeal && upcomingDeal.CPA !== effectiveDeal.CPA &&
                              <Tooltip
                                title={<p style={{ margin: "0px", textAlign: "center" }}>
                                  {`CPA will be changed to ${upcomingDeal.CPA} €, on the ${upcomingDeal.validFrom.split("T")[0]}`}
                                </p>}
                                arrow
                                disableInteractive
                              >
                                {upcomingDeal.CPA > effectiveDeal.CPA ?
                                  <div className={styles.priceUp}>
                                    <span class="material-symbols-outlined">
                                      clock_arrow_up
                                    </span>
                                  </div>
                                  :
                                  <div className={styles.priceDown}>
                                    <span class="material-symbols-outlined">
                                      clock_arrow_down
                                    </span>
                                  </div>
                                }
                              </Tooltip>
                            }
                            {effectiveDeal && effectiveDeal.CPA > 0
                              ? `${effectiveDeal.CPA} €`
                              : "-"}
                          </div>
                        </div>
                        <div className={styles.regionCell}>
                          <div className={styles.regionCellContent}>

                            <p>Revshare</p>
                            {upcomingDeal && upcomingDeal.revshare !== effectiveDeal.revshare && (
                              <Tooltip
                                title={
                                  <p style={{ margin: "0px", textAlign: "center" }}>
                                    {`Revshare will be changed to ${(upcomingDeal.revshare * 100).toFixed(2)}%, on the ${upcomingDeal.validFrom.split("T")[0]}`}
                                  </p>
                                }
                                arrow
                                disableInteractive
                              >
                                {upcomingDeal.revshare > effectiveDeal.revshare ? (
                                  <div className={styles.priceUp}>
                                    <span className="material-symbols-outlined">clock_arrow_up</span>
                                  </div>
                                ) : (
                                  <div className={styles.priceDown}>
                                    <span className="material-symbols-outlined">clock_arrow_down</span>
                                  </div>
                                )}
                              </Tooltip>
                            )}
                            {effectiveDeal && effectiveDeal.revshare
                              ? `${(effectiveDeal.revshare * 100).toFixed(2)}%`
                              : "-"}
                          </div>
                        </div>
                        <div className={styles.regionCell}>
                          {program.downTime?.length ? null : region.downTime?.length ? (
                            <Tooltip
                              title={
                                <p style={{ margin: "0px" }}>
                                  {"Downtime start: " + region.downTime[0].startDate}
                                </p>
                              }
                              arrow
                            >
                              <span className={styles.inactiveRegion}>Inactive</span>
                            </Tooltip>
                          ) : requestStatus ? (
                            requestStatus === "Confirmed" ? (
                              <button
                                onClick={() =>
                                  copyToClipBoard(
                                    affiliateLink,
                                    regionId
                                  )
                                }
                                className={styles.copyButton}
                              >
                                {copyStatus === regionId ? (
                                  "Copied!"
                                ) : (
                                  <>
                                    <span className="material-symbols-outlined">
                                      content_cut
                                    </span>{" "}
                                    Copy
                                  </>
                                )}
                              </button>
                            ) : (
                              <button className={styles.pendingButton} disabled>
                                Pending
                              </button>
                            )
                          ) : (
                            <button
                              onClick={() => joinProgramModal(program, region)}
                              className={styles.joinButton}
                            >
                              <span className="material-symbols-outlined">login</span>{" "}
                              Join
                            </button>
                          )}
                          {region.regionInformation?.length > 0 &&
                            <div className={styles.regionInfo}>
                              {region.regionInformation?.map((info) => (
                                <Tooltip
                                  key={info.id}
                                  title={<p style={{ margin: "0px" }}>{info.message}</p>}
                                  arrow
                                >
                                  <div
                                    className={`${styles.informationItem} ${getClassName(
                                      info.type
                                    )}`}
                                  >
                                    <span className="material-symbols-outlined">
                                      {getIconName(info.type)}
                                    </span>
                                  </div>
                                </Tooltip>
                              ))}
                            </div>
                          }
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ))
        )}
      </div>


      {/* ======================================== */}
      {/* Modal: Join program */}
      {/* ======================================== */}
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        className={styles.joinModal}
        overlayClassName={styles.overlay}
      >
        <div className={styles.modalHeader}>
          <h3>Request to join program</h3>
          <button onClick={() => setShowModal(false)} className={styles.modalClose}>
            <span className="material-symbols-outlined">close</span>
          </button>
        </div>
        <div className={styles.modalContent}>
          <div className={styles.programSource}>
            {appliedFilters.source && (
              <>
                <img
                  src={
                    sources.find((s) => s.id === appliedFilters.source.value)?.logo
                      ? `/uploads/sourceLogos/${sources.find((s) => s.id === appliedFilters.source.value)?.logo
                      }`
                      : placeholder
                  }

                  alt="Source"
                />
                <p>{appliedFilters.source.label}</p>
              </>
            )}
          </div>
          <div className={styles.programSource}>
            {programToJoin && (
              <>
                <img
                  src={
                    programToJoin.program.brand.image
                      ? `/uploads/brandLogos/${programToJoin.program.brand.image}`
                      : placeholder
                  }
                  alt="Program"

                />
                <div className={styles.modalProgInfo}>
                  <p>{programToJoin.program.name}</p>
                  <p>{programToJoin.region.regionName}</p>
                </div>
              </>
            )}
          </div>
          {programToJoin && programToJoin.region.deal && (
            <div className={styles.dealContainer}>
              <p className={styles.standardDealHeader}>Deal Information</p>
              <div className={styles.deals}>
                <div>
                  <p>
                    <strong>CPA:</strong>
                    {programToJoin.region.deal.CPA > 0
                      ? ` ${programToJoin.region.deal.CPA} €`
                      : ' -'}
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Revenue Share:</strong>
                    {programToJoin.region.deal.revshare > 0
                      ? ` ${(programToJoin.region.deal.revshare * 100).toFixed(2)} %`
                      : ' -'}
                  </p>
                </div>
                <div>
                  <p>
                    <strong>Baseline:</strong>
                    {programToJoin.region.deal.baseline
                      ? ` ${programToJoin.region.deal.baseline} €`
                      : ' -'}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={styles.modalActions}>
          <button onClick={() => setShowModal(false)} className={styles.cancelButton}>
            Cancel
          </button>
          <button
            onClick={() =>
              joinProgram(
                appliedFilters.source?.value,
                programToJoin.region.id
              )
            }
            className={styles.applyButton}
          >
            Join
          </button>
        </div>
      </Modal>

      {/* ======================================== */}
      {/* Modal: Filter */}
      {/* ======================================== */}
      <Modal
        isOpen={showFilterModal}
        onRequestClose={cancelFilters}
        className={styles.filterModal}
        overlayClassName={styles.overlay}
      >
        <div className={styles.modalHeader}>
          <h3>Filter</h3>
          <button onClick={cancelFilters} className={styles.modalClose}>
            <span className="material-symbols-outlined">close</span>
          </button>
        </div>
        <div className={styles.modalContent}>
          <div className={styles.filterForm}>
            <div>
              <label>Brand</label>
              <Select
                name="brandId"
                value={tempFilters.brand}
                onChange={(val) => handleTempFilterChange('brand', val)}
                options={brands}
                isClearable
                placeholder="Select brand"
                classNamePrefix={filterLoading ? "loading" : "customSelect"}
                disabled={filterLoading}
              />
            </div>
            <div>
              <label>Region(s)</label>
              <Select
                isMulti
                name="regionIds"
                value={tempFilters.regions}
                onChange={(val) => handleTempFilterChange('regions', val)}
                options={regions.map((region) => ({
                  value: region.id,
                  label: region.name,
                  shortCode: region.shortCode,
                }))}
                placeholder="Select regions"
                classNamePrefix={filterLoading ? "loading" : "customSelect"}
                disabled={filterLoading}
                isClearable
                formatOptionLabel={({ label, shortCode }, { context }) => {
                  if (context === 'menu') {
                    return (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Flag
                          code={shortCode.toLowerCase()}
                          style={{ width: '20px', height: '15px', marginRight: '8px' }}
                          alt={`${label} flag`}
                        />
                        <span>{label}</span>
                      </div>
                    );
                  } else if (context === 'value') {
                    return (
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        <Flag
                          code={shortCode.toLowerCase()}
                          style={{ width: '15px', height: '10px', marginRight: '5px' }}
                          alt={`${label} flag`}
                        />
                        <span>{label}</span>
                      </span>
                    );
                  }
                  return label;
                }}
              />
            </div>
            <div>
              <label>Vertical(s)</label>
              <Select
                name="verticalIds"
                value={tempFilters.verticals}
                onChange={(val) => handleTempFilterChange('verticals', val)}
                options={verticals}
                isMulti
                placeholder="Select verticals"
                classNamePrefix={filterLoading ? "loading" : "customSelect"}
                disabled={filterLoading}
              />
            </div>
          </div>
        </div>
        <div className={styles.modalActions}>
          <button onClick={clearFilters} className={styles.clearButton}>
            Clear
          </button>
          <div className={styles.rightSideButtons}>
            <button onClick={cancelFilters} className={styles.cancelButton}>
              Cancel
            </button>
            <button onClick={applyFilters} className={styles.applyButton}>
              Apply
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ProgramsPage;
