import React, { useState, useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import styles from './EarningsChart.module.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  ChartDataLabels
);

const EarningsChart = ({
  earningsData,
  chartPeriod,
  onPeriodChange,
  sources,
  programs,
  onSourceChange,
  onProgramChange,
  selectedSource,
  selectedProgram,
}) => {
  // State variable to keep track of the selected metric (earnings or QFTD)
  const [selectedMetric, setSelectedMetric] = useState('earnings'); // Default is 'earnings'

  const generateDateRange = (period) => {
    const dates = [];
    const today = new Date();
    const startDate = new Date();

    if (period === 'last7days') {
      startDate.setDate(today.getDate() - 6);
    } else if (period === 'lastMonth') {
      startDate.setMonth(today.getMonth() - 1);
    }

    for (let d = new Date(startDate); d <= today; d.setDate(d.getDate() + 1)) {
      dates.push(
        new Date(d).toLocaleDateString(undefined, {
          month: 'short',
          day: 'numeric',
        })
      );
    }
    return dates;
  };

  const allDates = useMemo(() => generateDateRange(chartPeriod), [chartPeriod]);

  const { chartData, detailsMap } = useMemo(() => {
    const dataMap = {};
    const detailsMap = {};

    earningsData.forEach((item) => {
      const date = new Date(item.date).toLocaleDateString(undefined, {
        month: 'short',
        day: 'numeric',
      });

      let totalValue;
      if (selectedMetric === 'earnings') {
        totalValue =
          parseFloat(item.totalCPAIncomeBeforeCommission || 0) +
          parseFloat(item.totalRevShareIncomeBeforeCommission || 0);
      } else if (selectedMetric === 'QFTD') {
        totalValue = parseInt(item.totalQFTD || 0, 10);
      } else if (selectedMetric === 'profits') {
        totalValue =
          (parseFloat(item.totalCPAIncomeBeforeCommission || 0) +
            parseFloat(item.totalRevShareIncomeBeforeCommission || 0)) -
          (parseFloat(item.totalCPAIncome || 0) +
            parseFloat(item.totalRevShareIncome || 0));
      }

      if (!dataMap[date]) {
        dataMap[date] = totalValue;
        detailsMap[date] = [];
      } else {
        dataMap[date] += totalValue;
      }

      item.details.forEach((detail) => {
        let detailValue;
        if (selectedMetric === 'earnings') {
          detailValue = parseFloat(detail.totalIncomeBeforeCommission || 0);
        } else if (selectedMetric === 'QFTD') {
          detailValue = parseInt(detail.totalQFTD || 0, 10);
        } else if (selectedMetric === 'profits') {
          detailValue =
            (parseFloat(detail.totalCPAIncomeBeforeCommission || 0) +
              parseFloat(detail.totalRevShareIncomeBeforeCommission || 0)) -
            (parseFloat(detail.totalCPAIncome || 0) +
              parseFloat(detail.totalRevShareIncome || 0));
        }

        detailsMap[date].push({
          sourceName: detail.sourceName,
          programName: detail.programName,
          value: detailValue,
        });
      });
    });

    const dates = allDates;
    const values = dates.map((date) => dataMap[date] || 0);

    const lineColor =
      selectedMetric === 'earnings'
        ? '#FCA311'
        : selectedMetric === 'profits'
          ? '#2A9D8F'
          : '#14213D';

    const backgroundColor =
      selectedMetric === 'earnings'
        ? 'rgba(252, 163, 17, 0.2)'
        : selectedMetric === 'profits'
          ? 'rgba(42, 157, 143, 0.2)'
          : 'rgba(20, 33, 61, 0.2)';

    return {
      chartData: {
        labels: dates,
        datasets: [
          {
            label:
              selectedMetric === 'earnings'
                ? 'Revenue'
                : selectedMetric === 'profits'
                  ? 'Profits'
                  : 'QFTD',
            data: values,
            fill: false,
            backgroundColor: backgroundColor,
            borderColor: lineColor,
            pointRadius: 5,
            datalabels: {
              align: 'end',
              anchor: 'end',
              formatter: (value) =>
                selectedMetric === 'earnings' || selectedMetric === 'profits'
                  ? `€${value.toFixed(2)}`
                  : value,
              font: { size: 10 },
              color: lineColor,
            },
            cubicInterpolationMode: 'monotone',
          },
        ],
      },
      detailsMap,
    };
  }, [
    earningsData,
    selectedSource,
    selectedProgram,
    chartPeriod,
    allDates,
    selectedMetric,
  ]);

  // Memoize chartOptions
  const chartOptions = useMemo(() => {
    const values = chartData.datasets[0].data;
    const maxYValue = Math.max(...values);

    // Adjust suggestedMax based on the selected metric
    let suggestedMax;
    if (selectedMetric === 'earnings' || selectedMetric === 'profits') {
      suggestedMax =
        maxYValue === 0
          ? 100
          : Math.ceil((maxYValue * 1.15) / 50) * 50;
    } else if (selectedMetric === 'QFTD') {
      suggestedMax =
        maxYValue === 0
          ? 10
          : maxYValue < 10
            ? 10
            : Math.ceil(maxYValue * 1.15);
    }

    // Set Y-axis tick format
    const yAxisTickCallback = (value) =>
      selectedMetric === 'earnings' || selectedMetric === 'profits'
        ? `€${value.toFixed(0)}`
        : value;

    return {
      responsive: true,
      maintainAspectRatio: false, // Allows the height to be controlled via CSS
      plugins: {
        legend: { display: false },
        tooltip: {
          enabled: true,
          callbacks: {
            label: (context) => {
              const date = context.label;
              const totalValue = context.raw;
              const details = detailsMap[date] || [];

              const totalLabel =
                selectedMetric === 'earnings'
                  ? `Total: €${totalValue.toFixed(2)}`
                  : selectedMetric === 'profits'
                    ? `Total: €${totalValue.toFixed(2)}`
                    : `Total: ${totalValue}`;

              // Sort by `sourceName` before mapping
              const sortedDetails = details.sort((a, b) =>
                a.sourceName.localeCompare(b.sourceName)
              );

              const detailLabels = sortedDetails
                .filter((detail) => detail.value > 0)
                .map((detail) => {
                  if (selectedSource && selectedProgram) {
                    return null;
                  } else if (selectedSource) {
                    return `${detail.programName}: ${selectedMetric === 'earnings' || selectedMetric === 'profits'
                      ? `€${detail.value.toFixed(2)}`
                      : detail.value}`;
                  } else if (selectedProgram) {
                    return `${detail.sourceName}: ${selectedMetric === 'earnings' || selectedMetric === 'profits'
                      ? `€${detail.value.toFixed(2)}`
                      : detail.value}`;
                  } else {
                    return `${detail.sourceName} - ${detail.programName}: ${selectedMetric === 'earnings' || selectedMetric === 'profits'
                      ? `€${detail.value.toFixed(2)}`
                      : detail.value}`;
                  }
                })
                .filter(Boolean);

              return [totalLabel, ...detailLabels];
            },
          },
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          titleColor: '#FCA311',
          bodyColor: '#ffffff',
          borderWidth: 1,
          padding: 10,
          bodySpacing: 5,
          displayColors: false,
        },
        datalabels: {
          display: false,
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          max: suggestedMax,
          grid: { display: false },
          ticks: {
            callback: yAxisTickCallback,
            autoSkip: true,
          },
          border: { display: false },
        },
        x: {
          grid: { display: false },
          offset: true,
          ticks: {
            callback: (val, index) => chartData.labels[index],
          },
        },
      },
    };
  }, [
    chartData,
    detailsMap,
    selectedSource,
    selectedProgram,
    selectedMetric,
  ]);

  const handleChartPeriodChange = (event) => {
    onPeriodChange(event.target.value);
  };

  const handleSourceChange = (event) => {
    onSourceChange(event.target.value);
  };

  const handleProgramChange = (event) => {
    onProgramChange(event.target.value);
  };

  // Function to handle metric change
  const handleMetricChange = (metric) => {
    setSelectedMetric(metric);
  };


  return (
    <div className={styles.chartContainer}>
      <div className={styles.chartHeader}>
        <div className={styles.headerTheme}>
          <h3>Activity</h3>
          <div className={styles.chartLegends}>
            <button
              className={`${styles.toggleButton} ${selectedMetric === 'earnings' ? styles.active : ''
                }`}
              onClick={() => handleMetricChange('earnings')}
            >
              <span className={styles.earningsCircle}></span>
              Turnover

            </button>
            <button
              className={`${styles.toggleButton} ${selectedMetric === 'profits' ? styles.active : ''
                }`}
              onClick={() => handleMetricChange('profits')}
            >
              <span className={styles.profitsCircle}></span>
              Profits
            </button>
            <button
              className={`${styles.toggleButton} ${selectedMetric === 'QFTD' ? styles.active : ''
                }`}
              onClick={() => handleMetricChange('QFTD')}
            >
              <span className={styles.QFTDCircle}></span>
              QFTD
            </button>
          </div>
        </div>
        <div className={styles.chartFilter}>
          <FormControl variant="outlined" size="small">
            <InputLabel>Period</InputLabel>
            <Select
              value={chartPeriod}
              onChange={handleChartPeriodChange}
              label="Period"
            >
              <MenuItem value="last7days">Last 7 Days</MenuItem>
              <MenuItem value="lastMonth">Last Month</MenuItem>
            </Select>
          </FormControl>
        </div>
        <FormControl variant="outlined" size="small" fullWidth>
          <InputLabel>Source</InputLabel>
          <Select
            value={selectedSource || ''}
            onChange={handleSourceChange}
            label="Source"
          >
            <MenuItem value={null}>All</MenuItem>
            {sources.map((source) => (
              <MenuItem key={source.id} value={source.id}>
                {source.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" size="small" fullWidth>
          <InputLabel>Program</InputLabel>
          <Select
            value={selectedProgram || ''}
            onChange={handleProgramChange}
            label="Program"
          >
            <MenuItem value={null}>All</MenuItem>
            {programs.map((program) => (
              <MenuItem key={program.id} value={program.id}>
                {program.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {chartData ? (
        <div className={styles.chartWrapper}>
          <Line data={chartData} options={chartOptions} />
        </div>
      ) : (
        <p>No data available for the selected period.</p>
      )}
    </div>
  );
};

export default EarningsChart;
