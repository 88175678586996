import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './NotFound.module.scss';

const NotFound = () => {
  useEffect(() => {
    document.title = 'Savvy - Not Found';
  }, []);
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/'); // Navigerar tillbaka till startsidan
  };

  return (
    <div className={styles.notfoundContainer}>
      <h1 className={styles.notfoundTitle}>404 - Page Not Found</h1>
      <p className={styles.notfoundMessage}>Sorry, the page you are looking for does not exist.</p>
      <button className={styles.notfoundButton} onClick={handleGoHome}>
        Go to Homepage
      </button>
    </div>
  );
};

export default NotFound;
