import React, { useState, useEffect } from "react";
import authService from "../../../services/authService";
import styles from "../Settings.module.scss";

const MailSettings = () => {
    const [email, setEmail] = useState("");
    const [settings, setSettings] = useState({
        payoutReceipt: false,
        programDowntimes: false,
        newPrograms: false,
        newProgramRegions: false,
        newQFTDs: false,
        programInformation: false,
        responsesToProgramRequests: false,
        linkUpdates: false,
        dealUpdates: false,
    });
    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState("");

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                setLoading(true);
                const response = await authService.getUserMailSignups();
                setEmail(response.userMail || ""); // Default to empty if no email
                setSettings({
                    payoutReceipt: response.payoutReceipt || false,
                    programDowntimes: response.programDowntimes || false,
                    newPrograms: response.newPrograms || false,
                    newProgramRegions: response.newProgramRegions || false,
                    newQFTDs: response.newQFTDs || false,
                    programInformation: response.programInformation || false,
                    responsesToProgramRequests: response.responsesToProgramRequests || false,
                    linkUpdates: response.linkUpdates || false,
                    dealUpdates: response.dealUpdates || false,
                });
            } catch (error) {
                console.error("Error fetching settings:", error);
                setEmail("");
                setSettings({
                    payoutReceipt: false,
                    programDowntimes: false,
                    newPrograms: false,
                    newProgramRegions: false,
                    newQFTDs: false,
                    programInformation: false,
                    responsesToProgramRequests: false,
                    linkUpdates: false,
                    dealUpdates: false,
                });
            } finally {
                setLoading(false);
            }
        };

        fetchSettings();
    }, []);

    const validateEmail = (email) => {
        if (!email) {
            return "Email is required.";
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            return "Invalid email format.";
        }
        return "";
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setSettings((prevSettings) => ({
            ...prevSettings,
            [name]: checked,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const error = validateEmail(email);
        if (error) {
            setEmailError(error);
            return;
        }
        setEmailError("");

        try {
            setLoading(true);
            await authService.setUserMailSignups({
                userMail: email,
                ...settings,
            });
            alert("Settings updated successfully!");
        } catch (error) {
            console.error("Error updating settings:", error);
            alert("Failed to update settings. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <form onSubmit={handleSubmit} className={styles.mailSettingsForm}>
                    <h2>Email Subscription</h2>
                    <div className={styles.formGroup}>
                        <p className={styles.infoText}>Select which email you want notification subscription for</p>
                        <label htmlFor="email"><span className={styles.obligatory}>Email Address</span>
                            <input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className={styles.input}
                                required
                            />
                        </label>
                        {emailError && <p className={styles.errorText}>{emailError}</p>}
                    </div>

                    <div className={styles.formGroup}>
                        <p className={styles.infoText}>Select which types of notications you want to subscription for</p>
                        <div className={styles.checkboxGroup}>

                            {Object.keys(settings).map((key) => (
                                <div key={key} className={styles.checkBox}>
                                    <input
                                        type="checkbox"
                                        id={key}
                                        name={key}
                                        checked={settings[key]}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label htmlFor={key}>{formatLabel(key)}</label>
                                </div>
                            ))}
                        </div>

                    </div>

                    <div className={styles.formActions}>
                        <button type="submit" disabled={loading} className={styles.saveBtn}>
                            Save Settings
                        </button>
                    </div>
                </form>
            )}
        </>
    );
};

const formatLabel = (key) => {
    return key
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/^./, (str) => str.toUpperCase());
};

export default MailSettings;
