import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Select from 'react-select';
import authService from '../../services/authService';

import styles from './EarningsChart.module.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  ChartDataLabels
);

const EarningsChart = () => {

  const chartPeriodOptions = [
    { value: 'last7days', label: 'Last 7 Days' },
    { value: 'lastMonth', label: 'Last Month' },
  ];

  const defaultPeriod = chartPeriodOptions.find(option => option.value === "last7days");

  const [chartData, setChartData] = useState(null);
  const [chartOptions, setChartOptions] = useState(null);
  const [chartPeriod, setChartPeriod] = useState(defaultPeriod);
  const [earningsData, setEarningsData] = useState([]);

  const rootStyles = getComputedStyle(document.documentElement);


  const chartColors = {
    primary: rootStyles.getPropertyValue('--primary-color').trim(),
    lightPrimary: rootStyles.getPropertyValue('--light-primary-color').trim(),
    background: rootStyles.getPropertyValue('--background-color').trim(),
  };

  const fetchDashboardEarnings = async () => {
    try {
      const data = await authService.getDashboardEarnings(chartPeriod.value);
      setEarningsData(data);
    } catch (error) {
      console.error('Error fetching dashboard earnings data:', error);
    }
  };

  useEffect(() => {
    prepareChartData();
  }, [earningsData]);

  useEffect(() => {
    fetchDashboardEarnings();
  }, [chartPeriod]);

  const prepareChartData = () => {
    const allDates = generateDateRange(chartPeriod.value);
    const dataMap = {};
    const programMap = {};

    // Organisera data per datum och program
    earningsData.forEach((item) => {
      const date = new Date(item.date).toLocaleDateString(undefined, {
        month: 'short',
        day: 'numeric',
      });
      const totalEarning =
        parseFloat(item.totalCPAIncome || 0) +
        parseFloat(item.totalRevShareIncome || 0);

      if (!dataMap[date]) {
        dataMap[date] = totalEarning;
        programMap[date] = [];
      }

      // Lägg till programintäkter per datum
      item.programs.forEach((program) => {
        programMap[date].push({
          programName: program.programName,
          totalIncome: parseFloat(program.totalIncome || 0),
        });
      });
    });

    const dates = [];
    const earnings = [];

    allDates.forEach((date) => {
      dates.push(date);
      earnings.push(dataMap[date] || 0);
    });

    const maxYValue = Math.max(...earnings);
    const suggestedMax = maxYValue === 0 ? 100 : Math.ceil((maxYValue * 1.15) / 50) * 50;

    // Skapa data för diagrammet
    setChartData({
      labels: dates,
      datasets: [
        {
          label: 'Revenue',
          data: earnings,
          fill: false,
          backgroundColor: chartColors.lightPrimary,
          borderColor: chartColors.primary,
          pointRadius: 5,
          datalabels: {
            align: 'end',
            anchor: 'end',
            formatter: (value) => `€${value.toFixed(2)}`,
            font: { size: 10 },
            color: chartColors.primary,
          },
          cubicInterpolationMode: 'monotone',
        },
      ],
    });

    // Anpassad Tooltip Callback
    setChartOptions({
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: { display: false },
        tooltip: {
          enabled: true,
          callbacks: {
            // Ta bort `title` callback för att inte visa datumet
            label: (context) => {
              const date = context.label;
              const totalEarning = context.raw;
              const programs = programMap[date] || [];

              // Skapa standardrad med totalintäkter
              const totalLabel = `Total: €${totalEarning.toFixed(2)}`;

              // Skapa en uppdelning av programintäkter, en per rad
              const programLabels = programs
                .filter((program) => program.totalIncome > 0)
                .map(
                  (program) =>
                    `${program.programName}: €${program.totalIncome.toFixed(2)}`
                );

              // Returnera endast programlabels utan datum
              return [totalLabel, ...programLabels];
            },
          },
          bodyColor: '#ffffff',
          borderWidth: 1,
          padding: 10,
          bodySpacing: 5,
          displayColors: false,
        },
        datalabels: {
          display: false, // Döljer datalabels i själva diagrammet
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          max: suggestedMax,
          grid: { display: false },
          ticks: {
            callback: (value) => `€${value.toFixed(0)}`,
            autoSkip: true,
          },
          border: { display: false },
        },
        x: {
          grid: { display: false },
          offset: true,
          ticks: {
            callback: (val, index) => dates[index],
          },
        },
      },
    });


  };


  const generateDateRange = (period) => {
    const dates = [];
    const today = new Date();
    const startDate = new Date();

    if (period === 'last7days') {
      startDate.setDate(today.getDate() - 6);
    } else if (period === 'lastMonth') {
      startDate.setMonth(today.getMonth() - 1);
    }

    for (let d = new Date(startDate); d <= today; d.setDate(d.getDate() + 1)) {
      dates.push(
        new Date(d).toLocaleDateString(undefined, {
          month: 'short',
          day: 'numeric',
        })
      );
    }
    return dates;
  };

  const handleChartPeriodChange = (selectedOption) => {
    setChartPeriod(selectedOption);
  };


  return (
    <>
      <div className={styles.chartHeader}>
        <div className={styles.headerTheme}>
          <h3>Activity</h3>
          <div className={styles.chartLegends}>
            <span className={styles.earningsLegend}>Revenue</span>
          </div>
        </div>
        <div className={styles.chartFilter}>
          <Select
            value={chartPeriod}
            onChange={handleChartPeriodChange}
            options={chartPeriodOptions}
            className={styles.chartPeriodSelect}
            classNamePrefix="customSelect"
          />
        </div>
      </div>
      {chartData ? (
        <div className={styles.chartDataContainer}>
          <Line data={chartData} options={chartOptions} />
        </div>
      ) : (
        <p>No earnings data available for the selected period.</p>
      )}
    </>
  );
};

export default EarningsChart;
