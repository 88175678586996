import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import styles from './SetInformation.module.scss';

const SetInformation = ({
    isOpen,
    onRequestClose,
    onSubmit,
    program
}) => {
    const [scope, setScope] = useState('program'); // 'program' eller 'region'
    const [selectedProgramRegionId, setSelectedProgramRegionId] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [message, setMessage] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [notificationActive, setNotificationActive] = useState(false);

    // Hämta befintlig information baserat på scope och typ
    useEffect(() => {
        if (isOpen) {
            loadInformation();
        } else {
            resetForm();
        }
    }, [isOpen, scope, selectedProgramRegionId, selectedType, program]);

    const loadInformation = () => {
        let information = null;

        if (scope === 'program') {
            // Programnivå
            information =
                program.ProgramInformations?.find((info) => info.type === selectedType) || null;
        } else if (scope === 'region' && selectedProgramRegionId) {
            // Regionsnivå
            const region = program.ProgramRegions.find(
                (pr) => pr.id === parseInt(selectedProgramRegionId)
            );
            information = region?.ProgramInformations?.find(
                (info) => info.type === selectedType
            ) || null;
        }

        if (information) {
            setMessage(information.message || '');
            setIsEditing(true);
        } else {
            resetForm();
        }
    };

    const resetForm = () => {
        setMessage('');
        setIsEditing(false);
    };

    const handleSave = () => {
        const payload = {
            type: selectedType,
            message,
            programId: program.id,
            programRegionId: scope === 'region' ? selectedProgramRegionId : null,
            notificationActive,
        };
        onSubmit(payload);
        resetForm();
        onRequestClose();
    };

    const handleDelete = () => {
        const payload = {
            scope,
            isDelete: true,
            type: selectedType,
            programId: program.id,
            programRegionId: scope === 'region' ? selectedProgramRegionId : null,
        };
        onSubmit(payload);
        resetForm();
        onRequestClose();
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => {
                resetForm();
                onRequestClose();
            }}
            className={styles.modal}
            overlayClassName={styles.modalOverlay}
        >
            <div className={styles.modalHeader}>
                <h3>{isEditing ? 'Edit Information' : 'Set Information'}</h3>
                <button className={styles.modalClose} onClick={() => onRequestClose()}>
                    <span class="material-symbols-outlined">
                        close
                    </span>
                </button>
            </div>
            <div className={styles.modalContent}>
                {/* Steg 1: Välj scope (program eller region) */}
                <div className={styles.formField}>
                    <label htmlFor="scope">Apply to</label>
                    <select
                        id="scope"
                        value={scope}
                        onChange={(e) => {
                            setScope(e.target.value);
                            setSelectedProgramRegionId(''); // Återställ vald region vid byte av scope
                            setSelectedType(''); // Återställ typ vid byte av scope
                        }}
                        className={styles.input}
                    >
                        <option value="program">Entire Program</option>
                        <option value="region">Specific Region</option>
                    </select>
                </div>

                {/* Om scope är region, visa val av region */}
                {scope === 'region' && (
                    <div className={styles.formField}>
                        <label htmlFor="region">Select Region</label>
                        <select
                            id="region"
                            value={selectedProgramRegionId}
                            onChange={(e) => setSelectedProgramRegionId(e.target.value)}
                            className={styles.input}
                        >
                            <option value="" disabled>Select region</option>
                            {program.ProgramRegions.map((pr) => (
                                <option key={pr.id} value={pr.id}>
                                    {pr.Region.name}
                                </option>
                            ))}
                        </select>
                    </div>
                )}

                {/* Information Type */}
                <div className={styles.formField}>
                    <label htmlFor="informationType">Information Type</label>
                    <select
                        id="informationType"
                        value={selectedType}
                        onChange={(e) => setSelectedType(e.target.value)}
                        className={styles.input}
                    >
                        <option value="" disabled>Select type</option>
                        <option value="general">General Information</option>
                        <option value="delay">Data Delays Information</option>
                        <option value="warning">Warning Information</option>
                    </select>
                </div>

                {/* Message */}
                {selectedType && (
                    <>
                        <div className={styles.formField}>
                            <label htmlFor="message">Message</label>
                            <textarea
                                id="message"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                className={styles.textarea}
                                placeholder="Type your message here..."
                            />
                        </div>
                        <div className={styles.notificationContainer}>
                            <button
                                type="button"
                                className={`${styles.notificationBtn} ${notificationActive && styles.active}`}
                                onClick={() => setNotificationActive(!notificationActive)}
                                disabled={!selectedType || !message}
                            >
                                <span className="material-symbols-outlined">
                                    notifications
                                </span>
                            </button>
                            <p>Send information as notifications for users with the program/region</p>
                        </div>
                    </>
                )}
            </div>
            <div className={styles.modalActions}>
                {isEditing && (
                    <>
                        <button
                            className={styles.saveButton}
                            onClick={handleSave}
                            disabled={!selectedType || !message}
                        >
                            Save
                        </button>
                        <button
                            className={styles.deleteButton}
                            onClick={handleDelete}
                        >
                            Delete
                        </button>
                    </>
                )}

                {!isEditing && (
                    <button
                        className={styles.submitButton}
                        onClick={handleSave}
                        disabled={!selectedType || !message}
                    >
                        Submit
                    </button>
                )}
                <button
                    className={styles.cancelButton}
                    onClick={() => {
                        resetForm();
                        onRequestClose();
                    }}
                >
                    Cancel
                </button>
            </div>
        </Modal>
    );
};

export default SetInformation;
