import React, { useState } from 'react';
import Modal from 'react-modal';
import styles from './EditSourceProgramDealModal.module.scss';

const EditSourceProgramDealModal = ({
  isOpen,
  onRequestClose,
  selectedProgramSource,
  onSubmit,
}) => {
  const [dealData, setDealData] = useState({
    baseline: selectedProgramSource?.baseline || '',
    cpaBefore: selectedProgramSource?.cpaBefore || '',
    cpa: selectedProgramSource?.cpa || '',
    revshareBefore: selectedProgramSource?.revshareBefore || '',
    revshare: selectedProgramSource?.revshare || '',
  });

  const handleDealChange = (field, value) => {
    setDealData({ ...dealData, [field]: value });
  };

  const handleSubmit = () => {
    onSubmit(dealData);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={styles.modal}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.modalContent}>
        <h3>Edit Deal</h3>
        <div>
          <p><strong>Source: </strong>{selectedProgramSource?.source}</p>
          <p><strong>Program: </strong>{selectedProgramSource?.program}</p>
        </div>
        <div>
          <h6>Current deal</h6>
          <p><strong>Baseline:</strong> {selectedProgramSource?.baseline} €</p>
          <p><strong>Savvy CPA:</strong> {selectedProgramSource?.cpaBefore} €</p>
          <p><strong>Customer CPA:</strong> {selectedProgramSource?.cpa} €</p>
          <p><strong>Savvy Revshare:</strong> {selectedProgramSource?.revshareBefore}%</p>
          <p><strong>Customer Revshare:</strong> {selectedProgramSource?.revshare}%</p>
        </div>
        <p className={styles.warningText}>This change will only affect the current region.</p>

        <div className={styles.inputGroup}>
          <div className={styles.formField}>
            <label htmlFor="baseline">New Baseline</label>
            <input
              id="baseline"
              type="number"
              value={dealData.baseline}
              onChange={(e) => handleDealChange('baseline', e.target.value)}
            />
          </div>

          <div className={styles.formField}>
            <label htmlFor="cpaBefore">New Savvy CPA €</label>
            <input
              id="cpaBefore"
              type="number"
              value={dealData.cpaBefore}
              onChange={(e) => handleDealChange('cpaBefore', e.target.value)}
            />
          </div>

          <div className={styles.formField}>
            <label htmlFor="cpa">New Customer CPA €</label>
            <input
              id="cpa"
              type="number"
              value={dealData.cpa}
              onChange={(e) => handleDealChange('cpa', e.target.value)}
            />
          </div>

          <div className={styles.formField}>
            <label htmlFor="revshareBefore">New Savvy Revshare %</label>
            <input
              id="revshareBefore"
              type="number"
              value={dealData.revshareBefore}
              onChange={(e) => handleDealChange('revshareBefore', e.target.value)}
            />
          </div>

          <div className={styles.formField}>
            <label htmlFor="revshare">New Customer Revshare %</label>
            <input
              id="revshare"
              type="number"
              value={dealData.revshare}
              onChange={(e) => handleDealChange('revshare', e.target.value)}
            />
          </div>
        </div>

        <div className={styles.modalActions}>
          <button onClick={handleSubmit} className={styles.submitButton}>Submit</button>
          <button onClick={onRequestClose} className={styles.cancelButton}>Cancel</button>
        </div>
      </div>
    </Modal>
  );
};

export default EditSourceProgramDealModal;
