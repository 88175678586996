import React, { useState, useEffect, useRef } from 'react';
import styles from './Performance.module.scss';
import programService from '../../services/programService';
import sourceService from '../../services/sourceService';
import performanceService from '../../services/performanceService';
import Select from 'react-select';
import Flag from 'react-world-flags';
import Tooltip from '@mui/material/Tooltip';
import PaginationComponent from '../../components/PaginationComponent';
import useScrollLock from './useScrollLock'; // Importera hooken
import DateSelect from '../../components/DateSelect';
import Modal from 'react-modal';
import FadeScrollContainer from '../../components/FadeScrollContainer';

const FlagDisplay = ({ regions, maxVisibleFlags = 4 }) => {
  if (!regions || regions.length === 0) return <div>-</div>;

  const visibleFlags =
    regions.length > maxVisibleFlags ? regions.slice(0, maxVisibleFlags - 1) : regions; // Upp till maxVisibleFlags
  const extraFlags =
    regions.length > maxVisibleFlags ? regions.slice(maxVisibleFlags - 1) : []; // Resten

  return (
    <div className={styles.flags}>
      {visibleFlags.map((region, idx) => (
        <Tooltip key={idx} title={<p style={{ margin: '0px' }}>{region.name}</p>} arrow>
          <div className={styles.flag}>
            <Flag code={region.shortCode} />
          </div>
        </Tooltip>
      ))}
      {extraFlags.length > 0 && (
        <Tooltip
          title={
            <div style={{ display: 'grid', gridTemplateColumns: 'auto auto', gap: '8px' }}>
              {extraFlags.map((region, idx) => (
                <>
                  <div style={{ display: "flex", just: "flex-end", width: "100%" }}>
                    <Flag code={region.shortCode} height="16" />
                  </div >
                  <p key={idx} style={{ margin: '0px' }}>
                    {region.name}
                  </p>
                </>
              ))}
            </div>
          }
          arrow
        >
          <div className={styles.moreFlags}>
            <span class="material-symbols-outlined">
              more_horiz
            </span>
          </div>
        </Tooltip >
      )}
    </div >
  );
};


const Performance = () => {
  useEffect(() => {
    document.title = 'Savvy - Performance';
  }, []);

  const [loading, setLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);
  const [filterLoading, setFilterLoading] = useState(true);

  // ================================
  // FILTER: State-hantering
  // ================================
  const [appliedFilters, setAppliedFilters] = useState({
    source: null,
    brand: null,
  });

  // 2. Temporära filter som används i modalen
  const [tempFilters, setTempFilters] = useState({
    source: null,
    brand: null,
  });

  const [sources, setSources] = useState([]);
  const [brands, setBrands] = useState([]);

  const [totalActive, setTotalActive] = useState(false);
  const [totals, setTotals] = useState({});

  const [perDay, setPerDay] = useState(false);

  // Tillfälliga filter som användaren redigerar
  const [appliedDateRange, setAppliedDateRange] = useState(null);
  const [splitByRegion, setSplitByRegion] = useState(false);
  const [splitPerDay, setSplitPerDay] = useState(false);

  const [performanceData, setPerformanceData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [showBreakdownMenu, setShowBreakdownMenu] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const breakdownMenuRef = useRef(null);
  const breakdownButtonRef = useRef(null);
  const [showFilterModal, setFilterShowModal] = useState(false); // Filter-modal

  const tableOuterRef = useRef(null); // Ref för tabellens container


  useEffect(() => {
    fetchFilters();
    fetchPerformance();
  }, []);

  // Funktion för att hämta initial data
  const fetchFilters = async () => {
    try {
      setFilterLoading(true); // Starta laddningsindikator
      const [sourcesData, brandsData] = await Promise.all([
        sourceService.getUserSources(),
        programService.fetchAllBrands(),
      ]);

      // Sätt data för sources och brands när de två första anropen är klara
      setSources(sourcesData);
      setBrands(
        brandsData.map((brand) => ({ value: brand.id, label: brand.name }))
      );

    } catch (error) {
      console.error('Error fetching initial data:', error);
    } finally {
      setFilterLoading(false); // Stoppa laddningsindikator
    }
  };

  // Funktion för att hämta prestandadata
  const fetchPerformance = async () => {
    try {
      if (firstLoad) {
        setLoading(true);
      }

      const response = await performanceService.fetchPerformance(
        appliedFilters.source?.value || null,
        appliedFilters.brand?.value || null,
        appliedDateRange,
        splitByRegion ? "regions" : "programs",
        splitPerDay,
      );

      setTotals(response.totals);
      setPerformanceData(response.data);
      setCurrentPage(1); // Återställ till första sidan
      if (splitPerDay) {
        setPerDay(true);
      } else {
        setPerDay(false);
      }
    } catch (error) {
      console.error('Error fetching performance data:', error);
    } finally {

      if (!firstLoad) {
        setFirstLoad(true)
      } else {
        setLoading(false);
      }
    }
  };

  // Handle clicks outside BreakdownMenu
  useEffect(() => {
    const handleClickOutsideBreakdownMenu = (event) => {
      if (
        breakdownMenuRef.current &&
        !breakdownMenuRef.current.contains(event.target) &&
        breakdownButtonRef.current &&
        !breakdownButtonRef.current.contains(event.target)
      ) {
        setShowBreakdownMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutsideBreakdownMenu);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideBreakdownMenu);
    };
  }, [breakdownMenuRef, breakdownButtonRef]); // Lägg till breakdownButtonRef här


  const getStatDisplayValue = (statValue) => (statValue != null ? statValue : '-');

  // Beräkna total antal sidor
  const totalPages = Math.ceil(performanceData.length / itemsPerPage);

  // Funktion för att hantera sidbyte
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const getSortValue = (dataItem, key) => {
    switch (key) {
      case 'date':
        return dataItem.stats.date || '';
      case 'program':
        return dataItem.brandName + (dataItem.verticals?.join(', ') || '');
      case 'regions':
        return dataItem.type === 'program'
          ? dataItem.regions.map((r) => r.name).join(', ')
          : dataItem.region.name;
      case 'source':
        return dataItem.sourceName || '';
      case 'clicks':
        return dataItem.stats.clicks || 0;
      case 'signups':
        return dataItem.stats.signups || 0;
      case 'FTD':
        return dataItem.stats.FTD || 0;
      case 'QFTD':
        return dataItem.stats.QFTD || 0;
      case 'CPAhold':
        return dataItem.stats.CPAIncomeHold || 0;
      case 'CPAverified':
        return dataItem.stats.CPAIncome || 0;
      case 'CPAdeny':
        return dataItem.stats.CPAIncomeDenied || 0;
      case 'RevShare':
        return dataItem.stats.revShareIncome || 0;
      case 'Total':
        return (dataItem.stats.CPAIncome || 0) + (dataItem.stats.revShareIncome || 0);
      default:
        return '';
    }
  };

  const sortedData = React.useMemo(() => {
    let sortableData = [...performanceData];
    if (sortConfig !== null && sortConfig.key !== null) {
      sortableData.sort((a, b) => {
        const aValue = getSortValue(a, sortConfig.key);
        const bValue = getSortValue(b, sortConfig.key);

        if (typeof aValue === 'string') {
          return sortConfig.direction === 'ascending'
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        } else {
          return sortConfig.direction === 'ascending'
            ? aValue - bValue
            : bValue - aValue;
        }
      });
    }
    return sortableData;
  }, [performanceData, sortConfig]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    setCurrentPage(1); // Återställ till första sidan
    if (tableOuterRef.current) {
      tableOuterRef.current.scrollTo(0, 0); // Scrolla till toppen
    }
  };

  // ================================
  // Filter-hantering i modalen
  // ================================
  const handleTempFilterChange = (key, value) => {
    setTempFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const applyFilters = () => {
    setAppliedFilters(tempFilters);
    setFilterShowModal(false);
  };

  const cancelFilters = () => {
    setTempFilters(appliedFilters);
    setFilterShowModal(false);
  };

  const clearFilters = () => {
    setTempFilters({
      source: appliedFilters.source,
      brand: null,
    });
  };

  const countActiveFilters = () => {
    let count = 0;
    if (appliedFilters.source) count++;
    if (appliedFilters.brand) count++;
    return count;
  };

  const countActiveBreakdown = () => {
    let count = 0;
    if (splitPerDay) { count += 1 };
    if (splitByRegion) { count += 1 };
    return count;
  }

  return (
    <div className={styles.performanceContainer}>
      <div className={styles.header}>
        <h1>Performance</h1>
        <FadeScrollContainer
          direction="horizontal"
          fadeRange={20}
          fadeSize={150}
          fadeColor="var(--background-grey-2)"
          maxHeight="46px"
          height={'46px'}
          width='fit-content'
          maxWidth='calc(100% + 6px)'
          classNameInner={styles.fadescrollInner}
          className={styles.fadescroll}
        >
          <div className={styles.options}>
            <div className={styles.filters}>
              <label className={`${styles.showTotals} ${!totalActive && styles.inactiveTotal}`}>
                <input type="checkbox"
                  checked={totalActive}
                  onChange={(e) => setTotalActive(e.target.checked)}
                />
                <p className={styles.showTotalBtn}>
                  {totalActive ?
                    <>
                      <span class="material-symbols-outlined">
                        visibility_off
                      </span>
                      <p>Hide Total</p>
                    </>
                    :
                    <>
                      <span class="material-symbols-outlined">
                        visibility
                      </span>
                      <p>View Total</p>
                    </>
                  }
                </p>
              </label>
              <div className={styles.breakdownContainer}>
                <div>
                  <button
                    className={styles.breakDownButton}
                    onClick={() => setShowBreakdownMenu(!showBreakdownMenu)}
                    ref={breakdownButtonRef}
                  >

                    <span class="material-symbols-outlined">
                      splitscreen
                    </span>
                    Split By
                    <span className={styles.filterCount}>{countActiveBreakdown()}/2</span>
                  </button>
                </div>

                {showBreakdownMenu && (
                  <div ref={breakdownMenuRef} className={styles.breakdownMenu}>
                    <div>
                      <label className={styles.checkBox}>
                        <input
                          type="checkbox"
                          checked={splitByRegion}
                          onChange={(e) => setSplitByRegion(e.target.checked)}
                        />
                        Region
                      </label>
                    </div>
                    <div>
                      <label className={styles.checkBox}>
                        <input
                          type="checkbox"
                          checked={splitPerDay}
                          onChange={(e) => setSplitPerDay(e.target.checked)}
                        />
                        Day
                      </label>
                    </div>
                  </div>
                )}
              </div>
              <DateSelect dateRange={appliedDateRange} setDateRange={setAppliedDateRange} boxShadow={true} />
              <button className={styles.filterButton} onClick={() => setFilterShowModal(true)}>
                <span className="material-symbols-outlined">filter_alt</span>
                Filter
                <span className={styles.filterCount}>{countActiveFilters()}/2</span>
              </button>
            </div>
            <button onClick={fetchPerformance} className={styles.generateReportBtn}>
              <span className={`material-symbols-outlined ${loading && styles.loading}`}>
                refresh
              </span>
              Generate Report
            </button>
          </div>
        </FadeScrollContainer>

      </div>

      {!firstLoad ?
        <div className={styles.loadingTable}><div className={styles.spinnerRing}><div className={styles.ring}></div></div></div> :
        <div className={styles.tableOuter} ref={tableOuterRef}>
          <table className={styles.tableFullWidth}>
            <thead>
              <tr>
                {perDay && (
                  <th>
                    <div className={styles.sortableHeader} onClick={() => handleSort('date')} style={{ cursor: 'pointer' }}>
                      Date
                      <div className={styles.sortIcons}>
                        <span
                          className={`material-symbols-outlined ${sortConfig.key === 'date' && sortConfig.direction === 'ascending' ? styles.activeSort : ''
                            }`}
                        >
                          arrow_drop_up
                        </span>
                        <span
                          className={`material-symbols-outlined ${sortConfig.key === 'date' && sortConfig.direction === 'descending' ? styles.activeSort : ''
                            }`}
                        >
                          arrow_drop_down
                        </span>
                      </div>
                    </div>
                  </th>
                )}
                <th>
                  <div className={styles.sortableHeader} onClick={() => handleSort('program')} style={{ cursor: 'pointer' }}>
                    Program
                    <div className={styles.sortIcons}>
                      <span
                        className={`material-symbols-outlined ${sortConfig.key === 'program' && sortConfig.direction === 'ascending' ? styles.activeSort : ''
                          }`}
                      >
                        arrow_drop_up
                      </span>
                      <span
                        className={`material-symbols-outlined ${sortConfig.key === 'program' && sortConfig.direction === 'descending' ? styles.activeSort : ''
                          }`}
                      >
                        arrow_drop_down
                      </span>
                    </div>
                  </div>
                </th>
                <th>
                  <div className={styles.sortableHeader} onClick={() => handleSort('regions')} style={{ cursor: 'pointer' }}>
                    Regions
                    <div className={styles.sortIcons}>
                      <span
                        className={`material-symbols-outlined ${sortConfig.key === 'regions' && sortConfig.direction === 'ascending' ? styles.activeSort : ''
                          }`}
                      >
                        arrow_drop_up
                      </span>
                      <span
                        className={`material-symbols-outlined ${sortConfig.key === 'regions' && sortConfig.direction === 'descending' ? styles.activeSort : ''
                          }`}
                      >
                        arrow_drop_down
                      </span>
                    </div>
                  </div>
                </th>
                <th>
                  <div className={styles.sortableHeader} onClick={() => handleSort('source')} style={{ cursor: 'pointer' }}>
                    Source
                    <div className={styles.sortIcons}>
                      <span
                        className={`material-symbols-outlined ${sortConfig.key === 'source' && sortConfig.direction === 'ascending' ? styles.activeSort : ''
                          }`}
                      >
                        arrow_drop_up
                      </span>
                      <span
                        className={`material-symbols-outlined ${sortConfig.key === 'source' && sortConfig.direction === 'descending' ? styles.activeSort : ''
                          }`}
                      >
                        arrow_drop_down
                      </span>
                    </div>
                  </div>
                </th>
                <th>
                  <div className={styles.sortableHeader} onClick={() => handleSort('clicks')} style={{ cursor: 'pointer' }}>
                    Clicks
                    <div className={styles.sortIcons}>
                      <span
                        className={`material-symbols-outlined ${sortConfig.key === 'clicks' && sortConfig.direction === 'ascending' ? styles.activeSort : ''
                          }`}
                      >
                        arrow_drop_up
                      </span>
                      <span
                        className={`material-symbols-outlined ${sortConfig.key === 'clicks' && sortConfig.direction === 'descending' ? styles.activeSort : ''
                          }`}
                      >
                        arrow_drop_down
                      </span>
                    </div>
                  </div>
                </th>
                <th>
                  <div className={styles.sortableHeader} onClick={() => handleSort('signups')} style={{ cursor: 'pointer' }}>
                    Signups
                    <div className={styles.sortIcons}>
                      <span
                        className={`material-symbols-outlined ${sortConfig.key === 'signups' && sortConfig.direction === 'ascending' ? styles.activeSort : ''
                          }`}
                      >
                        arrow_drop_up
                      </span>
                      <span
                        className={`material-symbols-outlined ${sortConfig.key === 'signups' && sortConfig.direction === 'descending' ? styles.activeSort : ''
                          }`}
                      >
                        arrow_drop_down
                      </span>
                    </div>
                  </div>
                </th>
                <th>
                  <div className={styles.sortableHeader} onClick={() => handleSort('FTD')} style={{ cursor: 'pointer' }}>
                    FTD
                    <div className={styles.sortIcons}>
                      <span
                        className={`material-symbols-outlined ${sortConfig.key === 'FTD' && sortConfig.direction === 'ascending' ? styles.activeSort : ''
                          }`}
                      >
                        arrow_drop_up
                      </span>
                      <span
                        className={`material-symbols-outlined ${sortConfig.key === 'FTD' && sortConfig.direction === 'descending' ? styles.activeSort : ''
                          }`}
                      >
                        arrow_drop_down
                      </span>
                    </div>
                  </div>
                </th>
                <th>
                  <div className={styles.sortableHeader} onClick={() => handleSort('QFTD')} style={{ cursor: 'pointer' }}>
                    QFTD
                    <div className={styles.sortIcons}>
                      <span
                        className={`material-symbols-outlined ${sortConfig.key === 'QFTD' && sortConfig.direction === 'ascending' ? styles.activeSort : ''
                          }`}
                      >
                        arrow_drop_up
                      </span>
                      <span
                        className={`material-symbols-outlined ${sortConfig.key === 'QFTD' && sortConfig.direction === 'descending' ? styles.activeSort : ''
                          }`}
                      >
                        arrow_drop_down
                      </span>
                    </div>
                  </div>
                </th>
                <th className={styles.CPATh} colSpan={3}>
                  <div className={`${styles.sortableHeader} ${styles.CPAheader}`}>
                    <p>
                      CPA
                    </p>
                    <div className={styles.CPAbreakdown}>
                      <div onClick={() => handleSort('CPAhold')} style={{ cursor: 'pointer' }}>
                        <Tooltip title={<p style={{ margin: '0px' }}>Hold CPA</p>} arrow>
                          <div className={styles.holdCPAspan}>
                            <span class="material-symbols-outlined">
                              schedule
                            </span>
                          </div>
                        </Tooltip>
                        <div className={styles.sortIcons}>
                          <span
                            className={`material-symbols-outlined ${sortConfig.key === 'CPAhold' && sortConfig.direction === 'ascending' ? styles.activeSort : ''
                              }`}
                          >
                            arrow_drop_up
                          </span>
                          <span
                            className={`material-symbols-outlined ${sortConfig.key === 'CPAhold' && sortConfig.direction === 'descending' ? styles.activeSort : ''
                              }`}
                          >
                            arrow_drop_down
                          </span>
                        </div>
                      </div>
                      <div onClick={() => handleSort('CPAdeny')} style={{ cursor: 'pointer' }}>
                        <Tooltip title={<p style={{ margin: '0px' }}>Denied CPA</p>} arrow>
                          <div className={styles.deniedCPAspan}>
                            <span class="material-symbols-outlined">
                              cancel
                            </span>
                          </div>
                        </Tooltip>
                        <div className={styles.sortIcons}>
                          <span
                            className={`material-symbols-outlined ${sortConfig.key === 'CPAdeny' && sortConfig.direction === 'ascending' ? styles.activeSort : ''
                              }`}
                          >
                            arrow_drop_up
                          </span>
                          <span
                            className={`material-symbols-outlined ${sortConfig.key === 'CPAdeny' && sortConfig.direction === 'descending' ? styles.activeSort : ''
                              }`}
                          >
                            arrow_drop_down
                          </span>
                        </div>
                      </div>
                      <div onClick={() => handleSort('CPAverified')} style={{ cursor: 'pointer' }}>
                        <Tooltip title={<p style={{ margin: '0px' }}>Approved CPA</p>} arrow>
                          <div className={styles.verifiedCPAspan}>
                            <span class="material-symbols-outlined">
                              check_circle
                            </span>
                          </div>
                        </Tooltip>
                        <div className={styles.sortIcons}>
                          <span
                            className={`material-symbols-outlined ${sortConfig.key === 'CPAverified' && sortConfig.direction === 'ascending' ? styles.activeSort : ''
                              }`}
                          >
                            arrow_drop_up
                          </span>
                          <span
                            className={`material-symbols-outlined ${sortConfig.key === 'CPAverified' && sortConfig.direction === 'descending' ? styles.activeSort : ''
                              }`}
                          >
                            arrow_drop_down
                          </span>
                        </div></div>
                    </div>
                  </div>
                </th>
                <th className={styles.revshareCell}>
                  <div className={styles.sortableHeader} onClick={() => handleSort('RevShare')} style={{ cursor: 'pointer' }}>
                    RevShare
                    <div className={styles.sortIcons}>
                      <span
                        className={`material-symbols-outlined ${sortConfig.key === 'RevShare' && sortConfig.direction === 'ascending' ? styles.activeSort : ''
                          }`}
                      >
                        arrow_drop_up
                      </span>
                      <span
                        className={`material-symbols-outlined ${sortConfig.key === 'RevShare' && sortConfig.direction === 'descending' ? styles.activeSort : ''
                          }`}
                      >
                        arrow_drop_down
                      </span>
                    </div>
                  </div>
                </th>
                <th>
                  <div className={styles.sortableHeader} onClick={() => handleSort('Total')} style={{ cursor: 'pointer' }}>
                    Total
                    <div className={styles.sortIcons}>
                      <span
                        className={`material-symbols-outlined ${sortConfig.key === 'Total' && sortConfig.direction === 'ascending' ? styles.activeSort : ''
                          }`}
                      >
                        arrow_drop_up
                      </span>
                      <span
                        className={`material-symbols-outlined ${sortConfig.key === 'Total' && sortConfig.direction === 'descending' ? styles.activeSort : ''
                          }`}
                      >
                        arrow_drop_down
                      </span>
                    </div>
                  </div>
                </th>

              </tr>
              {totalActive &&
                <tr className={styles.totalsRow}>
                  <td>Total</td>
                  <td></td>
                  <td></td>
                  {perDay && <td></td>}
                  <td>{totals.clicks}</td>
                  <td>{totals.signups}</td>
                  <td>{totals.FTD}</td>
                  <td>{totals.QFTD}</td>
                  <td className={styles.CPACell}>{totals.CPAIncomeHold} €</td>
                  <td className={styles.CPACell}>{totals.CPAIncomeDenied} €</td>
                  <td className={styles.CPACell}>{totals.CPAIncome} €</td>
                  <td className={styles.revshareCell}>{totals.revShareIncome.toFixed(2)} €</td>
                  <td>{totals.totalEarnings.toFixed(2)} €</td>

                </tr>
              }
            </thead>
            <tbody>
              {currentData.length > 0 ? (
                currentData.map((data, index) => {
                  const regions =
                    data.type === 'program' ? data.regions : [data.region];

                  return (
                    <tr key={index}>
                      {perDay && <td>{data.stats.date}</td>}
                      <td>
                        {data.programName}
                      </td>
                      <td className={styles.flagTD}>
                        <FlagDisplay regions={regions} />
                      </td>
                      <td>{data.sourceName}</td>
                      <td>{getStatDisplayValue(data.stats.clicks)}</td>
                      <td>{getStatDisplayValue(data.stats.signups)}</td>
                      <td>{getStatDisplayValue(data.stats.FTD)}</td>
                      <td>{getStatDisplayValue(data.stats.QFTD)}</td>
                      <td className={styles.CPACell}>                      {getStatDisplayValue(
                        data.stats.CPAIncomeHold != null
                          ? `${data.stats.CPAIncomeHold} €`
                          : null
                      )}</td>
                      <td className={styles.CPACell}>                      {getStatDisplayValue(
                        data.stats.CPAIncomeDenied != null
                          ? `${data.stats.CPAIncomeDenied} €`
                          : null
                      )}</td>
                      <td className={styles.CPACell}>                      {getStatDisplayValue(
                        data.stats.CPAIncome != null
                          ? `${data.stats.CPAIncome} €`
                          : null
                      )}
                      </td >
                      <td className={styles.revshareCell}>
                        {getStatDisplayValue(
                          data.stats.revShareIncome != null
                            ? `${data.stats.revShareIncome.toFixed(2)} €`
                            : null
                        )}
                      </td>
                      <td>
                        {getStatDisplayValue(
                          data.stats.CPAIncome != null || data.stats.revShareIncome != null
                            ? `${((data.stats.CPAIncome || 0) + (data.stats.revShareIncome || 0)).toFixed(2)} €`
                            : null
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="11" className={styles.noFound}>
                    No performance data available
                  </td>
                </tr>
              )}
              <tr className={styles.pagRow}>
                <td colSpan={12}>
                  <PaginationComponent
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                    totalPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      }

      <Modal
        isOpen={showFilterModal}
        onRequestClose={cancelFilters}
        className={styles.filterModal}
        overlayClassName={styles.overlay}
      >
        <div className={styles.modalHeader}>
          <h3>Filter</h3>
          <button onClick={cancelFilters} className={styles.modalClose}>
            <span className="material-symbols-outlined">close</span>
          </button>
        </div>
        <div className={styles.modalContent}>
          <div className={styles.filterForm}>
            <div>
              <label>Source</label>
              <Select
                name="source"
                value={tempFilters.source}
                onChange={(val) => handleTempFilterChange('source', val)}
                options={sources.map((source) => ({
                  value: source.id,
                  label: source.name,
                }))}
                classNamePrefix={filterLoading ? "loading" : "customSelect"}
                disabled={filterLoading}
                placeholder="Select source"
                isClearable
              />
            </div>
            <div>
              <label>Brand</label>
              <Select
                name="brandId"
                value={tempFilters.brand}
                onChange={(val) => handleTempFilterChange('brand', val)}
                options={brands}
                isClearable
                placeholder="Select a brand"
                classNamePrefix={filterLoading ? "loading" : "customSelect"}
                disabled={filterLoading}
              />
            </div>
          </div>
        </div>
        <div className={styles.modalActions}>
          <button onClick={clearFilters} className={styles.clearButton}>
            Clear
          </button>
          <div className={styles.rightSideButtons}>
            <button onClick={cancelFilters} className={styles.cancelButton}>
              Cancel
            </button>
            <button onClick={applyFilters} className={styles.applyButton}>
              Apply
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Performance;
