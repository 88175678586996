import React, { useState } from "react";
import Modal from "react-modal";
import Select from "react-select";
import { DateRange } from "react-date-range";
import { NavLink } from "react-router-dom";
import styles from "./ProgramTaskModal.module.scss";
import { enGB } from "date-fns/locale";
import performanceService from "../../../services/performanceService";

const ProgramTaskModal = ({ isOpen, onRequestClose, runFetch }) => {
    const [selectedPrograms, setSelectedPrograms] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
        },
    ]);
    const [loading, setLoading] = useState(false);
    const [summary, setSummary] = useState(null);

    const programOptions = [
        { value: "funbet", label: "Funbet" },
        { value: "unobet", label: "Unobet" },
        { value: "2rbo", label: "2RBO" },
        { value: "pinnacle", label: "Pinnacle" },
        { value: "chilli", label: "Chilli" },
        { value: "donbet", label: "Donbet" },
        { value: "mystake", label: "MyStake" },
        { value: "ggbet", label: "GGBet" },
        { value: "expekt", label: "Expekt" },
        { value: "casumo", label: "Casumo" },
        { value: "v.partners", label: "V.Partners" },
    ];

    const truncateDateRange = (range) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Ensure today is at midnight for comparison

        const startDate = range.selection.startDate;
        const endDate = range.selection.endDate > today ? today : range.selection.endDate;

        return {
            selection: {
                ...range.selection,
                startDate: startDate,
                endDate: endDate,
            },
        };
    };

    const handleSelectAll = () => {
        setSelectAll(!selectAll);
        if (!selectAll) {
            setSelectedPrograms(programOptions.map((option) => option.value));
        } else {
            setSelectedPrograms([]);
        }
    };

    const handleProgramChange = (selectedOptions) => {
        setSelectAll(false);
        setSelectedPrograms(selectedOptions.map((option) => option.value));
    };

    const handleSubmit = async () => {
        if (selectedPrograms.length === 0) {
            alert("Please select at least one program.");
            return;
        }

        setLoading(true);
        try {
            const formatDate = (date) => {
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, "0"); // Månaden är 0-baserad
                const day = String(date.getDate()).padStart(2, "0");
                return `${year}-${month}-${day}`;
            };

            const response = await performanceService.forceSync(
                selectedPrograms,
                formatDate(dateRange[0].startDate),
                formatDate(dateRange[0].endDate)
            );

            setSummary({
                warnings: response.warnings,
                errors: response.errors,
                logsLink: response.logsLink,
            });

            runFetch();
        } catch (error) {
            console.error("Error running tasks:", error);
            setSummary({
                warnings: 0,
                errors: 0,
                logsLink: null,
                error: true,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={!loading ? onRequestClose : undefined} // Prevent closing if loading
            className={styles.modal}
            overlayClassName={styles.overlay}
            shouldCloseOnOverlayClick={!loading} // Prevent overlay click when loading
        >
            <div className={styles.modalHeader}>
                <h3>Task Summary</h3>
                <button className={styles.modalClose} onClick={() => onRequestClose()}>
                    <span class="material-symbols-outlined">
                        close
                    </span>
                </button>
            </div>
            {loading ? (
                <div className={styles.loadingContainer}>
                    <div className={styles.spinner}></div>
                    <p>Running tasks, please wait...</p>
                </div>
            ) : summary ? (
                <>
                    <div className={styles.summaryContent}>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: summary.error
                                    ? "An error occurred while running tasks."
                                    : `Tasks completed successfully. <br/> <strong>Warnings</strong>: ${summary.warnings} <br/> <strong>Errors</strong>: ${summary.errors}`,
                            }}
                        ></p>

                    </div>
                    <div className={styles.modalActions}>
                        {summary.logsLink && (
                            <NavLink to={summary.logsLink} className={styles.submitButton} target="_blank">
                                Check Logs
                            </NavLink>
                        )}
                        <button
                            onClick={() => setSummary(null)}
                            className={styles.backButton}
                        >
                            Back
                        </button>
                        <button onClick={onRequestClose} className={styles.cancelButton}>
                            Close
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <div className={styles.modalContent}>
                        <div className={styles.formGroup}>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                />
                                Select All
                            </label>
                        </div>
                        <div className={styles.formGroup}>
                            <label>Select Programs</label>
                            <Select
                                options={programOptions}
                                isMulti
                                value={programOptions.filter((option) =>
                                    selectedPrograms.includes(option.value)
                                )}
                                onChange={handleProgramChange}
                                isDisabled={selectAll}
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label>Select Date Range</label>
                            <div className={styles.dateContainer}>
                                <DateRange
                                    ranges={dateRange}
                                    editableDateInputs={true}
                                    onChange={(ranges) =>
                                        setDateRange([truncateDateRange(ranges).selection])
                                    }
                                    moveRangeOnFirstSelection={false}
                                    maxDate={new Date()}
                                    locale={enGB}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.modalActions}>
                        <button onClick={handleSubmit} className={styles.submitButton}>
                            Run Tasks
                        </button>
                        <button onClick={onRequestClose} className={styles.cancelButton}>
                            Cancel
                        </button>
                    </div>
                </>
            )}
        </Modal>
    );
};

export default ProgramTaskModal;
