import React, { useState, useEffect, useRef } from 'react';
import styles from './Performance.module.scss';
import programService from '../../services/programService';
import sourceService from '../../services/sourceService';
import Select from 'react-select';
import DateSelect from '../../components/DateSelect'
import Flag from 'react-world-flags';
import Tooltip from '@mui/material/Tooltip';
import PaginationComponent from '../../components/PaginationComponent';
import AddEditPerformanceModal from './components/AddEditPerformanceModal';
import ProgramTaskModal from './components/ProgramTaskModal';
import performanceService from '../../services/performanceService';

const Performance = () => {
  useEffect(() => {
    document.title = 'Savvy - Performance';
  }, []);

  const [sources, setSources] = useState([]);
  const [brands, setBrands] = useState([]);

  // Faktiska filter som används vid API-anrop
  const [perDay, setPerDay] = useState(false);

  // Tillfälliga filter som användaren redigerar
  const [selectedSource, setSelectedSource] = useState(null);
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [appliedDateRange, setAppliedDateRange] = useState(null);
  const [breakdownOption, setBreakdownOption] = useState('regions');
  const [tempPerDay, setTempPerDay] = useState(false);

  const [performanceData, setPerformanceData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [showBreakdownMenu, setShowBreakdownMenu] = useState(false);

  const [totalActive, setTotalActive] = useState(false);
  const [totals, setTotals] = useState({});

  const breakdownMenuRef = useRef(null);
  const breakdownButtonRef = useRef(null);

  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const [showPerformanceModal, setShowPerformanceModal] = useState(false);
  const [showSyncModal, setShowSyncModal] = useState(false);

  useEffect(() => {
    fetchInitialData();
  }, []);

  // Funktion för att hämta initial data
  const fetchInitialData = async () => {
    try {
      const [sourcesData, brandsData] = await Promise.all([
        sourceService.getAllSources(),
        programService.fetchAllBrands(),
      ]);
      setSources(sourcesData);
      setBrands(
        brandsData.map((brand) => ({ value: brand.id, label: brand.name }))
      );

      // Hämta prestandadata med standardfilter
      fetchPerformance(
        null, // sourceId
        null, // brandId
        null, // dateRange
        breakdownOption,
        perDay,
        currentPage,
        itemsPerPage
      );
    } catch (error) {
      console.error('Error fetching initial data:', error);
    }
  };

  const fetchPerformance = async (sourceId, brandId, dateRange, breakdownOption, perDay) => {
    try {
      // Formatera start- och slutdatum om dateRange finns
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Månaden är 0-baserad
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

      const startDate = dateRange ? formatDate(dateRange.startDate) : null;
      const endDate = dateRange ? formatDate(dateRange.endDate) : null;
      // Skicka de separata datumen som parametrar
      const response = await performanceService.fetchAllPerformance(
        sourceId,
        brandId,
        startDate,
        endDate,
        breakdownOption,
        perDay
      );

      setPerformanceData(response.data);
      setTotals(response.totals)
      setCurrentPage(1); // Återställ till första sidan
    } catch (error) {
      console.error('Error fetching performance data:', error);
    }
  };
  // Funktion som anropas när användaren klickar på "Apply Filters"
  const handleApplyFilters = () => {
    setPerDay(tempPerDay);
    fetchPerformance(
      selectedSource ? selectedSource.id : null,
      selectedBrandId,
      appliedDateRange,
      breakdownOption,
      tempPerDay
    );
  };


  // Handle clicks outside BreakdownMenu
  useEffect(() => {
    const handleClickOutsideBreakdownMenu = (event) => {
      if (
        breakdownMenuRef.current &&
        !breakdownMenuRef.current.contains(event.target) &&
        breakdownButtonRef.current &&
        !breakdownButtonRef.current.contains(event.target)
      ) {
        setShowBreakdownMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutsideBreakdownMenu);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideBreakdownMenu);
    };
  }, [breakdownMenuRef, breakdownButtonRef]); // Lägg till breakdownButtonRef här

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      border: '1px solid #ced4da',
      borderRadius: '8px',
      padding: '2px',
      minHeight: '42px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#86b7fe',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#6c757d',
    }),
  };

  const handleSourceChange = (selectedOption) => {
    if (selectedOption) {
      const source = sources.find((src) => src.id === selectedOption.value);
      setSelectedSource(source);
    } else {
      setSelectedSource(null);
    }
  };

  const handleBrandChange = (selectedOption) => {
    setSelectedBrandId(selectedOption ? selectedOption.value : null);
  };

  const getStatDisplayValue = (statValue) => (statValue != null ? statValue : '-');

  // Beräkna total antal sidor
  const totalPages = Math.ceil(performanceData.length / itemsPerPage);

  // Funktion för att hantera sidbyte
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const getSortValue = (dataItem, key) => {
    switch (key) {
      case 'date':
        return dataItem.stats.date || '';
      case 'program':
        return dataItem.brandName + (dataItem.verticals?.join(', ') || '');
      case 'regions':
        return dataItem.type === 'program'
          ? dataItem.regions.map((r) => r.name).join(', ')
          : dataItem.region.name;
      case 'source':
        return dataItem.sourceName || '';
      case 'clicks':
        return dataItem.stats.clicks || 0;
      case 'signups':
        return dataItem.stats.signups || 0;
      case 'FTD':
        return dataItem.stats.FTD || 0;
      case 'QFTD':
        return dataItem.stats.QFTD || 0;
      case 'CPABeforeVerified':
        return dataItem.stats.CPAIncomeBeforeCommission || 0;
      case 'CPABeforeHold':
        return dataItem.stats.CPAIncomeBeforeCommissionHold || 0;
      case 'CPABeforeDenied':
        return dataItem.stats.CPAIncomeBeforeCommissionDenied || 0;
      case 'CPAdeny':
        return dataItem.stats.CPAIncomeDenied || 0;
      case 'CPAhold':
        return dataItem.stats.CPAIncomeHold || 0;
      case 'CPAverified':
        return dataItem.stats.CPAIncome || 0;
      case 'RevBeforeCommission':
        return dataItem.stats.revShareIncomeBeforeCommission || 0;
      case 'RevIncome':
        return dataItem.stats.revShareIncome || 0;
      case 'Total':
        return dataItem.stats.totalEarnings || 0;
      case 'locked':
        return dataItem.stats.locked || 0;
      default:
        return '';
    }
  };

  const sortedData = React.useMemo(() => {
    let sortableData = [...performanceData];
    if (sortConfig !== null && sortConfig.key !== null) {
      sortableData.sort((a, b) => {
        const aValue = getSortValue(a, sortConfig.key);
        const bValue = getSortValue(b, sortConfig.key);

        if (typeof aValue === 'string') {
          return sortConfig.direction === 'ascending'
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        } else {
          return sortConfig.direction === 'ascending'
            ? aValue - bValue
            : bValue - aValue;
        }
      });
    }
    return sortableData;
  }, [performanceData, sortConfig]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    setCurrentPage(1); // Återställ till första sidan
  };

  return (
    <div className={styles.performanceContainer}>
      <h1>Performance</h1>
      <div className={styles.filterForm}>
        <div>
          <label>Select source</label>
          <Select
            name="source"
            value={
              selectedSource
                ? { value: selectedSource.id, label: selectedSource.name }
                : null
            }
            onChange={handleSourceChange}
            options={sources.map((source) => ({
              value: source.id,
              label: source.name,
            }))}
            styles={customSelectStyles}
            placeholder="Select source"
            isClearable
          />
        </div>
        <div>
          <label>Brand</label>
          <Select
            name="brandId"
            value={
              brands.find((option) => option.value === selectedBrandId) || null
            }
            onChange={handleBrandChange}
            options={brands}
            isClearable
            placeholder="Select a brand"
            styles={customSelectStyles}
          />
        </div>
        <div className={styles.filterButtons}>
          <div>
            <DateSelect dateRange={appliedDateRange} setDateRange={setAppliedDateRange} boxShadow={true} />
            <div className={styles.breakdownContainer}>
              <button
                onClick={() => setShowBreakdownMenu(!showBreakdownMenu)}
                ref={breakdownButtonRef}
              >
                <span className="material-symbols-outlined">filter_list</span>
                Breakdown
              </button>
              {showBreakdownMenu && (
                <div ref={breakdownMenuRef} className={styles.breakdownMenu}>
                  <div>
                    <label>
                      <select
                        value={breakdownOption}
                        onChange={(e) => setBreakdownOption(e.target.value)}
                      >
                        <option value="programs">Programs</option>
                        <option value="regions">Regions</option>
                      </select>
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        checked={tempPerDay}
                        onChange={(e) => setTempPerDay(e.target.checked)}
                      />
                      Per Day
                    </label>
                  </div>
                </div>
              )}
            </div>
            <button onClick={handleApplyFilters} className={styles.applyFiltersBtn}>
              Apply Filters
            </button>
            <label className={`${styles.showTotals} ${!totalActive && styles.inactiveTotal}`}>
              <input type="checkbox"
                checked={totalActive}
                onChange={(e) => setTotalActive(e.target.checked)}
              />
              <p>
                {totalActive ? "Hide" : "View"} total
              </p>
            </label>
          </div>

          <div>
            <button className={styles.addEditBtn} onClick={() => setShowPerformanceModal(true)}>Edit Performance</button>
            <button className={styles.addEditBtn} onClick={() => setShowSyncModal(true)}>
              <span class="material-symbols-outlined">
                sync
              </span>
              Force sync</button>

          </div>

        </div>
      </div>

      <div className={styles.tableContainer}>
        <table className={styles.tableFullWidth}>
          <thead>
            <tr>
              {perDay && (
                <th>
                  <div className={styles.sortableHeader} onClick={() => handleSort('date')} style={{ cursor: 'pointer' }}>
                    Date
                    <div className={styles.sortIcons}>
                      <span
                        className={`material-symbols-outlined ${sortConfig.key === 'date' && sortConfig.direction === 'ascending'
                          ? styles.activeSort
                          : ''
                          }`}
                      >
                        arrow_drop_up
                      </span>
                      <span
                        className={`material-symbols-outlined ${sortConfig.key === 'date' && sortConfig.direction === 'descending'
                          ? styles.activeSort
                          : ''
                          }`}
                      >
                        arrow_drop_down
                      </span>
                    </div>
                  </div>
                </th>
              )}
              <th>
                <div className={styles.sortableHeader} onClick={() => handleSort('program')} style={{ cursor: 'pointer' }}>
                  Program
                  <div className={styles.sortIcons}>
                    <span
                      className={`material-symbols-outlined ${sortConfig.key === 'program' && sortConfig.direction === 'ascending'
                        ? styles.activeSort
                        : ''
                        }`}
                    >
                      arrow_drop_up
                    </span>
                    <span
                      className={`material-symbols-outlined ${sortConfig.key === 'program' && sortConfig.direction === 'descending'
                        ? styles.activeSort
                        : ''
                        }`}
                    >
                      arrow_drop_down
                    </span>
                  </div>
                </div>
              </th>
              <th>
                <div className={styles.sortableHeader} onClick={() => handleSort('regions')} style={{ cursor: 'pointer' }}>
                  Regions
                  <div className={styles.sortIcons}>
                    <span
                      className={`material-symbols-outlined ${sortConfig.key === 'regions' && sortConfig.direction === 'ascending'
                        ? styles.activeSort
                        : ''
                        }`}
                    >
                      arrow_drop_up
                    </span>
                    <span
                      className={`material-symbols-outlined ${sortConfig.key === 'regions' && sortConfig.direction === 'descending'
                        ? styles.activeSort
                        : ''
                        }`}
                    >
                      arrow_drop_down
                    </span>
                  </div>
                </div>
              </th>
              <th>
                <div className={styles.sortableHeader} onClick={() => handleSort('source')} style={{ cursor: 'pointer' }}>
                  Source
                  <div className={styles.sortIcons}>
                    <span
                      className={`material-symbols-outlined ${sortConfig.key === 'source' && sortConfig.direction === 'ascending'
                        ? styles.activeSort
                        : ''
                        }`}
                    >
                      arrow_drop_up
                    </span>
                    <span
                      className={`material-symbols-outlined ${sortConfig.key === 'source' && sortConfig.direction === 'descending'
                        ? styles.activeSort
                        : ''
                        }`}
                    >
                      arrow_drop_down
                    </span>
                  </div>
                </div>
              </th>
              <th>
                <div className={styles.sortableHeader} onClick={() => handleSort('clicks')} style={{ cursor: 'pointer' }}>
                  Clicks
                  <div className={styles.sortIcons}>
                    <span
                      className={`material-symbols-outlined ${sortConfig.key === 'clicks' && sortConfig.direction === 'ascending'
                        ? styles.activeSort
                        : ''
                        }`}
                    >
                      arrow_drop_up
                    </span>
                    <span
                      className={`material-symbols-outlined ${sortConfig.key === 'clicks' && sortConfig.direction === 'descending'
                        ? styles.activeSort
                        : ''
                        }`}
                    >
                      arrow_drop_down
                    </span>
                  </div>
                </div>
              </th>
              <th>
                <div className={styles.sortableHeader} onClick={() => handleSort('signups')} style={{ cursor: 'pointer' }}>
                  Signups
                  <div className={styles.sortIcons}>
                    <span
                      className={`material-symbols-outlined ${sortConfig.key === 'signups' && sortConfig.direction === 'ascending'
                        ? styles.activeSort
                        : ''
                        }`}
                    >
                      arrow_drop_up
                    </span>
                    <span
                      className={`material-symbols-outlined ${sortConfig.key === 'signups' && sortConfig.direction === 'descending'
                        ? styles.activeSort
                        : ''
                        }`}
                    >
                      arrow_drop_down
                    </span>
                  </div>
                </div>
              </th>
              <th>
                <div className={styles.sortableHeader} onClick={() => handleSort('FTD')} style={{ cursor: 'pointer' }}>
                  FTD
                  <div className={styles.sortIcons}>
                    <span
                      className={`material-symbols-outlined ${sortConfig.key === 'FTD' && sortConfig.direction === 'ascending'
                        ? styles.activeSort
                        : ''
                        }`}
                    >
                      arrow_drop_up
                    </span>
                    <span
                      className={`material-symbols-outlined ${sortConfig.key === 'FTD' && sortConfig.direction === 'descending'
                        ? styles.activeSort
                        : ''
                        }`}
                    >
                      arrow_drop_down
                    </span>
                  </div>
                </div>
              </th>
              <th>
                <div className={styles.sortableHeader} onClick={() => handleSort('QFTD')} style={{ cursor: 'pointer' }}>
                  QFTD
                  <div className={styles.sortIcons}>
                    <span
                      className={`material-symbols-outlined ${sortConfig.key === 'QFTD' && sortConfig.direction === 'ascending'
                        ? styles.activeSort
                        : ''
                        }`}
                    >
                      arrow_drop_up
                    </span>
                    <span
                      className={`material-symbols-outlined ${sortConfig.key === 'QFTD' && sortConfig.direction === 'descending'
                        ? styles.activeSort
                        : ''
                        }`}
                    >
                      arrow_drop_down
                    </span>
                  </div>
                </div>
              </th>
              <th className={styles.CPATh}>
                <div className={`${styles.sortableHeader} ${styles.CPAheader}`}>
                  <p>
                    Savvy CPA
                  </p>
                  <div className={styles.CPAbreakdown}>
                    <div onClick={() => handleSort('CPAhold')} style={{ cursor: 'pointer' }}>
                      <Tooltip title={<p style={{ margin: '0px' }}>Hold CPA</p>} arrow>
                        <div className={styles.holdCPAspan}>
                          <span class="material-symbols-outlined">
                            schedule
                          </span>
                        </div>
                      </Tooltip>
                      <div className={styles.sortIcons}>
                        <span
                          className={`material-symbols-outlined ${sortConfig.key === 'CPAhold' && sortConfig.direction === 'ascending' ? styles.activeSort : ''
                            }`}
                        >
                          arrow_drop_up
                        </span>
                        <span
                          className={`material-symbols-outlined ${sortConfig.key === 'CPAhold' && sortConfig.direction === 'descending' ? styles.activeSort : ''
                            }`}
                        >
                          arrow_drop_down
                        </span>
                      </div>
                    </div>
                    <div onClick={() => handleSort('CPAdeny')} style={{ cursor: 'pointer' }}>
                      <Tooltip title={<p style={{ margin: '0px' }}>Denied CPA</p>} arrow>
                        <div className={styles.deniedCPAspan}>
                          <span class="material-symbols-outlined">
                            cancel
                          </span>
                        </div>
                      </Tooltip>
                      <div className={styles.sortIcons}>
                        <span
                          className={`material-symbols-outlined ${sortConfig.key === 'CPAdeny' && sortConfig.direction === 'ascending' ? styles.activeSort : ''
                            }`}
                        >
                          arrow_drop_up
                        </span>
                        <span
                          className={`material-symbols-outlined ${sortConfig.key === 'CPAdeny' && sortConfig.direction === 'descending' ? styles.activeSort : ''
                            }`}
                        >
                          arrow_drop_down
                        </span>
                      </div>
                    </div>
                    <div onClick={() => handleSort('CPAverified')} style={{ cursor: 'pointer' }}>
                      <Tooltip title={<p style={{ margin: '0px' }}>Approved CPA</p>} arrow>
                        <div className={styles.verifiedCPAspan}>
                          <span class="material-symbols-outlined">
                            check_circle
                          </span>
                        </div>
                      </Tooltip>
                      <div className={styles.sortIcons}>
                        <span
                          className={`material-symbols-outlined ${sortConfig.key === 'CPAverified' && sortConfig.direction === 'ascending' ? styles.activeSort : ''
                            }`}
                        >
                          arrow_drop_up
                        </span>
                        <span
                          className={`material-symbols-outlined ${sortConfig.key === 'CPAverified' && sortConfig.direction === 'descending' ? styles.activeSort : ''
                            }`}
                        >
                          arrow_drop_down
                        </span>
                      </div></div>
                  </div>
                </div>
              </th>
              <th className={styles.CPATh}>
                <div className={`${styles.sortableHeader} ${styles.CPAheader}`}>
                  <p>
                    Customer CPA
                  </p>
                  <div className={styles.CPAbreakdown}>
                    <div onClick={() => handleSort('CPAhold')} style={{ cursor: 'pointer' }}>
                      <Tooltip title={<p style={{ margin: '0px' }}>Hold CPA</p>} arrow>
                        <div className={styles.holdCPAspan}>
                          <span class="material-symbols-outlined">
                            schedule
                          </span>
                        </div>
                      </Tooltip>
                      <div className={styles.sortIcons}>
                        <span
                          className={`material-symbols-outlined ${sortConfig.key === 'CPAhold' && sortConfig.direction === 'ascending' ? styles.activeSort : ''
                            }`}
                        >
                          arrow_drop_up
                        </span>
                        <span
                          className={`material-symbols-outlined ${sortConfig.key === 'CPAhold' && sortConfig.direction === 'descending' ? styles.activeSort : ''
                            }`}
                        >
                          arrow_drop_down
                        </span>
                      </div>
                    </div>
                    <div onClick={() => handleSort('CPAdeny')} style={{ cursor: 'pointer' }}>
                      <Tooltip title={<p style={{ margin: '0px' }}>Denied CPA</p>} arrow>
                        <div className={styles.deniedCPAspan}>
                          <span class="material-symbols-outlined">
                            cancel
                          </span>
                        </div>
                      </Tooltip>
                      <div className={styles.sortIcons}>
                        <span
                          className={`material-symbols-outlined ${sortConfig.key === 'CPAdeny' && sortConfig.direction === 'ascending' ? styles.activeSort : ''
                            }`}
                        >
                          arrow_drop_up
                        </span>
                        <span
                          className={`material-symbols-outlined ${sortConfig.key === 'CPAdeny' && sortConfig.direction === 'descending' ? styles.activeSort : ''
                            }`}
                        >
                          arrow_drop_down
                        </span>
                      </div>
                    </div>
                    <div onClick={() => handleSort('CPAverified')} style={{ cursor: 'pointer' }}>
                      <Tooltip title={<p style={{ margin: '0px' }}>Approved CPA</p>} arrow>
                        <div className={styles.verifiedCPAspan}>
                          <span class="material-symbols-outlined">
                            check_circle
                          </span>
                        </div>
                      </Tooltip>
                      <div className={styles.sortIcons}>
                        <span
                          className={`material-symbols-outlined ${sortConfig.key === 'CPAverified' && sortConfig.direction === 'ascending' ? styles.activeSort : ''
                            }`}
                        >
                          arrow_drop_up
                        </span>
                        <span
                          className={`material-symbols-outlined ${sortConfig.key === 'CPAverified' && sortConfig.direction === 'descending' ? styles.activeSort : ''
                            }`}
                        >
                          arrow_drop_down
                        </span>
                      </div></div>
                  </div>
                </div>
              </th>
              <th>
                <div className={styles.sortableHeader} onClick={() => handleSort('RevBeforeCommission')}
                  style={{ cursor: 'pointer' }}>
                  Savvy Revshare
                  <div className={styles.sortIcons}>
                    <span
                      className={`material-symbols-outlined ${sortConfig.key === 'RevBeforeCommission' &&
                        sortConfig.direction === 'ascending'
                        ? styles.activeSort
                        : ''
                        }`}
                    >
                      arrow_drop_up
                    </span>
                    <span
                      className={`material-symbols-outlined ${sortConfig.key === 'RevBeforeCommission' &&
                        sortConfig.direction === 'descending'
                        ? styles.activeSort
                        : ''
                        }`}
                    >
                      arrow_drop_down
                    </span>
                  </div>
                </div>
              </th>
              <th>
                <div className={styles.sortableHeader} onClick={() => handleSort('RevIncome')} style={{ cursor: 'pointer' }}>
                  Customer Revshare
                  <div className={styles.sortIcons}>
                    <span
                      className={`material-symbols-outlined ${sortConfig.key === 'RevIncome' && sortConfig.direction === 'ascending'
                        ? styles.activeSort
                        : ''
                        }`}
                    >
                      arrow_drop_up
                    </span>
                    <span
                      className={`material-symbols-outlined ${sortConfig.key === 'RevIncome' && sortConfig.direction === 'descending'
                        ? styles.activeSort
                        : ''
                        }`}
                    >
                      arrow_drop_down
                    </span>
                  </div>
                </div>
              </th>
              <th>
                <div className={styles.sortableHeader} onClick={() => handleSort('Total')} style={{ cursor: 'pointer' }}>
                  Savvy Earnings
                  <div className={styles.sortIcons}>
                    <span
                      className={`material-symbols-outlined ${sortConfig.key === 'Total' && sortConfig.direction === 'ascending'
                        ? styles.activeSort
                        : ''
                        }`}
                    >
                      arrow_drop_up
                    </span>
                    <span
                      className={`material-symbols-outlined ${sortConfig.key === 'Total' && sortConfig.direction === 'descending'
                        ? styles.activeSort
                        : ''
                        }`}
                    >
                      arrow_drop_down
                    </span>
                  </div>
                </div>
              </th>
              {perDay && breakdownOption === "regions" && <th>
                <div className={styles.sortableHeader} onClick={() => handleSort('locked')} style={{ cursor: 'pointer' }}>
                  Locked
                  <div className={styles.sortIcons}>
                    <span
                      className={`material-symbols-outlined ${sortConfig.key === 'locked' && sortConfig.direction === 'ascending'
                        ? styles.activeSort
                        : ''
                        }`}
                    >
                      arrow_drop_up
                    </span>
                    <span
                      className={`material-symbols-outlined ${sortConfig.key === 'locked' && sortConfig.direction === 'descending'
                        ? styles.activeSort
                        : ''
                        }`}
                    >
                      arrow_drop_down
                    </span>
                  </div>
                </div>
              </th>}
            </tr>
          </thead>
          <tbody>
            {totalActive &&
              <tr className={styles.totalsRow}>
                <td><strong>Total</strong></td>
                <td></td>
                {perDay && <td></td>
                }
                <td></td>
                <td>{totals.clicks}</td>
                <td>{totals.signups}</td>
                <td>{totals.FTD}</td>
                <td>{totals.QFTD}</td>
                <td className={styles.CPACelltotal}>
                  <div className={styles.CPAbreakdown}>
                    <div>{totals.CPAIncomeBeforeCommissionHold} €</div>
                    <div>{totals.CPAIncomeBeforeCommissionDenied} €</div>
                    <div>{totals.CPAIncomeBeforeCommission} €</div>
                  </div>
                </td>
                <td className={styles.CPACelltotal}>
                  <div className={styles.CPAbreakdown}>
                    <div>{totals.CPAIncomeHold} €</div>
                    <div>{totals.CPAIncomeDenied} €</div>
                    <div>{totals.CPAIncome} €</div>
                  </div>
                </td>
                <td>{totals.revShareIncomeBeforeCommission} €</td>
                <td>{totals.revShareIncome} €</td>
                <td>{totals.totalEarnings} €</td>
                {perDay && breakdownOption === "regions" && <td></td>}
              </tr>
            }
            {currentData.length > 0 ? (
              currentData.map((data, index) => {
                const regions =
                  data.type === 'program' ? data.regions : [data.region];

                return (
                  <tr key={index}>
                    {perDay && <td>{data.stats.date}</td>}
                    <td>
                      {data.brandName} {data.verticals?.join(', ') || '-'}
                    </td>
                    <td>
                      <div className={styles.flags}>
                        {regions.length > 0 ? (
                          regions.map((region, idx) => (
                            <Tooltip key={idx} title={region.name} arrow>
                              <Flag code={region.shortCode} height="16" />
                            </Tooltip>
                          ))
                        ) : (
                          '-'
                        )}
                      </div>
                    </td>
                    <td>{data.sourceName}</td>
                    <td>{getStatDisplayValue(data.stats.clicks)}</td>
                    <td>{getStatDisplayValue(data.stats.signups)}</td>
                    <td>{getStatDisplayValue(data.stats.FTD)}</td>
                    <td>{getStatDisplayValue(data.stats.QFTD)}</td>
                    <td className={styles.CPACell}>
                      <div className={styles.CPAbreakdown}>
                        <div>                      {getStatDisplayValue(
                          data.stats.CPAIncomeBeforeCommissionHold != null
                            ? `${data.stats.CPAIncomeBeforeCommissionHold} €`
                            : null
                        )}</div>
                        <div>                      {getStatDisplayValue(
                          data.stats.CPAIncomeBeforeCommissionDenied != null
                            ? `${data.stats.CPAIncomeBeforeCommissionDenied} €`
                            : null
                        )}</div>
                        <div>                      {getStatDisplayValue(
                          data.stats.CPAIncomeBeforeCommission != null
                            ? `${data.stats.CPAIncomeBeforeCommission} €`
                            : null
                        )}</div>
                      </div>
                    </td>
                    <td className={styles.CPACell}>
                      <div className={styles.CPAbreakdown}>
                        <div>                      {getStatDisplayValue(
                          data.stats.CPAIncomeHold != null
                            ? `${data.stats.CPAIncomeHold} €`
                            : null
                        )}</div>
                        <div>                      {getStatDisplayValue(
                          data.stats.CPAIncomeDenied != null
                            ? `${data.stats.CPAIncomeDenied} €`
                            : null
                        )}</div>
                        <div>                      {getStatDisplayValue(
                          data.stats.CPAIncome != null
                            ? `${data.stats.CPAIncome} €`
                            : null
                        )}</div>
                      </div>
                    </td>
                    <td>
                      {getStatDisplayValue(
                        data.stats.revShareIncomeBeforeCommission != null
                          ? `${data.stats.revShareIncomeBeforeCommission.toFixed(2)} €`
                          : null
                      )}
                    </td>
                    <td>
                      {getStatDisplayValue(
                        data.stats.revShareIncome != null
                          ? `${data.stats.revShareIncome.toFixed(2)} €`
                          : null
                      )}
                    </td>
                    <td>
                      {getStatDisplayValue(
                        data.stats.totalEarnings != null
                          ? `${data.stats.totalEarnings.toFixed(2)} €`
                          : null
                      )}
                    </td>
                    {perDay && breakdownOption === "regions" && <td>{data.stats.locked === 1 &&
                      <span class="material-symbols-outlined">
                        lock
                      </span>
                    }</td>}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="12" className="text-center">
                  <strong>No performance data available</strong>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <PaginationComponent
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />

      {showPerformanceModal &&
        <AddEditPerformanceModal
          isOpen={showPerformanceModal}
          onRequestClose={() => setShowPerformanceModal(false)}
          runFetch={() => handleApplyFilters()}
        />
      }
      {showSyncModal &&
        <ProgramTaskModal
          isOpen={showSyncModal}
          onRequestClose={() => setShowSyncModal(false)}
          runFetch={() => handleApplyFilters()}
        />
      }
    </div>

  );
};

export default Performance;
