import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL + "/api/performance/";


const fetchPerformance = async (
    sourceId,
    brandId,
    dateRange,
    breakdownOption,
    perDay
  ) => {
    try {
      const params = {
        sourceId,
        brandId,
        breakdownOption,
        perDay,
      };
  
      // Lägg till start- och slutdatum om dateRange finns
      if (dateRange) {
        params.startDate = dateRange.startDate.toISOString(); // Formatera till YYYY-MM-DD
        params.endDate = dateRange.endDate.toISOString();
      }
  
      const response = await axios.get(`${API_URL}performance`, {
        params,
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching performance data:", error);
      throw error;
    }
  };
  
  const fetchAllPerformance = async (
    sourceId,
    brandId,
    startDate,
    endDate,
    breakdownOption,
    perDay
  ) => {
    try {
      const params = {
        sourceId,
        brandId,
        startDate,
    endDate,
        breakdownOption,
        perDay,
      };
  
      const response = await axios.get(`${API_URL}all-performance`, {
        params,
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching performance data:", error);
      throw error;
    }
  };

  const getStatsAndDeal = async (date,sourceProgramId, sourceProgramRegionId) => {
    try {
      const response = await axios.get(
        `${API_URL}stats-and-deal`, // Anpassa till rätt endpoint
        {
          params: {
            date,
            sourceProgramId,
            sourceProgramRegionId,
          },
          withCredentials: true,
        }
      );
  
      // Returnera datan från backend
      return response.data;
    } catch (error) {
      console.error("Error fetching stats and deal:", error);
      throw error;
    }
  };

  const saveStats = async (payload) => {
    try {
      const response = await axios.post(
        `${API_URL}stats`, // Anpassa till rätt endpoint
        payload,
        {
          withCredentials: true,
        }
      );
  
      // Returnera responsen från backend
      return response.data;
    } catch (error) {
      console.error("Error saving stats:", error);
      throw error;
    }
  };
  const forceSync = async (programs, startDate, endDate) => {
    try {
      
        const response = await axios.post(
            `${API_URL}force-sync`,
            { programs, startDate, endDate },
            { withCredentials: true }
        );
        return response.data;
    } catch (error) {
        console.error("Error during force-sync:", error);
        throw error;
    }
  };  
    
  const getMonthlyTotals = async (month) => {
    try {
      const response = await axios.get(
        `${API_URL}monthly-totals`,
        {
          params: { month }, // Pass the month as a query parameter
          withCredentials: true,
        }
      );
      return response.data; // Expecting totals summary data
    } catch (error) {
      console.error("Error fetching monthly totals:", error);
      throw error;
    }
  };
  
  const getSourceSummary = async (month, sourceId) => {
    try {
      const response = await axios.get(
        `${API_URL}source-summary`,
        {
          params: { month, sourceId }, // Pass the month and sourceId as query parameters
          withCredentials: true,
        }
      );
      return response.data; // Expecting source-specific summary data
    } catch (error) {
      console.error("Error fetching source summary:", error);
      throw error;
    }
  };
  
  const getProgramSummary = async (month, programId) => {
    try {
      const response = await axios.get(
        `${API_URL}program-summary`,
        {
          params: { month, programId }, // Pass the month and programId as query parameters
          withCredentials: true,
        }
      );
      return response.data; // Expecting program-specific summary data
    } catch (error) {
      console.error("Error fetching program summary:", error);
      throw error;
    }
  };
  
  const toggleVerification = async (month, type, id, verified ) => {
    try {
      const response = await axios.post(
        `${API_URL}toggle-verification`,
        { month, type, id, verified }, // Pass the month and programId as query parameters
        {
          withCredentials: true,
        }
      );
      return response.data; // Expecting program-specific summary data
    } catch (error) {
      console.error("Error setting verification:", error);
      throw error;
    }
  };

  const previewPDF = async (month, sourceId, configs) => {
    try {
      const response = await axios.post(
        `${API_URL}preview-pdf`,
        { month, sourceId, configs},
        {
          responseType: "blob",
          withCredentials: true,
      }
      );
      return response.data;
    } catch (error) {
      console.error("Error previewing pdf:", error);
      throw error;
    }
  };

  const viewPDF = async (month, sourceId) => {
    try {
      const response = await axios.get(
        `${API_URL}viewPDF-pdf`,
        {
          params: { month, sourceId },
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error viewing pdf:", error);
      throw error;
    }
  };

  const sendPDF = async (month, sourceId, configs) => {
    try {
      const response = await axios.post(
        `${API_URL}generate-pdf`,
        { month, sourceId, configs},
        {
          withCredentials: true,
        }
      );
      return response.data; // Expecting program-specific summary data
    } catch (error) {
      console.error("Error sending pdf:", error);
      throw error;
    }
  };
  
  const getUserPdfs = async () => {
    try {
      const response = await axios.get(
        `${API_URL}all-user-pdfs`,
        {
          withCredentials: true,
        }
      );
      return response.data; // Expecting program-specific summary data
    } catch (error) {
      console.error("Error sending pdfs:", error);
      throw error;
    }
  };

  const getPayoutOverview = async () => {
    try {
      const response = await axios.get(
        `${API_URL}payout-overview`,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error sending payout overview:", error);
      throw error;
    }
  };
  


  export default {
    getPayoutOverview,
    getUserPdfs,
    viewPDF,
    sendPDF,
    previewPDF,
    toggleVerification,
    getMonthlyTotals,
    getSourceSummary,
    getProgramSummary,
    forceSync,
    saveStats,
    getStatsAndDeal,
    fetchAllPerformance,
    fetchPerformance
  };