import React, { useState } from 'react';
import './DateRangeDefault.scss';
import './DateRangeTheme.scss';
import { DateRange } from 'react-date-range';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';
import styles from './DateSelect.module.scss';
import { enGB } from 'date-fns/locale';
import Modal from 'react-modal';

const DateSelect = ({ dateRange, setDateRange, boxShadow, mobileVersionSmall }) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [tempDateRange, setTempDateRange] = useState(dateRange); // Temporärt datumintervall för modal

  // Hantera "Clear"-knappen
  const handleClear = () => {
    setTempDateRange(null);
  };

  // Hantera "Apply"-knappen
  const handleApply = () => {
    setDateRange(tempDateRange);
    setShowDatePicker(false);
  };

  // Hantera "Cancel"-knappen
  const handleCancel = () => {
    setTempDateRange(dateRange); // Återställ till föregående datumintervall
    setShowDatePicker(false);
  };

  // Hantera datuminmatning i modalen
  const handleSelectDateRange = (ranges) => {
    setTempDateRange({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
    });
  };

  // Formatera visning av datumintervall
  const formatDateDisplay = (startDate, endDate) => {
    if (!startDate || !endDate) return null;
    const formatDate = (date) =>
      `${String(date.getDate()).padStart(2, '0')} ${date.toLocaleString('en-US', { month: 'short' })}, ${date.getFullYear()}`;
    return `${formatDate(startDate)} - ${formatDate(endDate)}`;
  };

  return (
    <div className={`${styles.dateFilter} ${mobileVersionSmall && styles.mobileVersionSmall}`}>
      <div className={`${styles.dateContainer} ${boxShadow && styles.withBoxShadow}`}>
        <button
          onClick={() => setShowDatePicker(true)}
          className={`${styles.datePickerBtn} ${dateRange ? styles.hasDates : ''}`}
        >
          <DateRangeOutlinedIcon className={styles.outlined} />
          <DateRangeIcon className={styles.filled} />
        </button>
        {dateRange && (
          <>
            <p className={styles.displayedDate}>
              {formatDateDisplay(dateRange.startDate, dateRange.endDate)}
            </p>
            <button
              onClick={() => setDateRange(null)}
              className={styles.removeDateBtn}
            >
              <span className="material-symbols-outlined">close</span>
            </button>
          </>
        )}
      </div>
      <Modal
        isOpen={showDatePicker}
        onRequestClose={handleCancel}
        contentLabel="Date Range Picker"
        className={styles.modalSide}
        overlayClassName={styles.overlay}
      >
        <div className={styles.modalHeader}>
          <h3>Date</h3>
          <button className={styles.modalClose} onClick={handleCancel}>
            <span className="material-symbols-outlined">close</span>
          </button>
        </div>
        <div className={styles.modalContent}>
          <div className={styles.dateRangeContainer}>
          <DateRange
            ranges={[
              {
                startDate: tempDateRange?.startDate || new Date(),
                endDate: tempDateRange?.endDate || new Date(),
                key: 'selection',
              },
            ]}
            onChange={handleSelectDateRange}
            maxDate={new Date()}
            moveRangeOnFirstSelection={false}
            editableDateInputs
            locale={enGB}
          />
          </div>
<div className={styles.dateButtons}>
  <button
    onClick={() => {
      const today = new Date();
      const firstDayOfWeek = new Date(today);
      firstDayOfWeek.setDate(today.getDate() - today.getDay() + 1); // Måndag
      const lastDayOfWeek = new Date(firstDayOfWeek);
      lastDayOfWeek.setDate(Math.min(firstDayOfWeek.getDate() + 6, today.getDate())); // Senast idag
      setTempDateRange({ startDate: firstDayOfWeek, endDate: lastDayOfWeek });
    }}
  >
    This Week
  </button>
  <button
    onClick={() => {
      const today = new Date();
      const firstDayOfLastWeek = new Date(today);
      firstDayOfLastWeek.setDate(today.getDate() - today.getDay() - 6); // Förra måndagen
      const lastDayOfLastWeek = new Date(firstDayOfLastWeek);
      lastDayOfLastWeek.setDate(firstDayOfLastWeek.getDate() + 6); // Förra söndagen
      setTempDateRange({ startDate: firstDayOfLastWeek, endDate: lastDayOfLastWeek });
    }}
  >
    Last Week
  </button>
  <button
    onClick={() => {
      const today = new Date();
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1); // Första dagen denna månad
      const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0); // Sista dagen denna månad
      const endDate = lastDayOfMonth > today ? today : lastDayOfMonth; // Begränsa till idag
      setTempDateRange({ startDate: firstDayOfMonth, endDate });
    }}
  >
    This Month
  </button>
  <button
    onClick={() => {
      const today = new Date();
      const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1); // Första dagen förra månaden
      const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0); // Sista dagen förra månaden
      setTempDateRange({ startDate: firstDayOfLastMonth, endDate: lastDayOfLastMonth });
    }}
  >
    Last Month
  </button>
</div>


        </div>
        <div className={styles.modalActions}>
          <button className={styles.clearButton} onClick={handleClear}>
            Clear
          </button>
          <div className={styles.rightSide}>
            <button className={styles.applyButton} onClick={handleApply}>
              Apply
            </button>
            <button className={styles.cancelButton} onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DateSelect;
