import React, { useState, useEffect } from 'react';
import authService from '../../services/authService';
import styles from '../shared/FormStyles.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import LoadingButtonDots from '../../components/LoadingButtonDots';

const Register = () => {
    useEffect(() => {
        document.title = 'Savvy - Register';
    }, []);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [existingStore, setExistingStore] = useState(false);
    const [loading, setLoading] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!termsAccepted) {
            setMessage('You must accept the Terms of Service');
            return;
        }

        if (password !== confirmPassword) {
            setMessage('Passwords do not match');
            return;
        }

        const passwordStrength = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&^#_.~`|<>{}()[\]:;"',+\-/])[A-Za-z\d@$!%*?&^#_.~`|<>{}()[\]:;"',+\-/]{7,}$/;
        if (!passwordStrength.test(password)) {
            alert('Password must be at least 7 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character');
            setMessage('Password');
            return;
        }

        setLoading(true);

        try {
            const response = await authService.register(password, email);
            navigate('/');

        } catch (error) {
            if (error.response) {
                setMessage(error.response.data.message);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        if (message === "Email is already connected to a user") {
            setMessage('');
        }
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (message.includes('Password')) {
            setMessage('');
        }
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        if (message === "Passwords do not match") {
            setMessage('');
        }
    };

    const handleTermsChange = (e) => {
        setTermsAccepted(e.target.checked);
        if (message === 'You must accept the Terms of Service') {
            setMessage('');
        }
    };


    return (
        <div className={styles.container}>
            <h1>Savvy</h1>
            <form onSubmit={handleSubmit} className={styles.form}>
                <h2>Register</h2>
                <div className={styles.inputs}>
                    <div className={styles.emailInput}>
                        <input
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                            required
                            className={`${styles.input} ${message === "Email is already connected to a user" ? styles.errorInput : ''}`}
                            placeholder="Email"
                        />
                        {message === "Email is already connected to a user" && <p className={styles.errorMessage}>{message}</p>}
                    </div>
                    <div className={styles.passwordInput}>
                        <input
                            type="password"
                            value={password}
                            onChange={handlePasswordChange}
                            required
                            className={`${styles.input} ${message.includes('Password') ? styles.errorInput : ''}`}
                            placeholder="Password"
                        />
                    </div>
                    <div className={styles.passwordInput}>
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            required
                            className={`${styles.input} ${message === "Passwords do not match" ? styles.errorInput : ''}`}
                            placeholder="Repeat Password"
                        />
                        {message === "Passwords do not match" && <p className={styles.errorMessage}>{message}</p>}
                    </div>
                    <div className={styles.termsCheckbox}>
                        <input
                            type="checkbox"
                            checked={termsAccepted}
                            onChange={handleTermsChange}
                            required
                        />
                        <label>
                            I accept the <Link to="/terms-of-service" target="_blank">Terms of Service</Link>
                        </label>
                        {message === 'You must accept the Terms of Service' && <p className={styles.errorMessage}>{message}</p>}
                    </div>
                    <button type="submit" className={styles.button} disabled={loading}>
                        {loading ? <LoadingButtonDots /> : 'Register account'}
                    </button>
                </div>
                <div className={styles.registerLoginLink}>
                    <p>Already have an account?</p><Link to="../login">Login</Link>
                </div>
            </form>
        </div>
    );
};

export default Register;
