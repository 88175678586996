import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL + "/api/";

// Fetch program requests
const fetchProgramRequests = async () => {
  try {
    const response = await axios.get(`${API_URL}programs/program-requests`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching program requests:", error);
    throw error;
  }
};

// Accept program request
const acceptProgramRequest = async (sourceProgramRegionId, updatedData) => {
  try {
    const response = await axios.post(
      `${API_URL}programs/accept-program-request/`,
      {
        sourceProgramRegionId,
        updatedData,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error accepting program request:", error);
    throw error;
  }
};

// Deny program request
const denyProgramRequest = async (sourceProgramRegionId, denyMessage) => {
  try {
    const response = await axios.post(
      `${API_URL}programs/deny-program-request/`,
      { sourceProgramRegionId, denyMessage },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error denying program request:", error);
    throw error;
  }
};

const createNewProgram = async (programData) => {
  try {
    const response = await axios.post(
      `${API_URL}programs/create`,
      programData,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating program:", error);
    throw error;
  }
};

const createBrand = async (brand) => {
  try {
    const response = await axios.post(
      `${API_URL}programs/brand-create`,
      brand,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching regions:", error);
    throw error;
  }
};

const createVertical = async (vertical) => {
  try {
    const response = await axios.post(
      `${API_URL}programs/vertical-create`,
      { vertical },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching regions:", error);
    throw error;
  }
};

const fetchPrograms = async (page = 1, limit = 10, filters = {}) => {
  try {
    const response = await axios.get(API_URL + "programs/admin/programs", {
      params: {
        page, // Skickar korrekt sidnummer
        limit, // Skickar korrekt antal per sida
        searchTerm: filters.searchTerm, // Skickar sökterm
        status: filters.status, // Filtrerar efter status
        brandId: filters.brandId, // Filtrerar efter brandId
        verticalId: filters.verticalId, // Filtrerar efter verticalId
        regionId: filters.regionId, // Filtrerar efter regionId
      },
      withCredentials: true, // Om autentisering krävs
    });
    return response.data; // Returnera responsens data
  } catch (error) {
    console.error("Error fetching programs:", error);
    throw error; // Kasta felet för att hantera högre upp
  }
};

const fetchSources = async (page = 1, limit = 10, filters = {}) => {
  try {
    const response = await axios.get(`${API_URL}sources/admin`, {
      params: {
        page,
        limit,
        searchTerm: filters.searchTerm || "", // Include search term
        verticalId: filters.verticalId || null, // Vertical filter
        regionId: filters.regionId || null, // Region filter
      },
      withCredentials: true, // Send cookies if authentication is required
    });
    return response.data; // Return the fetched data
  } catch (error) {
    console.error("Error fetching sources:", error);
    throw error; // Throw error to be handled by the caller
  }
};

const fetchUsersWithPagination = async (page = 1, limit = 10, filters = {}) => {
  try {
    const response = await axios.get(`${API_URL}user/admin`, {
      params: {
        page,
        limit,
        role: filters.role || null,
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

const fetchProgramsOptions = async () => {
  try {
    const response = await axios.get(`${API_URL}programs/program-options`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching program requests:", error);
    throw error;
  }
};

const fetchSourcePrograms = async (currentPage, itemsPerPage, filters) => {
  try {
    const response = await axios.get(`${API_URL}programs/source-programs`, {
      params: {
        currentPage,
        itemsPerPage,
        sourceId: filters.sourceId,
        programId: filters.programId,
        brandId: filters.brandId,
        verticalId: filters.verticalId,
        regionId: filters.regionId,
        dealType: filters.dealType,
        includeOld: filters.includeOld,
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching program requests:", error);
    throw error;
  }
};

const changeAffiliateLink = async (sourceProgramRegionId, newAffiliateLink) => {
  try {
    const response = await axios.post(
      `${API_URL}programs/affiliate-link-update`,
      { sourceProgramRegionId, newAffiliateLink },
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating affiliate link:", error);
    throw error;
  }
};

const updateDeal = async (sourceProgramRegionId, dealData) => {
  try {
    const response = await axios.post(
      `${API_URL}programs/update-deal`,
      {
        sourceProgramRegionId,
        ...dealData,
      },
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating deal:", error);
    throw error;
  }
};

const addNewRegionDeal = async (programId, dealData) => {
  try {
    const requestBody = {
      programId,
      regionId: dealData.regionId,
      baseline: dealData.baseline,
      CPAbeforeCommission: dealData.CPAbeforeCommission,
      cpa: dealData.cpa,
      revShareBeforeCommission: dealData.revShareBeforeCommission,
      revshare: dealData.revshare,
      description: dealData.description,
    };

    const response = await axios.post(
      `${API_URL}programs/add-region-deal`,
      requestBody,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error adding new region deal:", error);
    throw error;
  }
};

// programService.js
const updateStandardDeal = async (sourceProgramRegionId, dealData) => {
  try {
    const response = await axios.put(
      `${API_URL}programs/${sourceProgramRegionId}/update-standard-deal`,
      dealData,
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating region deal:", error);
    throw error;
  }
};

const getAdminDashboardEarnings = async (period, source, program) => {
  try {
    const response = await axios.get(`${API_URL}user/dashboard/admin-earnings`, {
      params: { period, source, program },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching dashboard earnings data:", error);
    throw error;
  }
};

const homePageSummary = async () => {
  try {
    const response = await axios.get(`${API_URL}user/homepage-summary`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching homepage summary:", error);
    throw error;
  }
};


const getDashboardHotSources = async () => {
  try {
    const response = await axios.get(`${API_URL}sources/hot-sources`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching hot sources:", error);
    throw error;
  }
};

const submitInformation = async (payload) => {
  try {
    const response = await axios.post(
      `${API_URL}programs/update-information`,
      {
        payload
      },
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating information:", error);
    throw error;
  }
};

const getCronTimer = async () => {
  try {
    const response = await axios.get(
      `${API_URL}settings/get-cron-timer`,
      { withCredentials: true }
    );
    return response.data; // Förväntar att data innehåller cron-timern
  } catch (error) {
    console.error("Error fetching cron timer:", error);
    throw error;
  }
};

// Uppdatera cron-timer
const updateCronTimer = async (cronTimer) => {
  try {
    const response = await axios.post(
      `${API_URL}settings/update-cron-timer`,
      { cronTimer }, // Skickar cron-timern som payload
      { withCredentials: true }
    );
    return response.data; // Förväntar en bekräftelse eller uppdaterad timer som respons
  } catch (error) {
    console.error("Error updating cron timer:", error);
    throw error;
  }
};

const setManualNotification = async (title, message, userId, isGlobal) => {
  try {
    const response = await axios.post(
      `${API_URL}user/manual-notification`,
      { title, message, userId, isGlobal }, // Skickar cron-timern som payload
      { withCredentials: true }
    );
    return response.data; // Förväntar en bekräftelse eller uppdaterad timer som respons
  } catch (error) {
    console.error("Error updating cron timer:", error);
    throw error;
  }
};



export default {
  setManualNotification,
  getCronTimer,
  updateCronTimer,
  submitInformation,
  getDashboardHotSources,
  homePageSummary,
  getAdminDashboardEarnings,
  updateStandardDeal,
  addNewRegionDeal,
  updateDeal,
  changeAffiliateLink,
  fetchSourcePrograms,
  fetchProgramsOptions,
  fetchUsersWithPagination,
  fetchSources,
  fetchPrograms,
  createBrand,
  createVertical,
  createNewProgram,
  fetchProgramRequests,
  acceptProgramRequest,
  denyProgramRequest,
};
