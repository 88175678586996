import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL + "/api/programs/";

const getSourcePrograms = async (sourceId, filters) => {
  try {
    const params = { sourceId, ...filters };
    const response = await axios.get(API_URL, {
      params,
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching source programs:", error);
    throw error;
  }
};

const getProgramsForSource = async (sourceId) => {
  try {
    const response = await axios.get(API_URL + "getProgramsForSource/" + sourceId, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching source programs:", error);
    throw error;
  }
};

const joinProgram = async (sourceId, programRegionId) => {
  try {
    const response = await axios.post(
      `${API_URL}join-program-request/`,
      { sourceId, programRegionId },
      {
        withCredentials: true,
      }
    );
    return response;
  } catch (error) {
    console.error("Error requesting program join:", error);
    throw error;
  }
};

const fetchAllRegions = async () => {
  try {
    const response = await axios.get(`${API_URL}all-regions`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching regions:", error);
    throw error;
  }
};

const fetchAllBrands = async () => {
  try {
    const response = await axios.get(`${API_URL}all-brands`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching brands:", error);
    throw error;
  }
};

const fetchAllVerticals = async () => {
  try {
    const response = await axios.get(`${API_URL}all-verticals`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching verticals:", error);
    throw error;
  }
};

const getProgramRegionStandardDealSources = async (programRegionId) => {
  try {
    const response = await axios.get(
      `${API_URL}${programRegionId}/standard-sources`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching program region sources:", error);
    throw error;
  }
};

const toggleProgramDownTime = async (programId) => {
  try {
    const response = await axios.post(
      `${API_URL}/toggle-downtime-program`,
      { programId },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error toggling downtime program:", error);
    throw error;
  }
};


const toggleProgramRegionDownTime = async (programRegionId) => {
  try {
    const response = await axios.post(
      `${API_URL}/toggle-downtime-programregion`,
      { programRegionId },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error toggling downtime region:", error);
    throw error;
  }
};


export default {
  toggleProgramRegionDownTime,
  toggleProgramDownTime,
  getProgramRegionStandardDealSources,
  fetchAllBrands,
  fetchAllVerticals,
  fetchAllRegions,
  joinProgram,
  getSourcePrograms,
  getProgramsForSource
};
