import React, { useState, useEffect } from "react";
import authService from "../../../services/authService";
import programService from "../../../services/programService";
import styles from "../Settings.module.scss";
import Select from "react-select";

const PaymentSettings = () => {
    const [regions, setRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState(null); // Entire region object
    const [settings, setSettings] = useState({
        companyName: "",
        city: "",
        zip: "",
        address: "",
        CorporateRegNo: "",
        VAT: "",
    });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({}); // Track validation errors

    const fetchRegions = async () => {
        try {
            const regionsData = await programService.fetchAllRegions();
            const formattedRegions = regionsData.map(region => ({
                value: region.id,
                label: region.name,
                name: region.name,
            }));
            setRegions(formattedRegions);
        } catch (error) {
            console.error("Error fetching regions:", error);
        }
    };

    // Fetch existing payment settings from the API
    const fetchSettings = async () => {
        try {
            const response = await authService.getUserPaymentSettings();
            if (response) {
                setSettings({
                    companyName: response.companyName || "",
                    city: response.city || "",
                    zip: response.zip || "",
                    address: response.address || "",
                    CorporateRegNo: response.CorporateRegNo || "",
                    VAT: response.VAT || "",
                });

                // Match the corresponding region
                const region = regions.find(r => r.value === response.regionId);
                if (region) setSelectedRegion(region);
            }
        } catch (error) {
            console.error("Error fetching settings:", error);
        }
    };

    // Load regions when the component mounts
    useEffect(() => {
        const initializeRegions = async () => {
            setLoading(true);
            await fetchRegions();
        };

        initializeRegions();
    }, []);

    // Fetch settings only after regions are loaded
    useEffect(() => {
        if (regions.length > 0) {
            fetchSettings();
        }
        setLoading(false);

    }, [regions]);

    // Handle input changes for text inputs
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSettings((prevSettings) => ({ ...prevSettings, [name]: value }));
    };

    // Handle region selection changes
    const handleRegionChange = (selectedOption) => {
        setSelectedRegion(selectedOption);
    };

    // Validate inputs before submitting
    const validateInputs = () => {
        const newErrors = {};
        if (!settings.companyName.trim()) newErrors.companyName = "Company name is required.";
        if (!selectedRegion) newErrors.regionId = "Please select a country.";
        if (!settings.city.trim()) newErrors.city = "City is required.";
        if (!settings.zip.trim()) newErrors.zip = "Zip code is required.";
        if (!settings.address.trim()) newErrors.address = "Address is required.";
        if (!settings.CorporateRegNo.trim()) newErrors.CorporateRegNo = "Corporate registration number is required.";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateInputs()) return; // Stop if validation fails

        try {
            setLoading(true);
            await authService.setUserPaymentSettings({
                ...settings,
                regionId: selectedRegion?.value,
            });
            alert("Settings updated successfully!");
        } catch (error) {
            console.error("Error updating settings:", error);
            alert("Failed to update settings. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <form onSubmit={handleSubmit} className={styles.paymentSettingsForm}>
                    <h2>Payment Details</h2>
                    <div className={styles.formGroup}>
                        <label>
                            <span className={styles.obligatory}>Company Name</span>
                            <input
                                className={styles.input}
                                type="text"
                                name="companyName"
                                value={settings.companyName}
                                onChange={handleInputChange}
                                placeholder="Enter company name"
                            />
                            {errors.companyName && <p className={styles.error}>{errors.companyName}</p>}
                        </label>
                        <label>
                            <span className={styles.obligatory}>Country</span>
                            <Select
                                name="regionId"
                                value={selectedRegion || null}
                                onChange={handleRegionChange}
                                options={regions}
                                placeholder="Select country"
                                classNamePrefix="customSelect"
                            />
                            {errors.regionId && <p className={styles.error}>{errors.regionId}</p>}
                        </label>
                        <label>
                            <span className={styles.obligatory}>City</span>
                            <input
                                className={styles.input}
                                type="text"
                                name="city"
                                value={settings.city}
                                onChange={handleInputChange}
                                placeholder="Enter city"
                            />
                            {errors.city && <p className={styles.error}>{errors.city}</p>}
                        </label>
                        <label>
                            <span className={styles.obligatory}>Zip</span>
                            <input
                                className={styles.input}
                                type="text"
                                name="zip"
                                value={settings.zip}
                                onChange={handleInputChange}
                                placeholder="Enter zip code"
                            />
                            {errors.zip && <p className={styles.error}>{errors.zip}</p>}
                        </label>
                        <label>
                            <span className={styles.obligatory}>Address</span>
                            <input
                                className={styles.input}
                                type="text"
                                name="address"
                                value={settings.address}
                                onChange={handleInputChange}
                                placeholder="Enter address"
                            />
                            {errors.address && <p className={styles.error}>{errors.address}</p>}
                        </label>
                        <label>
                            <span className={styles.obligatory}>Corporate Reg No</span>
                            <input
                                className={styles.input}
                                type="text"
                                name="CorporateRegNo"
                                value={settings.CorporateRegNo}
                                onChange={handleInputChange}
                                placeholder="Enter corporate registration number"
                            />
                            {errors.CorporateRegNo && <p className={styles.error}>{errors.CorporateRegNo}</p>}
                        </label>
                        <label>
                            <span>VAT</span>
                            <input
                                className={styles.input}
                                type="text"
                                name="VAT"
                                value={settings.VAT}
                                onChange={handleInputChange}
                                placeholder="Enter VAT number"
                            />
                            {errors.VAT && <p className={styles.error}>{errors.VAT}</p>}
                        </label>
                    </div>
                    <div className={styles.formActions}>
                        <button type="submit" disabled={loading} className={styles.saveBtn}>
                            Save Settings
                        </button>
                    </div>
                </form>
            )}
        </>
    );
};

export default PaymentSettings;
