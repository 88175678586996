import React, { useState, useEffect, useContext } from 'react';
import styles from './CountryChart.module.scss';
import Flag from 'react-world-flags';
import Select from 'react-select';
import { Skeleton, Tooltip } from '@mui/material';
import { UserResponsiveContext } from '../../components/UserResponsiveContext';

const CountryChart = ({ data, metrics, selectedMetric, onMetricChange, sources, selectedSource, onSourceChange, loading }) => {
  const { screenSize } = useContext(UserResponsiveContext);
  const isSmallScreen = screenSize <= 560;
  const sortedData = [...data].sort((a, b) => b.value - a.value);
  const maxValue = Math.max(...data.map((item) => item.value));
  const selectedSourceOption = sources.find((source) => source.id === selectedSource) || null;

  return (
    <>
      <div className={styles.filterContainer}>
        <div className={styles.nav}>
          {metrics.map((metric) => (
            <button
              key={metric.key}
              className={`${styles.navButton} ${selectedMetric === metric.key ? styles.active : ''}`}
              onClick={() => onMetricChange(metric.key)}
            >
              {metric.label}
            </button>
          ))}
        </div>
        <div className={styles.selectSourceContainer}>
          <Select
            name="source"
            value={
              selectedSourceOption
                ? { value: selectedSourceOption.id, label: selectedSourceOption.name }
                : null
            }
            onChange={(option) => onSourceChange(option ? option.value : 'all')}
            options={sources.map((source) => ({
              value: source.id,
              label: source.name,
            }))}
            classNamePrefix="customSelect"
            placeholder="All Sources"
            isClearable
            className={styles.sourceSelect}
          />
        </div>
      </div>
      {/* ...Filter/knappar... */}
      {loading ? (
        <div className={styles.countryChart}>
          {Array.from({ length: 4 }).map((_, index) => (
            <div key={index} className={styles.countryRow}>
              <Skeleton height={40} width="100%" />
              <Skeleton height={40} width="100%" />
            </div>
          ))}
        </div>
      ) : sortedData.length > 0 ? (
        // Rendera FadeScrollContainer först när vi har data
        <div className={styles.countryChart}>
          {sortedData.map((item, index) => (
            <div key={index} className={styles.countryRow}>
              <div className={styles.label}>
                {isSmallScreen ? (
                  // Visa Tooltip vid små skärmar
                  <Tooltip title={item.name || 'Unknown Country'} arrow placement='top'>
                    <span>
                      <Flag
                        code={item.shortCode}
                        className={styles.flag}
                        alt={item.name}
                      />
                    </span>
                  </Tooltip>
                ) : (
                  // Utan Tooltip vid större skärmar
                  <Flag
                    code={item.shortCode}
                    className={styles.flag}
                    alt={item.name}
                  />
                )}
                <span className={styles.countryName}>{item.name}</span>
              </div>
              <div className={styles.barContainer}>
                <div
                  className={styles.bar}
                  style={{
                    width: `${(item.value / maxValue) * 100 || 0}%`, // Hanterar division med 0
                  }}
                />
              </div>
              <div className={styles.value}>
                {item.value ? item.value : "0"} {selectedMetric === "cpaIncome" && " €"}
              </div>
            </div>
          ))}
        </div>
      ) : (
        // Tom fallback
        <p className={styles.noData}>No country metrics available</p>
      )}
    </>
  );

};

export default CountryChart;
