import React, { useEffect, useState } from "react";
import authService from "../../../services/authService";
import styles from "../Settings.module.scss";

const TelegramSettings = () => {
    const [chatNum, setChatNum] = useState(0);

    const fetchChatNum = async () => {
        try {
            const chatCount = await authService.fetchTelegramChatCount();
            setChatNum(chatCount)
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchChatNum();
    }, [])

    const removeTelegramChats = async () => {
        try {
            const userConfirmed = window.confirm("Are you sure you want to remove all Telegram chats?");

            if (userConfirmed) {
                await authService.deleteAllTelegramChats();
                alert("Telegram chats successfully removed.");
                fetchChatNum();

            } else {
                console.log("User canceled the operation.");
            }
        } catch (error) {
            console.error("Error while removing Telegram chats:", error);
        }
    };


    return (
        <div className={styles.telegramContainer}>
            <h2>Telegram Notification</h2>
            {chatNum > 0 &&
                <div className={styles.formGroup}>
                    <div className={styles.telegramInfo}>
                        <p>You have currently telegram notifications setup in <strong>{chatNum}</strong> chat{chatNum > 1 && "s"}.</p>
                        <button className={styles.deleteBtn} onClick={removeTelegramChats}>Remove from all</button>
                    </div>
                </div>
            }
            <div className={styles.formGroup}>
                <>
                    <h4>How to join:</h4>
                    <ol>
                        <li>Start a chat with the <a hre={"https://t.me/savvy_notif_bot"} target="_blank">savvy_notif_bot</a>, or add it to your chat group</li>
                        <li>Login using <span>/login youremail@mail.com</span></li>
                        <li>Configure which notifications you want in the chat using <span>/notifications</span></li>
                    </ol>
                </>
            </div>
        </div>
    )
}

export default TelegramSettings;