import React, { createContext, useState, useEffect } from 'react';

// Skapa Context
export const UserResponsiveContext = createContext();

// Provider-komponenten
export const UserResponsiveProvider = ({ children }) => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <UserResponsiveContext.Provider value={{ screenSize }}>
      {children}
    </UserResponsiveContext.Provider>
  );
};
