import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import styles from './AddEditPerformanceModal.module.scss';
import programService from '../../../services/programService';
import performanceService from '../../../services/performanceService';
import sourceService from '../../../services/sourceService';

const AddEditPerformanceModal = ({ isOpen, onRequestClose, runFetch }) => {
    const [sources, setSources] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [stats, setStats] = useState(null); // För redigering av befintlig statistik
    const [deal, setDeal] = useState(null); // För att lagra deal-information
    const [previousQFTD, setPreviousQFTD] = useState(0); // För att lagra tidigare QFTD-värde

    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedSource, setSelectedSource] = useState(null);
    const [selectedProgram, setSelectedProgram] = useState(null);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [notificationActive, setNotificationActive] = useState(false);
    const [locked, setLocked] = useState(true);

    const [formValues, setFormValues] = useState({
        clicks: '',
        signups: '',
        FTD: '',
        QFTD: '',
        CPAIncomeBeforeCommission: '',
        CPAIncome: '',
        revShareIncomeBeforeCommission: '',
        revShareIncome: '',
    });

    useEffect(() => {
        if (isOpen) fetchSources();
    }, [isOpen]);

    const fetchSources = async () => {
        try {
            const data = await sourceService.getAllSources();
            setSources(data || []);
        } catch (error) {
            console.error('Error fetching sources:', error);
        }
    };

    const fetchProgramsForSource = async (sourceId) => {
        try {
            const programsData = await programService.getProgramsForSource(sourceId);
            setPrograms(programsData);
        } catch (error) {
            console.error('Error fetching programs for source:', error);
            setPrograms([]);
        }
    };

    const fetchStats = async (date, sourceProgramId, sourceProgramRegionId) => {
        try {
            const statsData = await performanceService.getStatsAndDeal(date, sourceProgramId, sourceProgramRegionId);
            setStats(statsData.stats || null);
            setDeal(statsData.deal || null);

            if (statsData.stats) {
                setFormValues({
                    clicks: statsData.stats.clicks || '',
                    signups: statsData.stats.signups || '',
                    FTD: statsData.stats.FTD || '',
                    QFTD: statsData.stats.QFTD || '',
                    CPAIncomeBeforeCommission: statsData.stats.CPAIncomeBeforeCommission || '',
                    CPAIncome: statsData.stats.CPAIncome || '',
                    revShareIncomeBeforeCommission: statsData.stats.revShareIncomeBeforeCommission || '',
                    revShareIncome: statsData.stats.revShareIncome || '',
                });
                setPreviousQFTD(statsData.stats.QFTD || 0);
                setLocked(statsData.stats.locked);
            } else {
                setPreviousQFTD(0);
                setLocked(true);
            }
            setNotificationActive(false);

        } catch (error) {
            console.error('Error fetching stats:', error);
        }
    };

    const handleSourceChange = (source) => {
        setSelectedSource(source);
        setSelectedProgram(null);
        setSelectedRegion(null);
        setStats(null);
        setDeal(null);
        setPrograms([]);
        setNotificationActive(false);
        setLocked(true);
        setPreviousQFTD(0);
        if (source) {
            fetchProgramsForSource(source.value);
        }
        setFormValues({
            clicks: '',
            signups: '',
            FTD: '',
            QFTD: '',
            CPAIncomeBeforeCommission: '',
            CPAIncome: '',
            revShareIncomeBeforeCommission: '',
            revShareIncome: '',
        });
    };

    const handleProgramChange = (program) => {
        setSelectedProgram(program);
        setSelectedRegion(null);
        setStats(null);
        setDeal(null);
        setNotificationActive(false);
        setLocked(true);
        setPreviousQFTD(0);
        setFormValues({
            clicks: '',
            signups: '',
            FTD: '',
            QFTD: '',
            CPAIncomeBeforeCommission: '',
            CPAIncome: '',
            revShareIncomeBeforeCommission: '',
            revShareIncome: '',
        });
        if (program?.isAggregated && selectedDate && selectedSource) {
            fetchStats(selectedDate, program.value, null);
        }
    };

    const handleRegionChange = (region) => {
        setSelectedRegion(region);
        setStats(null);
        setDeal(null);
        setNotificationActive(false);
        setLocked(true);
        setPreviousQFTD(0);
        setFormValues({
            clicks: '',
            signups: '',
            FTD: '',
            QFTD: '',
            CPAIncomeBeforeCommission: '',
            CPAIncome: '',
            revShareIncomeBeforeCommission: '',
            revShareIncome: '',
        });
        if (selectedDate && selectedSource && selectedProgram && region) {
            fetchStats(selectedDate, selectedProgram.value, region.value);
        }
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setSelectedSource(null);
        setSelectedProgram(null);
        setSelectedRegion(null);
        setPrograms([]);
        setStats(null);
        setDeal(null);
        setNotificationActive(false);
        setLocked(true);
        setPreviousQFTD(0);
        setFormValues({
            clicks: '',
            signups: '',
            FTD: '',
            QFTD: '',
            CPAIncomeBeforeCommission: '',
            CPAIncome: '',
            revShareIncomeBeforeCommission: '',
            revShareIncome: '',
        });
    };

    const canToggleNotification = () => {
        const currentQFTD = parseInt(formValues.QFTD || 0, 10);
        if (currentQFTD <= 0) return false;

        if (stats) {
            const oldQFTD = parseInt(previousQFTD || 0, 10);
            return currentQFTD > oldQFTD;
        } else {
            return true;
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prev) => ({ ...prev, [name]: value }));

        if (name === 'QFTD') {
            if (!canToggleNotification()) {
                setNotificationActive(false);
            }
        }
    };

    const handleSubmit = async () => {
        try {
            const cleanedFormValues = {};
            for (const [key, value] of Object.entries(formValues)) {
                cleanedFormValues[key] = value === '' ? 0 : parseFloat(value) || 0;
            }

            const payload = {
                date: selectedDate,
                sourceProgramId: selectedProgram.value,
                sourceProgramRegionId: selectedProgram.isAggregated ? null : selectedRegion?.value || null,
                ...cleanedFormValues,
                sendNotification: notificationActive,
                locked,
            };

            if (stats?.id) {
                payload.statsId = stats.id;
            }

            await performanceService.saveStats(payload);
            runFetch();
            onRequestClose();
        } catch (error) {
            console.error('Error saving stats:', error);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className={styles.modal}
            overlayClassName={styles.modalOverlay}
        >
            <div className={styles.modalHeader}>
                <h3>{stats ? 'Edit Performance' : 'Add Performance'}</h3>
                <button className={styles.modalClose} onClick={() => onRequestClose()}>
                    <span class="material-symbols-outlined">
                        close
                    </span>
                </button>
            </div>
            <div className={styles.modalContent}>
                <div className={styles.inputGroup}>
                    <div className={styles.formField}>
                        <label htmlFor="date">Select Date</label>
                        <DatePicker
                            selected={selectedDate}
                            onChange={handleDateChange}
                            dateFormat="yyyy-MM-dd"
                            className={styles.input}
                            placeholderText="Choose a date"
                        />
                    </div>

                    {selectedDate && (
                        <div className={styles.formField}>
                            <label htmlFor="source">Select Source</label>
                            {sources.length > 0 ? (
                                <Select
                                    id="source"
                                    options={sources.map((source) => ({
                                        value: source.id,
                                        label: source.name,
                                    }))}
                                    onChange={handleSourceChange}
                                    placeholder="Choose a source"
                                    isClearable
                                />
                            ) : (
                                <p>No sources available</p>
                            )}
                        </div>
                    )}

                    {selectedSource && (
                        <div className={styles.formField}>
                            <label htmlFor="program">Select Program</label>
                            {programs.length > 0 ? (
                                <Select
                                    id="program"
                                    options={programs.map((program) => ({
                                        value: program.programId,
                                        label: program.name,
                                        regions: program.regions,
                                        isAggregated: program.isAggregated,
                                    }))}
                                    onChange={handleProgramChange}
                                    placeholder="Choose a program"
                                    isClearable
                                />
                            ) : (
                                <p>No programs available for the selected source</p>
                            )}
                        </div>
                    )}

                    {selectedProgram && (
                        <>
                            {!selectedProgram.isAggregated ? (
                                <div className={styles.formField}>
                                    <label htmlFor="region">Select Region</label>
                                    {selectedProgram.regions.length > 0 ? (
                                        <Select
                                            id="region"
                                            options={selectedProgram.regions.map((region) => ({
                                                value: region.id,
                                                label: region.name,
                                            }))}
                                            onChange={handleRegionChange}
                                            placeholder="Choose a region"
                                            isClearable
                                        />
                                    ) : (
                                        <p>No regions available for the selected program</p>
                                    )}
                                </div>
                            ) : (
                                <p>The data is aggregated, so region selection is skipped.</p>
                            )}
                        </>
                    )}

                    {(selectedProgram && (selectedProgram.isAggregated || selectedRegion)) && (
                        <>
                            <h4>Performance Data (Write Totals)</h4>
                            {Object.keys(formValues).map((key) => {
                                const labelMap = {
                                    CPAIncome: "Customer CPA",
                                    revShareIncome: "Customer Revshare",
                                    CPAIncomeBeforeCommission: "Savvy CPA",
                                    revShareIncomeBeforeCommission: "Savvy Revshare",
                                };

                                return (
                                    <div key={key} className={styles.formField}>
                                        <label htmlFor={key}>
                                            {labelMap[key] || key}
                                        </label>
                                        <div className={styles.inputContainer}>
                                            <input
                                                id={key}
                                                name={key}
                                                type="number"
                                                value={formValues[key]}
                                                onChange={handleInputChange}
                                                className={styles.input}
                                            />
                                            {key === "QFTD" &&
                                                <button
                                                    type="button"
                                                    className={`${styles.notificationBtn} ${notificationActive && styles.active}`}
                                                    onClick={() => setNotificationActive(!notificationActive)}
                                                    disabled={!canToggleNotification()}
                                                >
                                                    <span className="material-symbols-outlined">
                                                        notifications
                                                    </span>
                                                </button>
                                            }
                                        </div>
                                        {deal && labelMap[key] && (
                                            <span className={styles.dealInfo}>
                                                {key === "CPAIncome" && `Customer CPA during date: ${deal.CPA}€`}
                                                {key === "revShareIncome" && `Customer Revshare during date: ${deal.revshare}%`}
                                                {key === "CPAIncomeBeforeCommission" && `Savvy CPA before commission: ${deal.CPAbeforeCommission}€`}
                                                {key === "revShareIncomeBeforeCommission" && `Savvy Revshare before commission: ${deal.revShareBeforeCommission}%`}
                                            </span>
                                        )}
                                    </div>
                                );
                            })}
                            <div className={styles.locked}>
                                <label>
                                    <input type="checkbox"
                                        checked={locked}
                                        onChange={() => setLocked(!locked)}
                                    />
                                    Locked
                                </label>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className={styles.modalActions}>
                <button
                    onClick={handleSubmit}
                    className={styles.submitButton}
                    disabled={!selectedDate || !selectedSource || !selectedProgram || (!selectedProgram.isAggregated && !selectedRegion)}
                >
                    Submit
                </button>
                <button onClick={onRequestClose} className={styles.cancelButton}>
                    Cancel
                </button>
            </div>
        </Modal>
    );
};

export default AddEditPerformanceModal;
