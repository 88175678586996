import React, { useState, useEffect } from 'react';
import authService from '../../services/authService';
import styles from './Notification.module.scss';

const Notification = () => {
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const notifications = await authService.getNotifications();
                setNotifications(notifications);
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        };

        fetchNotifications();
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>Notifications</h1>
                <p className={styles.infoText}>(last 2 weeks)</p>
            </div>
            <div className={styles.notificationsGrid}>
                {notifications.length === 0 ? (
                    <p className={styles.noNotifications}>You have no notifications.</p>
                ) : (
                    notifications.map((notification) => (
                        <div className={styles.notificationCard} key={notification.id}>
                            <h3>{notification.title}</h3>
                            <p>{notification.message}</p>
                            <small className={styles.timestamp}>
                                {new Date(notification.createdAt).toLocaleString()}
                            </small>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default Notification;
