import React, { useState, useEffect, useRef } from "react";
import { Pagination } from "@mui/material";
import styles from "./PaginationComponent.module.scss";

const PaginationComponent = ({
  itemsPerPage,
  setItemsPerPage,
  totalPages,
  currentPage,
  handlePageChange,
}) => {
  const [isOpen, setIsOpen] = useState(false); // Styr om dropdown är öppen

  const buttonRef = useRef(null);
  const menuRef = useRef(null);

  // För att hantera responsiv pagination
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 480);
  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 480);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  /**
   * Klick på knappen
   */
  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  /**
   * Välj antal rader per sida
   */
  const handleDropdownSelect = (value) => {
    setItemsPerPage(value);
    setIsOpen(false);
    handlePageChange(null, 1);
  };

  /**
   * 1) Blockera scroll i bakgrunden om öppen
   * 2) Återställ när stängd
   */
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    // Rensa ifall komponent unmountas
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  /**
   * Klick utanför => stäng dropdown
   */
  useEffect(() => {
    if (!isOpen) return;

    const handleOutsideClick = (e) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(e.target) && // klick är inte i menyn
        buttonRef.current &&
        !buttonRef.current.contains(e.target)  // klick är inte på knappen
      ) {
        setIsOpen(false);
      }
    };

    window.addEventListener("mousedown", handleOutsideClick);
    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <div className={styles.paginationSettings}>
      {/* Knappen */}
      <div className={styles.dropdownWrapper}>
        <button
          ref={buttonRef}
          className={styles.dropdownToggle}
          onClick={toggleDropdown}
          disabled={totalPages === 10 && currentPage === 1}
        >
          {itemsPerPage}
          <span class="material-symbols-outlined">
arrow_drop_down
</span>
        </button>

        {/* Overlay i fullskärm, visas bara när isOpen = true */}
        <div className={`${styles.overlay} ${isOpen ? styles.open : ""}`} />

        {/* Dropdownen (alltid i DOM) */}
        <div
          ref={menuRef}
          className={`${styles.dropdownMenu} ${isOpen ? styles.open : ""}`}
        >
          {[10, 20].map((value) => (
            <div
              key={value}
              className={`${styles.dropdownItem} ${
                itemsPerPage === value ? styles.selected : ""
              }`}
              onClick={() => handleDropdownSelect(value)}
            >
              {value}
            </div>
          ))}
        </div>
      </div>

      {/* Material-UI Pagination */}
      <Pagination
  count={totalPages}
  page={currentPage}
  onChange={handlePageChange}
  siblingCount={isSmallScreen ? 0 : 1}
  sx={{
    "& .MuiPaginationItem-root": {
      // Grundstil för alla pagination-knappar
      color: "var(--text-color)",
      margin: "0 2px",               // Lite spacing mellan knapparna
      minWidth: "30px",             // Exempel på fast bredd
      height: "30px",               // Matcha dropdown
      transition: "background-color 0.3s ease-in-out",
      "&:hover:not(.Mui-disabled)": {
        backgroundColor: "var(--background-grey-3)",
      },
      "&.Mui-disabled": {
        backgroundColor: "transparent",
        color: "var(--text-color)", // du kan sätta grå text
        cursor: "default",
      },
    },

    // När sidan är vald (selected)
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "var(--primary-color)",
      color: "white !important",
      "&:hover": {
        backgroundColor: "var(--primary-color)",
      },
    },

    // Aktiv "Next"/"Prev" knapp
    "& .MuiPaginationItem-ellipsis": {
      // Om du vill styla "..." knapparna
    },
    "& .MuiPaginationItem-previousNext": {
      // Om du vill styla "<<" eller ">>" om du använder dem
    },
  }}
/>

    </div>
  );
};

export default PaginationComponent;
