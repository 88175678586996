import React, { useState, useEffect, useRef } from 'react';
import { io } from 'socket.io-client';
import authService from '../services/authService';
import styles from './NotificationButton.module.scss';
import { NavLink } from 'react-router-dom';

const URL = process.env.REACT_APP_BASE_URL;

const NotificationButton = ({ setShowConfetti }) => {
    const [unreadCount, setUnreadCount] = useState(0);
    const [notifications, setNotifications] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const recentNotifications = await authService.getRecentNotifications();
                setNotifications(recentNotifications);

                const unread = await authService.getUnreadCount();
                setUnreadCount(unread);

                const hasUnreadConfetti = await authService.unreadConfetti();
                if (hasUnreadConfetti) {
                    setShowConfetti(true);
                    await authService.markConfettiAsRead();
                }
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        };

        fetchNotifications();

        const userId = localStorage.getItem('userId');
        if (!userId) {
            console.error('No userId found');
            return;
        }

        const socket = io(URL, {
            auth: { userId },
        });

        socket.on('new_notification', async (notification) => {
            setUnreadCount((prev) => prev + 1);
            setNotifications((prev) => [notification, ...prev].slice(0, 5));

            if (notification.showConfetti) {
                setShowConfetti(true);
                await authService.markConfettiAsRead();
            }
        });

        return () => socket.disconnect();
    }, []);

    const handleDropdownToggle = async () => {
        if (dropdownOpen) {
            setDropdownOpen(false);
        } else {
            setDropdownOpen(true);
            try {
                await authService.markNotificationsAsRead();
                setUnreadCount(0);
            } catch (error) {
                console.error('Error marking notifications as read:', error);
            }
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div>
            <button onClick={handleDropdownToggle} className={styles.dropdownBtn} onMouseDown={(e) => e.stopPropagation()}>
                <span className="material-symbols-outlined">notifications</span>
                {unreadCount > 0 && <span className={styles.badge}>{unreadCount}</span>}
            </button>
            {dropdownOpen && (
                <ul ref={dropdownRef} className={`${styles.dropdown} ${dropdownOpen ? styles.dropdownOpen : ''}`}>
                    {!notifications || notifications.length === 0 ? (
                        <li className={styles.dropdownItem}>No new notifications</li>
                    ) : (
                        notifications.map((notification) => (
                            <li className={styles.dropdownItem} key={notification.id}>
                                <h4>{notification.title}</h4>
                                <p>{notification.message}</p>
                            </li>
                        ))
                    )}
                    <NavLink className={styles.historyLink} to="/notifications" onClick={() => setDropdownOpen(false)}>
                        <li className={`${styles.dropdownItem} ${styles.history}`}>
                            History
                        </li>
                    </NavLink>
                </ul>
            )}
        </div>
    );
};

export default NotificationButton;
