import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import authService from '../../services/authService';
import styles from './FormStyles.module.scss';
import LoadingButtonDots from '../../components/LoadingButtonDots'; // Importera LoadingButtonDots komponent

const Login = () => {
    useEffect(() => {
        document.title = 'Savvy - Login';
    }, []);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false); // Lägg till en state för att hantera laddningsindikatorn

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true); // Sätt loading till true när inloggningen påbörjas
        try {
            const response = await authService.login(email, password);
            if (response.role === "User") {
                if (response.userId) {
                    localStorage.setItem('userId', response.userId);
                }
                navigate('/');
            } else if (response.role === "Admin") {
                navigate('/admin');
            }
        } catch (error) {
            if (error.response) {
                setMessage(error.response.data.message);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        if (message === "No user found with this email") {
            setMessage(''); // Rensa felmeddelandet när användaren börjar skriva
        }
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (message === "Invalid password") {
            setMessage(''); // Rensa felmeddelandet när användaren börjar skriva
        }
    };


    return (
        <div className={styles.container}>
            <h1>Savvy</h1>
            <form onSubmit={handleSubmit} className={styles.form}>
                <h2>Sign in</h2>
                <div className={styles.inputs}>
                    <div className={styles.emailInput}>
                        <input
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                            required
                            className={`${styles.input} ${message === "No user found with this email" ? styles.errorInput : ''}`}
                            placeholder="Email"
                        />
                        {message === "No user found with this email" && <p className={styles.errorMessage}>{message}</p>}
                    </div>
                    <div className={styles.password}>
                        <div className={styles.passwordInput}>
                            <input
                                type="password"
                                value={password}
                                onChange={handlePasswordChange}
                                required
                                className={`${styles.input} ${message === "Invalid password" ? styles.errorInput : ''}`}
                                placeholder="Password"
                            />
                            {message === "Invalid password" && <p className={styles.errorMessage}>{message}</p>}
                        </div>
                        <Link to="../forgot-password" className={styles.forgot}>Forgot Password?</Link>
                    </div>
                    <button type="submit" className={styles.button} disabled={loading}>
                        {loading ? <LoadingButtonDots /> : 'Sign in'}
                    </button>
                </div>
                <div className={styles.registerLoginLink}>
                    <p>New user?</p><Link to="../register">Join now</Link>
                </div>
            </form>
            <p className={styles.termsOfservice}>By logging in, you agree to our <Link to="/terms-of-service" target="_blank">Terms of Service</Link></p>
        </div>
    );
};

export default Login;
