// src/components/CreateProgram.jsx

import React, { useState, useEffect } from 'react';
import { Form, Button, Table } from 'react-bootstrap';
import Select from 'react-select';
import Modal from 'react-modal';
import adminService from './../../services/adminService';
import programService from './../../services/programService';
import styles from './CreateProgram.module.scss';

Modal.setAppElement('#root');

const CreateProgram = () => {
  const [brands, setBrands] = useState([]);
  const [verticals, setVerticals] = useState([]);
  const [regions, setRegions] = useState([]);
  const [form, setForm] = useState({
    name: '',
    normalized_name: '',
    brandId: null,
    verticalIds: [],
    regionIds: [],
  });

  const [regionsWithDeals, setRegionsWithDeals] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isBulkEditModalOpen, setIsBulkEditModalOpen] = useState(false);
  const [bulkEditData, setBulkEditData] = useState({
    description: '',
    revshare: '',
    baseline: '',
    CPA: '',
    revShareBeforeCommission: '',
    CPAbeforeCommission: '',
  });

  const [modalData, setModalData] = useState({
    show: false,
    type: '',
    value: '',
    supportedDataPoints: [],
    image: null,
  });

  const [aggregatedData, setAggregatedData] = useState(false);

  const dataPointOptions = [
    { value: 'clicks', label: 'Clicks' },
    { value: 'signups', label: 'Signups' },
    { value: 'FTD', label: 'FTD' },
    { value: 'CPA', label: 'CPA' },
    { value: 'revShare', label: 'RevShare' },
  ];

  const customStyles = {
    control: base => ({
      ...base,
      height: 50,
      minHeight: 50
    })
  };

  useEffect(() => {
    fetchAllBrands();
    fetchAllVerticals();
    fetchAllRegions();
  }, []);

  useEffect(() => {
    const selectedIds = form.regionIds;
    const newRegionsWithDeals = selectedIds.map(id => {
      const existing = regionsWithDeals.find(rwd => rwd.id === id);
      if (existing) return existing;
      const region = regions.find(r => r.id === id);
      return {
        id: id,
        name: region ? region.name : '',
        description: '',
        revshare: 0.0,
        baseline: 0.0,
        CPA: 0.0,
        revShareBeforeCommission: 0.0,
        CPAbeforeCommission: 0.0,
      };
    });
    setRegionsWithDeals(newRegionsWithDeals);
  }, [form.regionIds, regions]);

  const fetchAllBrands = async () => {
    try {
      const data = await programService.fetchAllBrands();
      setBrands(data);
    } catch (error) {
      console.error('Error fetching brands:', error);
    }
  };

  const fetchAllVerticals = async () => {
    try {
      const data = await programService.fetchAllVerticals();
      setVerticals(data);
    } catch (error) {
      console.error('Error fetching verticals:', error);
    }
  };

  const fetchAllRegions = async () => {
    try {
      const data = await programService.fetchAllRegions();
      setRegions(data);
    } catch (error) {
      console.error('Error fetching regions:', error);
    }
  };

  const handleBrandChange = (option) => {
    const brandId = option ? option.value : null;
    setForm((prevForm) => ({ ...prevForm, brandId }));
  };

  const getSelectedBrand = () => {
    const selectedBrand = brands.find((brand) => brand.id === form.brandId);
    return selectedBrand ? { value: selectedBrand.id, label: selectedBrand.name } : null;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setForm({
      ...form,
      [name]: selectedOption ? (Array.isArray(selectedOption) ? selectedOption.map(opt => opt.value) : selectedOption.value) : (name === 'verticalIds' || name === 'regionIds' ? [] : null),
    });
  };

  const handleFileChange = (e) => {
    setModalData({
      ...modalData,
      image: e.target.files[0],
    });
  };

  const openModal = (type) => {
    setModalData({ ...modalData, show: true, type: type.toLowerCase(), value: '', supportedDataPoints: [] });
  };

  const closeModal = () => {
    setModalData({ ...modalData, show: false, value: '', supportedDataPoints: [] });
  };

  const handleModalSubmit = async () => {
    try {
      if (modalData.type === 'brand') {
        const formDataToSend = new FormData();
        formDataToSend.append('name', modalData.value);
        formDataToSend.append('supportedDataPoints', JSON.stringify(modalData.supportedDataPoints.map(point => point.value)));
        if (modalData.image) {
          formDataToSend.append('image', modalData.image);
        }
        await adminService.createBrand(formDataToSend);
        alert('Brand created successfully');
        fetchAllBrands();
      } else if (modalData.type === 'vertical') {
        await adminService.createVertical(modalData.value);
        alert('Vertical created successfully');
        fetchAllVerticals();
      }
      closeModal();
    } catch (error) {
      console.error('Error creating new item:', error);
    }
  };

  const handleAddBulkEdit = () => {
    if (selectedRows.length === 0) {
      alert('Please select at least one region to bulk edit.');
      return;
    }
    setIsBulkEditModalOpen(true);
  };

  const handleBulkEditSubmit = () => {
    const updatedRegions = regionsWithDeals.map(region => {
      if (selectedRows.includes(region.id)) {
        const updatedDeal = { ...region };
        Object.keys(bulkEditData).forEach(key => {
          if (bulkEditData[key] !== '') {
            updatedDeal[key] = key === 'description' ? bulkEditData[key] : parseFloat(bulkEditData[key]) || 0.0;
          }
        });
        return updatedDeal;
      }
      return region;
    });
    setRegionsWithDeals(updatedRegions);
    setIsBulkEditModalOpen(false);
    setBulkEditData({
      description: '',
      revshare: '',
      baseline: '',
      CPA: '',
      revShareBeforeCommission: '',
      CPAbeforeCommission: '',
    });
  };


  const handleRowSelection = (e, regionId) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, regionId]);
    } else {
      setSelectedRows(selectedRows.filter(id => id !== regionId));
    }
  };

  const handleAggregatedDataChange = (e) => {
    setAggregatedData(e.target.checked);
  };

  const handleSubmitProgram = async (e) => {
    e.preventDefault();
    try {
      const regionsData = regionsWithDeals.map(region => ({
        regionId: region.id,
        description: region.description,
        revshare: parseFloat(region.revshare) / 100,
        baseline: parseFloat(region.baseline) || 0.0,
        CPA: parseFloat(region.CPA) || 0.0,
        revShareBeforeCommission: parseFloat(region.revShareBeforeCommission) / 100,
        CPAbeforeCommission: parseFloat(region.CPAbeforeCommission) || 0.0,
      }));

      const programData = {
        name: form.name,
        normalized_name: form.normalized_name,
        brandId: form.brandId,
        verticalIds: form.verticalIds,
        aggregatedData,
        regions: regionsData, // Skicka all regionsdata i ett objekt
      };

      console.log("Submitting programData:", programData);
      await adminService.createNewProgram(programData);
      alert('Program created successfully');

      // Återställ formuläret
      setForm({
        normalized_name: '',
        name: '',
        brandId: null,
        verticalIds: [],
        regionIds: [],
      });
      setRegionsWithDeals([]);
      setSelectedRows([]);
      setAggregatedData(false);
    } catch (error) {
      console.error('Error creating program:', error);
      alert('Failed to create program');
    }
  };



  return (
    <div className={styles.createProgramContainer}>
      <h1>Create Program</h1>
      <Form onSubmit={handleSubmitProgram}>
        {/* Program Details */}
        <div className={styles.programMain}>
          <Form.Group>
            <Form.Label>Program Name</Form.Label>
            <Form.Control
              className={styles.input}
              type="text"
              name="name"
              value={form.name}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Normalized Name</Form.Label>
            <Form.Control
              className={styles.input}
              type="text"
              name="normalized_name"
              value={form.normalized_name}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <div className={styles.twoColumnInput}>
            <Form.Group>
              <Form.Label>Brand</Form.Label>
              <div className={styles.formGroupWithButton}>
                <Select
                  className={styles.select}
                  classNamePrefix="select"
                  styles={customStyles}
                  name="brandId"
                  value={getSelectedBrand()}
                  onChange={handleBrandChange}
                  options={brands.map((brand) => ({
                    value: brand.id,
                    label: brand.name,
                  }))}
                  isClearable
                  required
                />
                <Button variant="secondary" type="button" onClick={() => openModal('brand')}>
                  New Brand
                </Button>
              </div>
            </Form.Group>

            <Form.Group>
              <Form.Label>Verticals</Form.Label>
              <div className={styles.formGroupWithButton}>
                <Select
                  isMulti
                  className={styles.select}
                  classNamePrefix="select"
                  styles={customStyles}
                  name="verticalIds"
                  value={verticals
                    .filter(vertical => form.verticalIds.includes(vertical.id))
                    .map(vertical => ({ value: vertical.id, label: vertical.name }))}
                  onChange={(selectedOptions) => setForm({ ...form, verticalIds: selectedOptions ? selectedOptions.map(opt => opt.value) : [] })}
                  options={verticals.map(vertical => ({
                    value: vertical.id,
                    label: vertical.name
                  }))}
                  required
                />
                <Button variant="secondary" type="button" onClick={() => openModal('vertical')}>
                  New Vertical
                </Button>
              </div>
            </Form.Group>
          </div>

          {/* Region Selection */}
          <Form.Group>
            <Form.Label>Regions</Form.Label>
            <div className={styles.formGroupWithButton}>
              <Select
                isMulti
                className={styles.select}
                classNamePrefix="select"
                styles={customStyles}
                name="regionIds"
                value={regions
                  .filter(region => form.regionIds.includes(region.id))
                  .map(region => ({ value: region.id, label: region.name }))}
                onChange={(selectedOption) => handleSelectChange(selectedOption, { name: 'regionIds' })}
                options={regions.map(region => ({
                  value: region.id,
                  label: region.name
                }))}
                required
              />
            </div>
          </Form.Group>

          {/* Checkbox för Aggregated Data */}
          <Form.Group className={styles.aggregatedData}>
            <Form.Label>Aggregated Data</Form.Label>
            <Form.Check
              type="checkbox"
              checked={aggregatedData}
              onChange={handleAggregatedDataChange}
            />
          </Form.Group>

        </div>

        {/* Deal Group */}
        {form.regionIds.length > 0 && (
          <div className={styles.dealGroup}>
            <Button variant="primary" type="button" onClick={handleAddBulkEdit} className={styles.bulkEditBtn}>
              <span className="material-symbols-outlined">
                edit
              </span>
              Bulk Edit
            </Button>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>
                    <Form.Check
                      type="checkbox"
                      checked={selectedRows.length === regionsWithDeals.length && regionsWithDeals.length > 0}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedRows(regionsWithDeals.map(region => region.id));
                        } else {
                          setSelectedRows([]);
                        }
                      }}
                    />
                  </th>
                  <th>Region</th>
                  <th>Description</th>
                  <th>Baseline (€)</th>
                  <th>Savvy RevShare (%)</th>
                  <th>Customer Revshare (%)</th>
                  <th>Savvy CPA (€)</th>
                  <th>Customer CPA (€)</th>
                </tr>
              </thead>
              <tbody>
                {regionsWithDeals.map(region => (
                  <tr key={region.id}>
                    <td>
                      <Form.Check
                        type="checkbox"
                        checked={selectedRows.includes(region.id)}
                        onChange={(e) => handleRowSelection(e, region.id)}
                      />
                    </td>
                    <td>{region.name}</td>
                    <td>
                      <Form.Control
                        type="text"
                        value={region.description}
                        onChange={(e) => {
                          const updatedRegions = regionsWithDeals.map(r => {
                            if (r.id === region.id) {
                              return { ...r, description: e.target.value };
                            }
                            return r;
                          });
                          setRegionsWithDeals(updatedRegions);
                        }}
                        placeholder="Description"
                        required
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        value={region.baseline}
                        onChange={(e) => {
                          const updatedRegions = regionsWithDeals.map(r => {
                            if (r.id === region.id) {
                              return { ...r, baseline: parseFloat(e.target.value) || 0.0 };
                            }
                            return r;
                          });
                          setRegionsWithDeals(updatedRegions);
                        }}
                        step="0.01"
                        placeholder="0.00"
                        required
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        value={region.revShareBeforeCommission}
                        onChange={(e) => {
                          const updatedRegions = regionsWithDeals.map(r => {
                            if (r.id === region.id) {
                              return { ...r, revShareBeforeCommission: parseFloat(e.target.value) || 0.0 };
                            }
                            return r;
                          });
                          setRegionsWithDeals(updatedRegions);
                        }}
                        step="0.01"
                        placeholder="0.00"
                        required
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        value={region.revshare}
                        onChange={(e) => {
                          const updatedRegions = regionsWithDeals.map(r => {
                            if (r.id === region.id) {
                              return { ...r, revshare: parseFloat(e.target.value) || 0.0 };
                            }
                            return r;
                          });
                          setRegionsWithDeals(updatedRegions);
                        }}
                        step="0.01"
                        placeholder="0.00"
                        required
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        value={region.CPAbeforeCommission}
                        onChange={(e) => {
                          const updatedRegions = regionsWithDeals.map(r => {
                            if (r.id === region.id) {
                              return { ...r, CPAbeforeCommission: parseFloat(e.target.value) || 0.0 };
                            }
                            return r;
                          });
                          setRegionsWithDeals(updatedRegions);
                        }}
                        step="0.01"
                        placeholder="0.00"
                        required
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        value={region.CPA}
                        onChange={(e) => {
                          const updatedRegions = regionsWithDeals.map(r => {
                            if (r.id === region.id) {
                              return { ...r, CPA: parseFloat(e.target.value) || 0.0 };
                            }
                            return r;
                          });
                          setRegionsWithDeals(updatedRegions);
                        }}
                        step="0.01"
                        placeholder="0.00"
                        required
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}

        {/* Submit button */}
        <Button type="submit" className={styles.submitBtn}>
          Create Program
        </Button>
      </Form>

      {/* Bulk Edit Modal */}
      {isBulkEditModalOpen && (
        <Modal
          isOpen={isBulkEditModalOpen}
          onRequestClose={() => setIsBulkEditModalOpen(false)}
          contentLabel="Bulk Edit Deals"
          className={styles.modalSide}
          overlayClassName={styles.overlay}
        >
          <div className={styles.modalHeader}>
            <h3>Bulk Edit Deals</h3>
            <button className={styles.modalClose} onClick={() => setIsBulkEditModalOpen(false)}>
              <span class="material-symbols-outlined">
                close
              </span>
            </button>
          </div>
          <div className={styles.modalContent}>
            <Form>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  value={bulkEditData.description}
                  onChange={(e) => setBulkEditData({ ...bulkEditData, description: e.target.value })}
                  placeholder="Enter description"
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Standard Baseline (€)</Form.Label>
                <Form.Control
                  type="number"
                  value={bulkEditData.baseline}
                  onChange={(e) => setBulkEditData({ ...bulkEditData, baseline: e.target.value })}
                  step="0.01"
                  placeholder="0.00"
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Savvy RevShare (%)</Form.Label>
                <Form.Control
                  type="number"
                  value={bulkEditData.revShareBeforeCommission}
                  onChange={(e) => setBulkEditData({ ...bulkEditData, revShareBeforeCommission: e.target.value })}
                  step="0.01"
                  placeholder="0.00"
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Customer Revshare (%)</Form.Label>
                <Form.Control
                  type="number"
                  value={bulkEditData.revshare}
                  onChange={(e) => setBulkEditData({ ...bulkEditData, revshare: e.target.value })}
                  step="0.01"
                  placeholder="0.00"
                  required
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Savvy CPA (€)</Form.Label>
                <Form.Control
                  type="number"
                  value={bulkEditData.CPAbeforeCommission}
                  onChange={(e) => setBulkEditData({ ...bulkEditData, CPAbeforeCommission: e.target.value })}
                  step="0.01"
                  placeholder="0.00"
                  required
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Customer CPA (€)</Form.Label>
                <Form.Control
                  type="number"
                  value={bulkEditData.CPA}
                  onChange={(e) => setBulkEditData({ ...bulkEditData, CPA: e.target.value })}
                  step="0.01"
                  placeholder="0.00"
                  required
                />
              </Form.Group>

            </Form>
          </div>
          <div className={styles.modalActions}>
            <Button variant="primary" type="button" onClick={handleBulkEditSubmit} className={styles.save}>
              Save
            </Button>
            <Button variant="secondary" type="button" onClick={() => setIsBulkEditModalOpen(false)} className={styles.cancel}>
              Cancel
            </Button>
          </div>
        </Modal>
      )}

      <Modal
        isOpen={modalData.show}
        onRequestClose={closeModal}
        contentLabel="Create New"
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <div className={styles.modalContent}>
          <h3>Create New {modalData.type.charAt(0).toUpperCase() + modalData.type.slice(1)}</h3>
          <Form className={styles.addForm}>
            <Form.Group>
              <Form.Label>{modalData.type.charAt(0).toUpperCase() + modalData.type.slice(1)} Name</Form.Label>
              <Form.Control
                type="text"
                value={modalData.value}
                onChange={(e) => setModalData({ ...modalData, value: e.target.value })}
                required
              />
            </Form.Group>

            {modalData.type === 'brand' && (
              <>
                <Form.Group>
                  <Form.Label>Image</Form.Label>
                  <Form.Control
                    className={styles.imageInput}
                    type="file" onChange={handleFileChange}
                    accept="image/*"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Supported Data Points</Form.Label>
                  <Select
                    isMulti
                    options={dataPointOptions}
                    value={modalData.supportedDataPoints}
                    onChange={(selectedOptions) =>
                      setModalData({ ...modalData, supportedDataPoints: selectedOptions || [] })
                    }
                  />
                </Form.Group>
              </>
            )}
            <div className={styles.modalActions}>
              <Button variant="primary" type="button" onClick={handleModalSubmit} className={styles.save}>
                Save
              </Button>
              <Button variant="secondary" type="button" onClick={closeModal} className={styles.cancel}>
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default CreateProgram;
