import React, { useEffect, useState } from "react";
import Select from "react-select";
import adminService from "../../services/adminService";
import performanceService from "../../services/performanceService";
import styles from "./MonthlySummary.module.scss";
import InvoiceConfigModal from "./components/InvoiceConfigModal";

const MonthlySummary = () => {
    const [sources, setSources] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [selectedProgram, setSelectedProgram] = useState(null);
    const [selectedSource, setSelectedSource] = useState(null);
    const [summaryType, setSummaryType] = useState("totals");
    const [month, setMonth] = useState(new Date().toISOString().slice(0, 7)); // YYYY-MM
    const [summaryData, setSummaryData] = useState(null);
    const [expandedRows, setExpandedRows] = useState({});
    const [expandedDownTime, setExapandedDownTime] = useState();
    const [invoiceConfigOpen, setInvoiceConfigOpen] = useState(false);
    const [includeVAT, setIncludeVAT] = useState(false);
    const [extraPDFfields, setExtraPDFfields] = useState([]);

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const sourcesData = await adminService.fetchSources();
                const programsData = await adminService.fetchProgramsOptions();

                const sourceOptions = (sourcesData.sources || []).map((source) => ({
                    value: source.id,
                    label: source.name,
                }));
                const programOptions = (programsData || []).map((program) => ({
                    value: program.id,
                    label: program.name,
                }));

                setSources(sourceOptions);
                setPrograms(programOptions);
            } catch (error) {
                console.error("Error fetching programs or sources:", error);
            }
        };
        fetchInitialData();
    }, []);

    const fetchPerformanceSummary = async () => {
        try {
            let data;
            if (summaryType === "totals") {
                data = await performanceService.getMonthlyTotals(month);
            } else if (summaryType === "source" && selectedSource) {
                data = await performanceService.getSourceSummary(month, selectedSource.value);
            } else if (summaryType === "program" && selectedProgram) {
                data = await performanceService.getProgramSummary(month, selectedProgram.value);
            } else {
                data = null;
            }
            setSummaryData(data);
            setExpandedRows({});
        } catch (error) {
            console.error("Error fetching summary:", error);
        }
    };

    // useEffect som triggar fetchPerformanceSummary när state förändras
    useEffect(() => {
        if (summaryType === "totals") {
            // totals kan hämtas direkt
            fetchPerformanceSummary();
        } else if (summaryType === "source" && selectedSource) {
            // Bara hämta om vi är i source-läge och har en selectedSource
            fetchPerformanceSummary();
        } else if (summaryType === "program" && selectedProgram) {
            // Bara hämta om vi är i program-läge och har en selectedProgram
            fetchPerformanceSummary();
        }
        // Om inte villkoren uppfylls hämtar vi inte data
    }, [summaryType, selectedSource, selectedProgram, month]);

    const handleMonthChange = (e) => {
        setMonth(e.target.value);
    };

    const handleSummaryTypeChange = (type) => {
        setSummaryType(type);
        setSelectedSource(null);
        setSelectedProgram(null);
        setSummaryData(null);
        setExpandedRows({});
    };

    const handleFetch = () => {
        // Manuell fetch om användaren vill, men useEffect triggar redan fetch när state är korrekt
        fetchPerformanceSummary();
    };

    const toggleRow = (id, breakdownType) => {
        setExpandedRows((prev) => ({
            ...prev,
            [breakdownType + "_" + id]: !prev[breakdownType + "_" + id],
        }));
    };

    const handleRowClick = (breakdownType, item, e) => {
        if (e.target.closest("button")) return; // klick på knapp ska inte byta vy

        if (summaryType === "totals") {
            if (breakdownType === "program") {
                setSummaryType("program");
                setSelectedProgram({ value: item.id, label: item.name });
                setSummaryData(null);
            } else if (breakdownType === "source") {
                setSummaryType("source");
                setSelectedSource({ value: item.id, label: item.name });
                setSummaryData(null);
            }
        }
    };

    const handleVerifyClick = async (e, breakdownType, item) => {
        e.stopPropagation();
        let type;
        let id = item.id;

        if (summaryType === "totals") {
            if (breakdownType === "program") {
                type = "program";
            } else {
                type = "source";
            }
        } else if (summaryType === "source") {
            type = "sourceprogram";
        } else if (summaryType === "program") {
            type = "sourceprogram";
        }

        const newVerifiedStatus = !item.verified;
        await performanceService.toggleVerification(month, type, id, newVerifiedStatus);
        // Vi behöver inte explicit anropa fetchPerformanceSummary här,
        // useEffect triggar när state ändras men vi ändrar ej state här.
        // Om vi vill uppdatera direkt, anropa fetchPerformanceSummary manuellt:
        fetchPerformanceSummary();
    };

    const renderRegionRows = (regions) => {
        return Object.keys(regions).map((regionId) => {
            const region = regions[regionId];
            const rt = region.totals;
            return (
                <tr key={regionId} className={styles.regionRow}>
                    <td></td>
                    <td className={styles.regionName}>{region.name}</td>
                    <td>{rt.customerRevenue.toFixed(2)}€</td>
                    <td>{rt.totalEarnings.toFixed(2)}€</td>
                    <td>{rt.profit.toFixed(2)}€</td>
                    <td></td>
                </tr>
            );
        });
    };

    const renderTotalsRow = (totals) => {
        return (
            <tr className={styles.totalsRow}>
                <td></td>
                <td><strong>Totals</strong></td>
                <td><strong>{totals.customerRevenue.toFixed(2)}€</strong></td>
                <td><strong>{totals.totalEarnings.toFixed(2)}€</strong></td>
                <td><strong>{totals.profit.toFixed(2)}€</strong></td>
                <td></td>
            </tr>
        );
    };

    const handlePreviewPDF = async () => {
        const configs = {
            includeVAT, // eller false, beroende på ditt val
            extraRows: extraPDFfields, // Skicka dina rader från frontend
        };

        try {
            const pdfBlob = await performanceService.previewPDF(month, selectedSource.value, configs);
            console.log("PDF Blob:", pdfBlob);

            // Skapa en URL för blob och öppna den i en ny flik
            const pdfURL = window.URL.createObjectURL(pdfBlob);
            window.open(pdfURL, "_blank");
            window.open(pdfURL, "_blank");
        } catch (error) {
            console.error("Error previewing PDF:", error);
        }
    };

    const sendPDF = async () => {
        const configs = {
            includeVAT, // eller false, beroende på ditt val
            extraRows: extraPDFfields, // Skicka dina rader från frontend
        };
        await performanceService.sendPDF(month, selectedSource.value, configs);
        fetchPerformanceSummary();
    }

    const renderTable = (breakdownArray, breakdownType, totals, alreadySentSourceProgramIds = null) => {
        if (!breakdownArray || breakdownArray.length === 0) {
            return <p>No data available.</p>;
        }

        return (
            <table className={styles.dataTable}>
                <thead>
                    <tr>
                        <th></th>
                        <th>{breakdownType === "program" ? "Program" : "Source"}</th>
                        <th>C. Revenue</th>
                        <th>Turnover</th>
                        <th>Profit</th>
                        <th>Verify</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {breakdownArray.map((item) => {
                        const { id, name, totals: itemTotals, regions, verified, downTimes } = item;
                        const hasRegions = regions && Object.keys(regions).length > 0;
                        const expanded = expandedRows[breakdownType + "_" + id];

                        return (
                            <React.Fragment key={id}>
                                <tr className={`${styles.mainRow} ${verified && styles.verifiedRow}`}>
                                    <td className={styles.toggleCell}>
                                        {hasRegions && (
                                            <button
                                                className={styles.toggleButton}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    toggleRow(id, breakdownType);
                                                }}
                                            >
                                                {expanded ? "-" : "+"}
                                            </button>
                                        )}
                                    </td>
                                    <td>
                                        <p
                                            className={`${styles.nameCell} ${summaryType === "totals" && styles.clickable}`}
                                            onClick={(e) => handleRowClick(breakdownType, item, e)}
                                        >
                                            {name}
                                        </p>
                                    </td>
                                    <td>{itemTotals.customerRevenue.toFixed(2)}€</td>
                                    <td>{itemTotals.totalEarnings.toFixed(2)}€</td>
                                    <td>{itemTotals.profit.toFixed(2)}€</td>
                                    <td>
                                        {alreadySentSourceProgramIds && alreadySentSourceProgramIds.includes(item.id) ?
                                            <span>Handled</span>
                                            :
                                            <button onClick={(e) => handleVerifyClick(e, breakdownType, item)} className={`${styles.verifyBtn} ${verified ? styles.verified : styles.unverified}`}>
                                                {verified ? "Unverify" : "Verify"}
                                            </button>
                                        }
                                    </td>
                                    <td className={styles.downTimeCell}>
                                        {downTimes && Object.keys(downTimes).length !== 0 &&
                                            <div className={expandedDownTime === id && styles.expandedDiv}>
                                                <div className={styles.toggleDownTime}>
                                                    <p>Has downtimes</p>
                                                    <button onClick={() => { expandedDownTime === id ? setExapandedDownTime(null) : setExapandedDownTime(id) }}>
                                                        <span class="material-symbols-outlined">
                                                            {expandedDownTime === id ? "keyboard_arrow_up" : "keyboard_arrow_down"}
                                                        </span>
                                                    </button>
                                                </div>
                                                {expandedDownTime === id &&
                                                    <div className={styles.downTimeBreakDown}>
                                                        {Object.keys(downTimes).map((region) => (
                                                            <div key={region} className={styles.downTimeRow}>
                                                                <p><strong>{region}</strong></p>
                                                                {downTimes[region].map((dte, index) => (
                                                                    <p key={index}>
                                                                        {dte.startDate} - {dte.endDate || "Ongoing"}
                                                                    </p>
                                                                ))}
                                                            </div>
                                                        ))}
                                                    </div>

                                                }
                                            </div>
                                        }
                                    </td>
                                </tr>
                                {expanded && hasRegions && renderRegionRows(regions)}
                            </React.Fragment>
                        );
                    })}
                    {totals && renderTotalsRow(totals)}
                </tbody>
            </table >
        );
    };

    const renderTotalsView = () => {
        if (!summaryData) {
            return <p>No data available. Please fetch a summary.</p>;
        }

        const { programBreakdown, sourceBreakdown, totals } = summaryData;

        return (
            <div>
                <h3>Program Breakdown</h3>
                {renderTable(programBreakdown, "program", totals)}
                <h3>Source Breakdown</h3>
                {renderTable(sourceBreakdown, "source", totals)}
            </div>
        );
    };

    const renderSourceView = () => {
        if (!summaryData) {
            return <p>No data available. Please fetch a summary.</p>;
        }

        const { programBreakdown, totals, alreadySentSourceProgramIds, hasPaymentDetails } = summaryData;

        // Kolla om alla program i programBreakdown är verifierade
        const allIdsExist = programBreakdown.every(p =>
            alreadySentSourceProgramIds.includes(p.id)
        );

        return (
            <div>
                <h3>Source Summary (Programs breakdown)</h3>
                {renderTable(programBreakdown, "program", totals, alreadySentSourceProgramIds)}
                <div className={styles.sourceButtons}>
                    {!allIdsExist &&
                        <>
                            <button onClick={handlePreviewPDF} disabled={!hasPaymentDetails} className={styles.previewBtn}>Preview PDF</button>
                            <button className={styles.sendBtn} disabled={!hasPaymentDetails} onClick={sendPDF}>Send PDF</button>
                            <button className={styles.openConfig} onClick={() => setInvoiceConfigOpen(true)}>Config PDF</button>
                            {!hasPaymentDetails && <p>Missing payment details</p>}
                        </>
                    }
                </div>
            </div>
        );
    };

    const renderProgramView = () => {
        if (!summaryData) {
            return <p>No data available. Please fetch a summary.</p>;
        }

        const { sourceBreakdown, totals, downTimes } = summaryData;
        return (
            <div>
                <h3>Program Summary (Sources breakdown)</h3>
                {downTimes && Object.keys(downTimes).length !== 0 &&
                    <div className={`${styles.programBreakDownTime} ${expandedDownTime === 0 ? styles.expandedDiv : styles.contractedDiv}`}>
                        <div className={styles.toggleDownTime}>
                            <p>Has downtimes</p>
                            <button onClick={() => { expandedDownTime === 0 ? setExapandedDownTime(null) : setExapandedDownTime(0) }}>
                                {expandedDownTime === 0 ?
                                    <span class="material-symbols-outlined">
                                        keyboard_arrow_up
                                    </span> : <span class="material-symbols-outlined">
                                        keyboard_arrow_down
                                    </span>
                                }
                            </button>
                        </div>
                        {expandedDownTime === 0 &&
                            <div className={styles.downTimeBreakDown}>
                                {Object.keys(downTimes).map((region) => (
                                    <div key={region} className={styles.downTimeRow}>
                                        <p><strong>{region}</strong></p>
                                        {downTimes[region].map((dte, index) => (
                                            <p key={index}>
                                                {dte.startDate} - {dte.endDate || "Ongoing"}
                                            </p>
                                        ))}
                                    </div>
                                ))}
                            </div>

                        }
                    </div>
                }
                {renderTable(sourceBreakdown, "program", totals)}
            </div>
        );
    };

    return (
        <div className={styles.summaryContainer}>
            <div className={styles.controls}>
                <div>
                    <button onClick={() => handleSummaryTypeChange("totals")} className={`${styles.optionsBtn} ${summaryType === "totals" && styles.activeOption}`}>
                        Totals
                    </button>
                    <button onClick={() => handleSummaryTypeChange("source")} className={`${styles.optionsBtn} ${summaryType === "source" && styles.activeOption}`}>
                        Source
                    </button>
                    <button onClick={() => handleSummaryTypeChange("program")} className={`${styles.optionsBtn} ${summaryType === "program" && styles.activeOption}`}>
                        Program
                    </button>
                </div>
                <label>
                    <input
                        type="month"
                        value={month}
                        onChange={handleMonthChange}
                        className={styles.month}
                    />
                </label>
                {summaryType === "source" && (
                    <Select
                        options={sources}
                        value={selectedSource}
                        onChange={setSelectedSource}
                        placeholder="Select a Source"
                        isClearable
                        className={styles.select}
                    />
                )}

                {summaryType === "program" && (
                    <Select
                        options={programs}
                        value={selectedProgram}
                        onChange={setSelectedProgram}
                        placeholder="Select a Program"
                        isClearable
                        className={styles.select}
                    />
                )}
                <button onClick={handleFetch} disabled={!month} className={styles.fetchBtn}>
                    Fetch Summary
                </button>
            </div>

            <div className={styles.resultSection}>
                {summaryType === "totals" && renderTotalsView()}
                {summaryType === "source" && renderSourceView()}
                {summaryType === "program" && renderProgramView()}
            </div>
            <InvoiceConfigModal
                isOpen={invoiceConfigOpen}
                onRequestClose={() => setInvoiceConfigOpen(false)}
                includeVAT={includeVAT}
                setIncludeVAT={setIncludeVAT}
                extraPDFfields={extraPDFfields}
                setExtraPDFfields={setExtraPDFfields}
            />
        </div>
    );
};

export default MonthlySummary;
