import React, { useState, useEffect } from 'react';
import EarningsChart from './components/EarningsChart';
import styles from './HomePage.module.scss';
import adminService from '../../services/adminService';
import sourceService from '../../services/sourceService';
import placeholder from '../../images/placeholder.jpg';

const Homepage = () => {
    const [earningsData, setEarningsData] = useState([]);
    const [sources, setSources] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [chartPeriod, setChartPeriod] = useState('last7days');
    const [selectedSource, setSelectedSource] = useState(null);
    const [selectedProgram, setSelectedProgram] = useState(null);
    const [summary, setSummary] = useState(null);
    const [hotSources, setHotSources] = useState([]);

    useEffect(() => {
        fetchInitialData();
    }, []);

    useEffect(() => {
        fetchDashboardEarnings();
    }, [chartPeriod, selectedSource, selectedProgram]);

    const fetchInitialData = async () => {
        try {
            const [sourcesData, programsData] = await Promise.all([
                sourceService.getAllSources(),
                adminService.fetchProgramsOptions(),
            ]);
            setSources(sourcesData);
            setPrograms(programsData);

            const summary = await adminService.homePageSummary();
            setSummary(summary);

            const hotSources = await adminService.getDashboardHotSources();
            setHotSources(hotSources);
        } catch (error) {
            console.error('Error fetching initial data:', error);
        }
    };

    const fetchDashboardEarnings = async () => {
        try {
            const data = await adminService.getAdminDashboardEarnings(
                chartPeriod,
                selectedSource,
                selectedProgram,
            );
            setEarningsData(data);
        } catch (error) {
            console.error('Error fetching dashboard earnings data:', error);
        }
    };


    return (
        <div className={styles.homePageContainer}>
            <div className={styles.homePageGrid}>

                <div className={styles.infoBoxesContainer}>
                    {summary && <>
                        <div className={styles.infoBox}>
                            <div className={styles.infoBoxHeader}>
                                <span class="material-symbols-outlined">
                                    payments
                                </span>
                                <h6>Turnover</h6>
                            </div>
                            <div className={styles.infoBoxStats}>
                                <p>LAST 7 DAYS</p>
                                <p>{summary.thisWeek.earnings.toFixed(2)}€</p>
                            </div>
                            <div className={styles.infoBoxStats}>
                                <p>TOTAL</p>
                                <p>{summary.total.earnings.toFixed(2)}€</p>
                            </div>

                        </div>
                        <div className={styles.infoBox}>
                            <div className={styles.infoBoxHeader}>
                                <span class="material-symbols-outlined">
                                    payments
                                </span>
                                <h6>Profit</h6>
                            </div>
                            <div className={styles.infoBoxStats}>
                                <p>LAST 7 DAYS</p>
                                <p>{summary.thisWeek.profit.toFixed(2)}€</p>
                            </div>
                            <div className={styles.infoBoxStats}>
                                <p>TOTAL</p>
                                <p>{summary.total.profit.toFixed(2)}€</p>
                            </div>

                        </div>
                        <div className={styles.infoBox}>
                            <div className={styles.infoBoxHeader}>
                                <span class="material-symbols-outlined">
                                    payments
                                </span>
                                <h6>QFTD</h6>
                            </div>
                            <div className={styles.infoBoxStats}>
                                <p>LAST 7 DAYS</p>
                                <p>{summary.thisWeek.QFTD}</p>
                            </div>
                            <div className={styles.infoBoxStats}>
                                <p>TOTAL</p>
                                <p>{summary.total.QFTD}</p>
                            </div>
                        </div>
                    </>}
                </div>
                <div className={styles.hotSourcesContainer}>

                    <div>
                        <h3>Top Performers <span>(Turnover)</span></h3>
                    </div>
                    <div className={styles.hotSourcesList}>
                        {hotSources.length > 0 ? hotSources.map((source, index) => (
                            <div key={index} className={styles.hotSourceCard}>
                                <div>
                                    <img
                                        src={source.image ? `/uploads/sourceLogos/${source.image}` : placeholder}
                                        alt="Source Logo"
                                    />
                                    <p>
                                        {source.name}
                                    </p>

                                </div>
                                <p>
                                    €{source.earnings.toFixed(2)} this week
                                </p>
                            </div>
                        )) :
                            <p className={styles.noPref}>No performance this week</p>
                        }
                    </div>
                </div>
                <EarningsChart
                    earningsData={earningsData}
                    chartPeriod={chartPeriod}
                    onPeriodChange={setChartPeriod}
                    sources={sources}
                    programs={programs}
                    onSourceChange={setSelectedSource}
                    onProgramChange={setSelectedProgram}
                    selectedSource={selectedSource}
                    selectedProgram={selectedProgram}
                />
            </div>

        </div>
    );
};

export default Homepage;
