import axios from "axios";
const API_URL = process.env.REACT_APP_BASE_URL + "/api/sources/";

// Hämta alla sidor för en användare
const getAllSources = async () => {
  try {
    const response = await axios.get(API_URL + "all", {
      withCredentials: true, // Skickar cookies med förfrågan
    });
    return response.data; // Returnerar datan från svaret
  } catch (error) {
    console.error("Error fetching user sources:", error);
    throw error;
  }
};

const getUserSources = async () => {
  try {
    const response = await axios.get(API_URL, {
      withCredentials: true, // Skickar cookies med förfrågan
    });
    return response.data; // Returnerar datan från svaret
  } catch (error) {
    console.error("Error fetching user sources:", error);
    throw error;
  }
};

const getUserSource = async (sourceId) => {
  try {
    const response = await axios.get(API_URL + "user-source/" + sourceId, {
      withCredentials: true, // Skickar cookies med förfrågan
    });
    return response.data; // Returnerar datan från svaret
  } catch (error) {
    console.error("Error fetching user sources:", error);
    throw error;
  }
};

// Skapa en ny sida (source)
const createUserSources = async (formData) => {
  try {
    const response = await axios.post(API_URL, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error creating new source:", error);
    // Check if the error has a response and use the message from it
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message); // Extract the custom error message
    } else {
      throw new Error(
        "An unexpected error occurred while creating the source."
      );
    }
  }
};

// Edit an existing source with FormData (including image)
const editSource = async (sourceId, formData) => {
  try {
    const response = await axios.put(`${API_URL}${sourceId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error editing source:", error);
    throw error;
  }
};

const fetchVerticals = async () => {
  try {
    const response = await axios.get(API_URL + "verticals", {
      withCredentials: true, // Skickar cookies med förfrågan
    });
    return response.data; // Returnerar datan från svaret
  } catch (error) {
    console.error("Error fetching verticals:", error);
    throw error;
  }
};

const fetchRegions = async () => {
  try {
    const response = await axios.get(API_URL + "regions", {
      withCredentials: true, // Skickar cookies med förfrågan
    });
    return response.data; // Returnerar datan från svaret
  } catch (error) {
    console.error("Error fetching regions:", error);
    throw error;
  }
};

const fetchEarnings = async (startDate, endDate) => {
  try {
    const response = await axios.get(API_URL + "earnings", {
      params: {
        startDate,
        endDate,
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching earnings:", error);
    throw error;
  }
};
// Exportera alla funktioner
export default {
  getAllSources,
  fetchEarnings,
  getUserSource,
  fetchRegions,
  fetchVerticals,
  getUserSources,
  createUserSources,
  editSource,
};
