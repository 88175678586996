import React, { useState } from "react";
import Modal from "react-modal";
import styles from "./InvoiceConfigModal.module.scss";

const InvoiceConfigModal = ({
    isOpen,
    onRequestClose,
    includeVAT,
    setIncludeVAT,
    extraPDFfields,
    setExtraPDFfields,
}) => {
    const [editingNewField, setEditingNewField] = useState(false);
    const [fieldName, setFieldName] = useState("");
    const [fieldValue, setFieldValue] = useState(0);

    const addField = () => {
        const newField = { name: fieldName, value: fieldValue };
        setExtraPDFfields([...extraPDFfields, newField]);
        setFieldName("");
        setFieldValue(0);
        setEditingNewField(false);
    };

    const removeField = (index) => {
        const updatedFields = extraPDFfields.filter((_, i) => i !== index);
        setExtraPDFfields(updatedFields);
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Config PDF"
            className={styles.modal}
            overlayClassName={styles.overlay}
        >
            <div className={styles.modalContent}>
                <h3>Config PDF</h3>
                <label className={styles.checkBox}>
                    <input
                        type="checkbox"
                        name="includeVat"
                        checked={includeVAT}
                        onChange={() => setIncludeVAT(!includeVAT)}
                    />
                    <span>Include VAT</span>
                </label>

                {/* Render saved fields */}
                {extraPDFfields.length > 0 && (
                    <ul className={styles.savedFields}>
                        {extraPDFfields.map((field, index) => (
                            <li key={index} className={styles.fieldRow}>
                                <span><strong>{field.name}</strong>: {field.value}</span>
                                <button
                                    className={styles.removeBtn}
                                    onClick={() => removeField(index)}
                                >
                                    <strong>X</strong>
                                </button>
                            </li>
                        ))}
                    </ul>
                )}

                {editingNewField ? (
                    <div className={styles.fieldInputs}>
                        <label>
                            <span>Field Name</span>
                            <input
                                className={styles.input}
                                type="text"
                                value={fieldName}
                                onChange={(e) => setFieldName(e.target.value)}
                            />
                        </label>
                        <label>
                            <span>Value</span>
                            <input
                                className={styles.input}
                                type="number"
                                value={fieldValue}
                                onChange={(e) => setFieldValue(parseFloat(e.target.value))}
                            />
                        </label>
                        <button className={styles.add} onClick={addField}>
                            Add
                        </button>
                    </div>
                ) : (
                    <button onClick={() => setEditingNewField(true)} className={styles.addField}>Add Field</button>
                )}
            </div>
        </Modal>
    );
};

export default InvoiceConfigModal;
