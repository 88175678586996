import React, { useState, useEffect } from 'react';
import authService from '../../services/authService';
import sourceService from '../../services/sourceService';
import EarningsChart from './EarningsChart';
import HotProgramsChart from './HotProgramsChart';
import CountryChart from './CountryChart';
import styles from './Dashboard.module.scss';
import { Skeleton } from '@mui/material';

const Dashboard = () => {
  useEffect(() => {
    document.title = 'Savvy - Dashboard';
  }, []);

  const [summaryData, setSummaryData] = useState(null);
  const [hotPrograms, setHotPrograms] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [currentMetric, setCurrentMetric] = useState('clicks');
  const [selectedSource, setSelectedSource] = useState('all');
  const [sources, setSources] = useState([]);


  /* LOADING */
  const [summaryLoading, setSummaryLoading] = useState(true);
  const [hotProgramsLoading, setHotProgramsLoading] = useState(true);
  const [countryChartLoading, setCountryChartLoading] = useState(true);

  useEffect(() => {
    fetchDashboardSummary();
    fetchDashboardHotPrograms();
    fetchSources();
  }, []);

  useEffect(() => {
    fetchCountryData();
  }, [currentMetric, selectedSource]);

  const fetchDashboardSummary = async () => {
    try {
      setSummaryLoading(true)
      const data = await authService.getDashboardSummary();
      setSummaryData(data);
    } catch (error) {
      console.error('Error fetching dashboard summary data:', error);
    } finally {
      setSummaryLoading(false);
    }
  };

  const fetchDashboardHotPrograms = async () => {
    try {
      setHotProgramsLoading(true)
      const data = await authService.getDashboardHotPrograms();
      setHotPrograms(data);
    } catch (error) {
      console.error('Error fetching dashboard hot programs data:', error);
    } finally {
      setHotProgramsLoading(false);
    }
  };

  const fetchCountryData = async () => {
    try {
      setCountryChartLoading(true)
      const data = await authService.getDashboardBarChartData(
        selectedSource !== 'all' ? selectedSource : null
      );
      console.log(data)
      setCountryData(data);
    } catch (error) {
      console.error('Error fetching country data:', error);
    } finally {
      setCountryChartLoading(false);
    }
  };

  const fetchSources = async () => {
    try {
      const data = await sourceService.getUserSources();
      setSources(data);
    } catch (error) {
      console.error('Error fetching sources:', error);
    }
  };

  if (!summaryData) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.dashboardContainer}>
      <div className={styles.infoBoxesContainer}>
        {!summaryLoading ?
          <>
            <div>
              <h3>{summaryData.totalEarnings.toFixed(2)} €</h3>
              <p>Revenue</p>
            </div>
            <div>
              <h3>{summaryData.totalSignups}</h3>
              <p>Signups</p>
            </div>
            <div>
              <h3>{summaryData.totalFTD}</h3>
              <p>FTD</p>
            </div>
          </>
          :
          <>
            <div>
              <Skeleton variant="text" width="100%" height={52} style={{ marginTop: "-10px" }} />
              <Skeleton variant="text" width="50%" height={20} style={{ marginTop: "-5px" }} />
            </div>
            <div>
              <Skeleton variant="text" width="100%" height={52} style={{ marginTop: "-10px" }} />
              <Skeleton variant="text" width="50%" height={20} style={{ marginTop: "-5px" }} />
            </div>
            <div>
              <Skeleton variant="text" width="100%" height={52} style={{ marginTop: "-10px" }} />
              <Skeleton variant="text" width="50%" height={20} style={{ marginTop: "-5px" }} />
            </div>
          </>
        }
      </div>

      <div className={styles.earningsChartContainer}>
        <EarningsChart />
      </div>

      <div className={styles.hotProgramsContainer}>
        <h3>Top Performers</h3>
        <div className={styles.hotProgramsList}>
          <HotProgramsChart hotPrograms={hotPrograms} loading={hotProgramsLoading} />
        </div>
      </div>

      <div className={styles.countryChartContainer}>
        <h3>Country Metrics</h3>
        <div className={styles.countryChartList}>
          <CountryChart
            data={countryData[currentMetric]?.data || []}
            metrics={[
              { key: 'clicks', label: 'Clicks' },
              { key: 'signups', label: 'Signups' },
              { key: 'ftd', label: 'FTD' },
              { key: 'cpaIncome', label: 'CPA (€)' },
            ]}
            selectedMetric={currentMetric}
            onMetricChange={setCurrentMetric}
            sources={sources}
            selectedSource={selectedSource}
            onSourceChange={setSelectedSource}
            loading={countryChartLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
